import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { mediaQuery, color, shadows } = baseVariables;

export const StyledPropertyCard = styled.div`
  margin-top: ${toRem(24)};
  .property-card {
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;
    .property-media {
      .carousel-container {
        .carousel-image {
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: ${shadows.boxShadowTile} 0 ${toRem(4)} ${toRem(4)} 0;
    border-top: ${toRem(1)} solid ${color.neutral20};
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};
    background-color: ${color.base20};

    &.compact-footer {
      flex-direction: column;
      width: 100%;
      .date-picker-container,
      .rooms-guests,
      .check-availability {
        width: 100%;
      }
      @media ${mediaQuery.md} {
        .mobile-container-footer,
        .mobile-container {
          width: ${toRem(400)};
        }
      }
    }

    .date-picker-container,
    .rooms-guests {
      flex: 1;
    }

    .date-picker-container {
      .contain {
        margin-top: 0;
      }
      .flexible-dates {
        text-align: left;
      }
      .m-dropdown-container {
        z-index: 3;
      }
    }

    @media ${mediaQuery.md} {
      flex-direction: row;
      .check-availability {
        width: auto;
      }
    }
  }
`;
