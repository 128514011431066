import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { font, spacers } = baseVariables;

export const StyledViewToggle = styled.div<{ bottom: number }>`
  &.map-view-toggle-container {
    width: fit-content;
    max-width: 100%;
    position: sticky;
    bottom: ${props => toRem(props.bottom || 0)};
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    margin-top: ${spacers.spacers?.[3]};
  }

  &.list-view-toggle-container {
    position: absolute;
    top: ${toRem(16)};
    right: ${spacers.spacer};
    z-index: 8;
  }

  .view-toggle-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${toRem(40)};
    min-width: ${toRem(110)};
    box-shadow: 0 ${toRem(3)} ${toRem(5)} ${toRem(3)} rgba(0, 0, 0, 0.4);
    border-radius: ${toRem(20)};
    font-size: ${toRem(16)};
    font-weight: ${font.fontWeightMedium};
    .icon {
      width: auto;
      height: auto;
      margin-right: ${spacers.spacers?.[1]};
      font-size: ${toRem(20)};
    }
  }
`;
