import { FC, SyntheticEvent } from 'react';
import { StyledRecentListDiv } from './RecentList.styles';
import type { RecentListProps } from './RecentList.types';

export const RecentList: FC<RecentListProps> = ({ heading, itemList, nameKey, valueKey, handleSelection }) => {
  const handleClick = (e: SyntheticEvent, item: Record<string, string>) => {
    e.preventDefault();
    if (item[nameKey]) {
      handleSelection(item[valueKey || nameKey], item['placeId'], item['secondaryDescription']);
    }
  };

  return (
    <StyledRecentListDiv data-testid="recent-list">
      {heading && <h3 className="t-overline-normal">{heading}</h3>}
      <ul>
        {itemList?.map((item: Record<string, string>) => (
          <li key={`recent-list-item-${item[nameKey]}`} className="list-item">
            <a href="#" onClick={(event: SyntheticEvent) => handleClick(event, item)} data-testid="recent-list-item">
              <span className="t-label-alt-s">{item[nameKey]}</span>
            </a>
          </li>
        ))}
      </ul>
    </StyledRecentListDiv>
  );
};
