import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type LocaleState = {
  locale: string;
};

type LocaleActions = {
  setLocale: (locale: string) => void;
};

const initialState: LocaleState = {
  locale: '',
};

export const localeStore: StateCreator<LocaleState & LocaleActions> = set => {
  return {
    ...initialState,

    setLocale: (locale: string) => {
      set(prevState => {
        return {
          ...prevState,
          locale,
        };
      });
    },
  };
};

export const useLocaleStore = createAppStore(localeStore);
