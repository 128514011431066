import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { font, color } = baseVariables;

export const StyledMiniPropertyCard = styled.div`
  box-shadow: rgba(0, 0, 0, 0.2) 0 ${toRem(4)} ${toRem(20)} 0;
  background: ${color.base20};
  border-radius: ${toRem(14)};
  min-width: ${toRem(320)};
  max-width: ${toRem(360)};
  height: ${toRem(120)};

  .image-section {
    width: ${toRem(120)};
    margin: 0 0 0 -${toRem(1)};
    overflow: hidden;
    .property-image {
      border-radius: ${toRem(14)} 0 0 ${toRem(14)};
      height: 100%;
    }
  }

  .main-section {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: space-between;
    border-radius: 0 ${toRem(14)} ${toRem(14)} 0;
    .description {
      padding: ${toRem(10)} ${toRem(4)} 0 ${toRem(10)};
      .title-link {
        background: transparent;
        color: ${color.neutral00};
        .title {
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-weight: ${font.fontWeightMedium};
        }
      }
      .reviews-miles {
        font-size: ${toRem(11)};
        white-space: nowrap;
        gap: ${toRem(4)};
        .ratings-container,
        .m-icon-text {
          gap: ${toRem(4)};
        }
      }
    }
    .divider {
      border-top: ${toRem(1)} solid ${color.neutral30};
    }
    .footer-link {
      font-size: ${toRem(13)};
      line-height: 1;
      white-space: nowrap;
    }
  }
`;
