import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type MediaQueryState = {
  isDesktop: boolean | null;
  isTabletAndAbove: boolean | null;
};

type MediaQueryActions = {
  setIsDesktop: (isDesktop: boolean) => void;
  setIsTabletAndAbove: (isTabletAndAbove: boolean) => void;
};

const initialState: MediaQueryState = {
  isDesktop: null,
  isTabletAndAbove: null,
};

export const mediaQueryStore: StateCreator<MediaQueryState & MediaQueryActions> = (set, get) => {
  return {
    ...initialState,

    setIsDesktop: (isDesktop: boolean) => {
      if (get().isDesktop !== isDesktop) {
        set(prevState => {
          return {
            ...prevState,
            isDesktop,
          };
        });
      }
    },

    setIsTabletAndAbove: (isTabletAndAbove: boolean) => {
      if (get().isTabletAndAbove !== isTabletAndAbove) {
        set(prevState => {
          return {
            ...prevState,
            isTabletAndAbove,
          };
        });
      }
    },
  };
};

export const useMediaQueryStore = createAppStore(mediaQueryStore);
