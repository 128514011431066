import { FC } from 'react';
import { DatePickerMobileProps, ValueProps } from './DatePickerMobile.types';
import { StyledFlexibleDatesField, StyledDatePickerMobile } from './DatePickerMobile.styles';
import { CALENDAR_VARIATION_VERTICAL } from '../../../constants';
import { DateObject } from '../DatePicker.types';
import { DatePicker } from '../DatePicker';
import { CheckBoxFormField } from '../../CheckBoxFormField';
import { getFormattedDates, getFormattedDate, getDatesLabel } from '../../../utils';

export const DatePickerMobile: FC<DatePickerMobileProps> = ({
  dates,
  selectedDateType,
  startDate,
  endDate,
  isDateRangeFixed = false,
  rangeStartDate,
  rangeEndDate,
  setDefaultDates = true,
  showFlexibleDates = true,
  flexibleDates = false,
  isPastDateSelectionDisabled,
  enableFromPastDate,
  onChange,
  onFlexibleDateChange,
}) => {
  const {
    night,
    nights,
    flexibleDates: flexibleDatesLabel,
    shortWeekdayInitials,
    shortWeekdayNames,
    longWeekdayNames,
    shortMonthNames,
    longMonthNames,
  } = dates;

  const setDates = (selectedStartDate: DateObject | null, selectedEndDate: DateObject | null) => {
    const dateInputValue = getFormattedDates(selectedStartDate, selectedEndDate);
    const formattedStartDate = getFormattedDate(selectedStartDate);
    const formattedEndDate = getFormattedDate(selectedEndDate);
    const datesLabel = getDatesLabel(selectedStartDate, selectedEndDate, dates.label, dates.night, dates.nights);

    onChange?.({
      dates: dateInputValue,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      datesLabel: datesLabel,
    });
  };

  const handleFlexibleDateChange = (selectedValues: ValueProps) => {
    onFlexibleDateChange && onFlexibleDateChange({ flexibleDates: Boolean(selectedValues) });
  };

  const datesField = (
    <DatePicker
      variation={CALENDAR_VARIATION_VERTICAL}
      nightLabel={night}
      nightsLabel={nights}
      shortWeekdayInitials={shortWeekdayInitials}
      shortWeekdayNames={shortWeekdayNames}
      longWeekdayNames={longWeekdayNames}
      shortMonthNames={shortMonthNames}
      longMonthNames={longMonthNames}
      setDates={setDates}
      setInitialDates={setDates}
      selectedDateType={selectedDateType}
      selectedStartDate={startDate}
      selectedEndDate={endDate}
      isDateRangeFixed={isDateRangeFixed}
      rangeStartDate={rangeStartDate}
      rangeEndDate={rangeEndDate}
      setDefaultDates={setDefaultDates}
      isThereTwoDateInputBox={true}
      isPastDateSelectionDisabled={isPastDateSelectionDisabled}
      enableFromPastDate={enableFromPastDate}
    />
  );

  const flexibleDatesField = (
    <StyledFlexibleDatesField>
      <CheckBoxFormField
        options={[{ label: flexibleDatesLabel, value: 'yes' }]}
        colLength={12}
        labelClassName="flexible-dates-label"
        className="flexible-dates"
        name="isFlexibleDates"
        onChange={handleFlexibleDateChange}
        value={flexibleDates ? 'yes' : 'no'}
        testId="flexibleDates"
      />
    </StyledFlexibleDatesField>
  );

  return (
    <StyledDatePickerMobile>
      {datesField}
      {showFlexibleDates && flexibleDatesField}
    </StyledDatePickerMobile>
  );
};
