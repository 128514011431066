import { FC } from 'react';
import clsx from 'clsx';
import { Button, Modal, Types } from '@marriott/mi-ui-library';
import { StyledFilterModal } from './FilterModal.styles';
import { FilterModalProps } from './FilterModal.types';

export const FilterModal: FC<FilterModalProps> = props => {
  const {
    labels,
    show,
    bodyCustomClass = '',
    children,
    isLoading,
    onApplySelectedFilters,
    onClearSelectedFilters,
    onCloseModal,
  } = props;

  return (
    <StyledFilterModal data-component-name="m-groups-FilterModal" data-testid="groups-FilterModal">
      <Modal
        role="dialog"
        show={show}
        popupOpenState={show}
        setPopupOpenState={onCloseModal}
        onClose={onCloseModal}
        handleBlur={true}
        labelledBy="filterModal"
        modalId="filterModal"
        disableScrollOnBody={true}
      >
        <Modal.Header
          headingTagElement={Types.tags.div}
          labelText={labels.heading}
          popupHeaderOnCLoseFunc={onCloseModal}
        ></Modal.Header>
        <Modal.Body className={clsx('modal-body', bodyCustomClass, isLoading && 'loading-view-container')}>
          <>
            <div className={clsx(isLoading && 'loading-view')}>{children}</div>
            {isLoading ? <div className="m-spinner-m" /> : null}
          </>
        </Modal.Body>
        <Modal.FooterGeneric
          className="footer-section"
          customClass="footer-actions"
          actions={
            <>
              <Button
                ariaLabel={labels.clearAriaLabel}
                className="reset-cta p-0"
                testId="clear"
                callback={onClearSelectedFilters}
              >
                {labels.clear}
              </Button>
              <Button
                ariaLabel={labels.applyAriaLabel}
                className="m-button-m m-button-primary"
                callback={onApplySelectedFilters}
              >
                {labels.apply}
              </Button>
            </>
          }
        />
      </Modal>
    </StyledFilterModal>
  );
};
