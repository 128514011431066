import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-next-link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .pagination-prev-link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .page-arrow-container {
    display: flex;
    align-items: center;
    cursor: default;
  }

  .rnb-pagination {
    list-style-type: none;
    padding: ${toRem(0)};
    display: flex;
    align-items: center;
    justify-content: center;

    .rnb-pagination-item {
      margin: 0 ${toRem(1)};

      border-radius: 50%;
      cursor: pointer;
      border: ${toRem(1)} solid transparent;
      height: ${toRem(24)};
      width: ${toRem(24)};
      line-height: ${toRem(16)};
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: ${baseVariables.color.neutral40};
        color: ${baseVariables.color.base20};
      }
      &.active {
        background: ${baseVariables.color.neutral40};
        color: ${baseVariables.color.base20};
        &:hover {
          background-color: ${baseVariables.color.neutral40};
          color: ${baseVariables.color.base20};
          cursor: default;
        }
      }
    }
    .rnb-pagination-dots {
      width: ${toRem(14)} !important;
      margin: 0;
      padding: 0;
      &:hover {
        border: none;
        cursor: default;
        background-color: none;
      }
    }
    .rnb-pagination-arrow {
      font-size: ${toRem(30)};
      width: auto;
      padding: ${toRem(2)} 0;
      &.disabled {
        pointer-events: none;
        color: ${baseVariables.color['close-silver']};
      }

      .rnb-pagination-arrow-next {
        width: auto;
        height: ${toRem(24)};
        padding: ${toRem(2)} 0;
        &.disabled {
          pointer-events: none;
          color: ${baseVariables.color['close-silver']};
        }
      }

      .icon-arrow-right,
      .icon-arrow-left {
        &:before {
          width: ${toRem(24)};
          height: ${toRem(24)};
          cursor: pointer;
        }
      }
    }

    .rnb-pagination-arrow:not(.disabled) {
      cursor: pointer;
    }
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      .page-arrow-container {
        .icon-arrow-left {
          &::before{
            content: "\\e922";
          }
        }
        .icon-arrow-right {
          &::before{
            content: "\\e921";
          }
        }
      }
    }
    `}
`;
