import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, font } = baseVariables;

export const StyledTextFormField = styled.div`
  &.m-input-field {
    caret-color: ${color.merch30};
    &.is-disabled {
      input {
        opacity: 1;
      }
    }
    &.hide-cursor {
      caret-color: transparent;
    }
  }

  label {
    display: block;
    max-width: calc(100% - ${toRem(22)});
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    text-overflow: ellipsis;
  }

  .trailing-element:focus .icon {
    outline: -webkit-focus-ring-color auto ${toRem(1)};
  }

  .icon {
    cursor: pointer;
  }

  .text-container {
    display: flex;
    justify-content: space-between;
  }

  .contain {
    line-height: 1;
    margin-top: ${toRem(4)};
  }

  &.is-error {
    input {
      box-shadow: none !important;
    }
    span:first-of-type.icon {
      font-size: ${font.fontXl};
      color: ${color.merch30};
    }
  }
`;
