import { AddressType, CountryInfo, SittingArrangement } from '../rfp/submitRfp.types';
import { ResponseStatus } from '../roominglist/responseStatus.types';

export type SubmitQuickGroupInput = {
  propertyCode: string;
  hasGuestRoom: boolean;
  currency: string;
  eventDetails?: EventDetails[];
  isThreePlusGuestRequested?: boolean;
  billingDetails?: BillingDetails;
  reservationMethod?: number;
  guestRoomComment?: string;
  eventSpaceComment?: string;
  contractDetails: ContractDetails;
  eventProfile: EventProfile;
  guests: GuestDetails;
  paymentMethodId?: string;
  isSubmitInquiry?: boolean;
};

type EventDetails = {
  date: string;
  roomType?: RoomTypeDetails[];
  eventSpace?: EventSpaceDetails[];
};

export type RoomTypeDetails = {
  roomPoolCode: string;
  segmentCode: string;
  roomTitle: string;
  count: number;
  rateDetails: RateDetails;
  isPrime: boolean;
};

export type EventSpaceDetails = {
  functionSpaceID: string;
  functionSpaceName: string;
  numberOfAttendees: number;
  startTime: string;
  endTime: string;
  sittingArrangement: SittingArrangement;
  sittingArrangementRate: {
    name: string;
    rateDetails: RateDetails;
  };
};

type RateDetails = {
  amount: {
    value: number;
    decimalPoint?: number;
  };
  unit?: {
    code: string;
    description: string;
  };
};

type BillingDetails = {
  type: BillingType;
  isCustomWebPageRequired: boolean;
  resMethod: string;
};

export enum BillingType {
  EPO = 'EPO',
  SRT = 'SRT',
  SAC = 'SAC',
}

export type ContractDetails = {
  contractType: string;
  isContractSigned?: boolean;
  cancellationFeeDetails: {
    rateDetails: RateDetails;
  };
  roomAttrition: {
    rateDetails: RateDetails;
  };
  taxAndFeeDetails?: {
    roomFees?: string;
    roomTaxes?: string;
    eventFees?: string;
  };
};

type EventProfile = {
  startDate: string;
  endDate: string;
  cutOffDate?: string;
  eventType?: EventType;
  numberOfRooms?: number;
  numberOfAttendees?: number;
  name: string;
  brideLastName?: string;
  groomLastName?: string;
};

export enum EventType {
  BUSINESS = 'BUSINESS',
  WEDDING = 'WEDDING',
  SOCIAL = 'SOCIAL',
}

type GuestDetails = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  rewardId?: string;
  address: AddressDetails;
};

type AddressDetails = {
  type: AddressType;
  companyName?: string;
  line1: string;
  line2?: string;
  country: CountryInfo;
  city: string;
  state: string;
  zipCode: string;
};

export type SubmitQuickGroupResponse = ResponseStatus & {
  status: number;
  data: string;
};
