import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, mediaQuery } = baseVariables;

export const StyledGroupSearchForm = styled.div`
  position: relative;
  .search-form {
    border-radius: ${toRem(12)};
    background-color: ${color.base20};
    box-shadow: 0 ${toRem(2)} ${toRem(8)} 0 rgba(0, 0, 0, 0.24);
    position: absolute;
    top: ${toRem(-60)};
    z-index: 1;
    left: 0;
    right: 0;
    @media ${mediaQuery.md} {
      top: ${toRem(-67)};
    }
    @media ${mediaQuery.lg} {
      top: ${toRem(-110)};
    }
  }

  .mobile-container {
    z-index: 50;
  }

  .search-results-form {
    .container {
      padding: 0;
    }
    &.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 50;
      background: ${color.base20};
      margin: 0 auto !important;
      .container {
        margin: inherit;
        padding: ${toRem(32)} ${toRem(16)};
      }
    }
  }

  .search-form-loading {
    height: ${toRem(57)};
    @media ${mediaQuery.md} {
      height: ${toRem(121)};
    }
  }
`;
