import { FC, useEffect, useState } from 'react';
import { Link, Image, Button } from '@marriott/mi-ui-library';
import { MiniPropertyCardProps } from './MiniPropertyCard.types';
import { StyledMiniPropertyCard } from './MiniPropertyCard.styles';
import { PropertyImage, PropertyImageType } from '../PropertyCard.types';
import { getPropertyImages } from '../../../../utils';

export const MiniPropertyCard: FC<MiniPropertyCardProps> = ({ media, propertyName, reviewsAndDistance, footer }) => {
  const [image, setImage] = useState<PropertyImage>();

  useEffect(() => {
    setImage(getPropertyImages(PropertyImageType.MINI, media.primaryImage)[0]);
  }, [media]);

  return (
    <StyledMiniPropertyCard className="d-flex property-card" data-testid="mini-property-card">
      <div className="p-0 image-section">
        <Image
          defaultImageURL={image?.defaultImageUrl}
          altText={image?.altText}
          customClass="property-image"
          loading={'eager'}
        />
      </div>
      <div className="main-section">
        <div className="description">
          <div className="t-subtitle-m">
            <Button className="p-0 text-left title-link" callback={propertyName.onClick}>
              <div className="mb-1 title">{propertyName.title}</div>
            </Button>
          </div>
          <div className="reviews-miles d-flex">
            {reviewsAndDistance.reviewCount ? (
              <Link
                text={''}
                linkClassName={'ratings-container d-flex'}
                ariaLabel={reviewsAndDistance.reviewCount || ''}
                linkHref={reviewsAndDistance.reviewLink}
              >
                <span className="icon-alt icon-star-fill"></span>
                <span>{reviewsAndDistance.rating}</span>
              </Link>
            ) : null}
            {reviewsAndDistance.reviewCount && reviewsAndDistance.distance ? <div>|</div> : null}
            {reviewsAndDistance.distance ? (
              <div className="m-icon-text">
                <span className="icon-location"></span>
                <span>{reviewsAndDistance.distance}</span>
              </div>
            ) : null}
          </div>
        </div>
        {footer ? (
          <div className="p-2 d-flex justify-content-end divider">
            <Link
              text={footer.label || ''}
              linkClassName="m-link pb-0 footer-link"
              linkHref={footer.linkUrl || ''}
              callback={footer.onClick}
            ></Link>
          </div>
        ) : null}
      </div>
    </StyledMiniPropertyCard>
  );
};
