import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { PropertyCardType } from '../../../molecules';
import { PropertyListProps } from './PropertyList.types';
import { PropertyCard } from './PropertyCard';
import { StyledPropertyList } from './PropertyList.styles';
import { PropertyQuickViewModal } from '../../../molecules';

export const PropertyList: FC<PropertyListProps> = ({ reslinkType, isMapView = false, propertyLabels, properties }) => {
  const [showQuickViewModal, setShowQuickViewModal] = useState<boolean>(false);
  const [quickViewPropertyId, setQuickViewPropertyId] = useState<string | number>('');

  const openQuickViewModal = (propertyId: string | number) => {
    setShowQuickViewModal(true);
    setQuickViewPropertyId(propertyId);
  };

  return (
    <StyledPropertyList>
      <div className={clsx(isMapView && 'pb-2 compact-card-list')}>
        {properties.map(propertyData => (
          <PropertyCard
            key={propertyData.property.id}
            type={isMapView ? PropertyCardType.COMPACT : PropertyCardType.REGULAR}
            reslinkType={reslinkType}
            isMapView={isMapView}
            propertyLabels={propertyLabels}
            propertyData={propertyData}
            openQuickViewModal={openQuickViewModal}
          />
        ))}
      </div>

      {showQuickViewModal ? (
        <PropertyQuickViewModal
          show={showQuickViewModal}
          visibilityConfig={{
            capacity: false,
            equipmentAndServices: false,
            footer: false,
          }}
          labels={propertyLabels}
          propertyId={quickViewPropertyId}
          error={propertyLabels.apiError}
          onCloseModal={() => {
            setShowQuickViewModal(false);
          }}
        />
      ) : null}
    </StyledPropertyList>
  );
};
