import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { RadiobuttonControlsList } from '@marriott/mi-ui-library';
import { SingleSelectListProps, ControlOptionProps } from './SingleSelectList.types';
import { StyledSingleSelectList } from './SingleSelectList.styles';
import { TAB_KEY, ENTER_KEY } from '../../constants';
import { TextFormField } from '../index';
import { useFocusOut } from '../../hooks';

export const SingleSelectList = React.forwardRef<HTMLInputElement, SingleSelectListProps>(
  (
    {
      name,
      label,
      ariaLabel,
      placeholder,
      eyebrowText,
      options,
      className,
      panelClassName,
      disabled = false,
      expandIconAriaLabel = 'expand icon',
      collapseIconAriaLabel = 'collapse icon',
      isError,
      errorMessage,
      onChange,
    },
    ref
  ) => {
    const inputId = React.useId();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<ControlOptionProps[]>();
    const singleSelectListRef = useRef<HTMLInputElement>(null);
    const inputFieldRef = useRef<HTMLInputElement>(null);

    const optionProps = {
      name,
      eyebrowText,
      controls: options.map(option => {
        return {
          label: option.label,
          code: option.code,
        };
      }),
      enableShowMoreToggle: false,
      colLength: 12,
      tabIndexForInput: -1,
    };

    useEffect(() => {
      const option = options
        .filter(option => option.selected === true)
        .map(option => {
          return {
            label: option.label,
            code: option.code,
          };
        });
      if (option) {
        setSelectedOption([option[0]]);
      }
    }, [options]);

    const handleChange = (option: ControlOptionProps[]) => {
      setSelectedOption(option);
      onChange(option[0]);
      setIsOpen(false);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (!event.relatedTarget) setIsOpen(true);
    };

    const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isOpen && event.key === ENTER_KEY) {
        setIsOpen(true);
        event.preventDefault();
      }
      if (isOpen && event.shiftKey && event.key === TAB_KEY) {
        setIsOpen(false);
        event.preventDefault();
        if (singleSelectListRef && singleSelectListRef.current) {
          singleSelectListRef.current.focus();
        }
      }
    };

    useFocusOut({
      ref: singleSelectListRef.current,
      onFocusOut: (isFocusOut: boolean) => {
        if (isFocusOut) {
          setIsOpen(false);
        }
      },
      handleKeyup: true,
    });

    return (
      <StyledSingleSelectList
        data-component-name="m-groups-SingleSelectList"
        data-testid="groups-SingleSelectList"
        ref={singleSelectListRef}
        className={clsx('m-input-field', className, isError ? 'is-error' : '')}
      >
        <TextFormField
          type="text"
          label={label}
          ariaLabel={ariaLabel}
          placeholder={placeholder}
          value={selectedOption?.[0]?.label || ''}
          className={clsx('t-font-s', disabled ? 'is-disabled' : '')}
          readOnly={true}
          ref={inputFieldRef || ref}
          testId={inputId}
          showIcon={!disabled}
          iconClass={!isOpen ? 'icon icon-arrow-down' : 'icon icon-arrow-up'}
          iconAriaLabel={!isOpen ? expandIconAriaLabel : collapseIconAriaLabel}
          isIconClickable={true}
          iconClickHandler={() => {
            setIsOpen(!isOpen);
            if (inputFieldRef && inputFieldRef.current) {
              inputFieldRef.current.focus();
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
          onFocus={handleFocus}
          onKeyDown={handleKeydown}
          onClick={() => setIsOpen(true)}
        />
        <ul
          tabIndex={-1}
          className={clsx(
            panelClassName,
            'm-exp-dropdown-panel',
            isOpen === true ? 'd-block' : 'd-none',
            'option-layout',
            'pl-2'
          )}
        >
          {selectedOption ? (
            <RadiobuttonControlsList {...optionProps} onChange={handleChange} defaultSelected={selectedOption} />
          ) : (
            <div>
              <RadiobuttonControlsList {...optionProps} onChange={handleChange} />
            </div>
          )}
        </ul>
        {isError && <span className={'error-text t-font-xs'}>{errorMessage}</span>}
      </StyledSingleSelectList>
    );
  }
);
