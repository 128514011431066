import { FC } from 'react';
import clsx from 'clsx';
import { Button, Types, baseVariables } from '@marriott/mi-ui-library';
import { PropertyCardFormData, PropertyCardProps } from './PropertyCard.types';
import { StyledPropertyCard } from './PropertyCard.styles';
import {
  PropertyCard as CommonPropertyCard,
  DatePickerDesktop,
  DatePickerMobileContainer,
  PropertyCardType,
  RoomsAndGuests,
} from '../../../../molecules';
import { useMediaQuery } from '../../../../hooks';
import {
  getPropertyAirportDistanceLabel,
  getPropertyItemValue,
  getFormattedDateString,
  getReviewsUrl,
  getFormattedDateObject,
  getFormattedDate,
  getFormattedCalendarDate,
} from '../../../../utils';
import { AVAILABILITY_SEARCH_URL, CHINESE_LANG, DEFAULT_LANG, LOCALE_MAP } from '../../../../constants';
import { getFilteredRates, getRateRange } from '../../../../admin/utils/reslink';
import { ReslinkType } from '@marriott/mi-groups-graphql';
import { Controller, useForm } from 'react-hook-form';
import { RoomsAndGuestsCount } from '../../../../molecules/RoomsAndGuests/RoomsAndGuests.types';
import { useLocaleStore } from '../../../../store';

export const PropertyCard: FC<PropertyCardProps> = ({
  type,
  reslinkType,
  isMapView,
  propertyLabels,
  propertyData,
  openQuickViewModal,
}) => {
  const { bookBy, toAirport, viewHotelDetails, priceType, dateLabels, roomsAndGuests, checkAvailability } =
    propertyLabels;
  const { groupCodes, corporateCode, maxOccupancy, overriddenStartDate, overriddenEndDate, property, miniHotelInfo } =
    propertyData;
  const { media, airports, reviews, roomTypes, basicInformation: propertyBasicInformation } = property || {};
  const { brand, name: propertyName, descriptions } = propertyBasicInformation || {};
  const { startDate, endDate, basicInformation, rates } = miniHotelInfo?.[0]?.node || {};

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);
  const { locale } = useLocaleStore();

  const isCompactCard = !isTabletAndAbove || type === PropertyCardType.COMPACT;
  const isCorporate = reslinkType === ReslinkType.CORP;

  const cutoffDate = getFormattedDateString(basicInformation?.cutoffDate || '', 'dateWithMonthAndYear');
  const reviewsText = reviews?.numberOfReviews?.count
    ? `${getPropertyItemValue(reviews.numberOfReviews.count)} ${propertyLabels.reviews}`
    : '';
  const airportDistanceText = getPropertyAirportDistanceLabel(airports, toAirport);
  const overview = descriptions?.find(item => item.type.code === 'location')?.localizedText?.translatedText;

  const filteredRates = getFilteredRates(groupCodes, rates);
  const rate = filteredRates && getRateRange(filteredRates);
  const minRate = !isCorporate && rate ? rate.minRate : 0;

  const reviewUrl = getReviewsUrl(property.seoNickname, brand?.id);

  const actualStartDate = overriddenStartDate || startDate;
  const actualEndDate = overriddenEndDate || endDate;
  const selectedStartDate = !isCorporate && actualStartDate ? getFormattedDateObject(actualStartDate) : null;
  const selectedEndDate = !isCorporate && actualEndDate ? getFormattedDateObject(actualEndDate) : null;

  const {
    control,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<PropertyCardFormData>({
    defaultValues: {
      dates: '',
      startDate: getFormattedDate(selectedStartDate),
      endDate: getFormattedDate(selectedEndDate),
      roomsAndGuests: {
        rooms: roomsAndGuests.minimumRoom,
        adults: roomsAndGuests.minimumAdults,
        children: roomsAndGuests.minimumChildren,
        childrenAge: [],
      },
    },
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
  });

  const getMaxGuestsPerRoom = () => {
    const roomTypeCodes = roomTypes?.edges?.map(edge => edge?.node?.roomTypeCode);
    const maxRoomTypeOccupancyList = rates
      ?.filter(
        rate =>
          groupCodes?.length &&
          groupCodes.includes(rate.groupCode as string) &&
          roomTypeCodes?.length &&
          roomTypeCodes.includes(rate.roomType)
      )
      .map(rate => rate?.roomTypeDetail?.maxOccupancy || 0);
    const defaultMaxGuestsPerRoom = roomsAndGuests.corporateMaxGuestsPerRoom as number;
    const maxRoomTypeOccupancy = maxRoomTypeOccupancyList?.length
      ? Math.max(...maxRoomTypeOccupancyList)
      : defaultMaxGuestsPerRoom;

    const maxGuestsPerRoom = isCorporate ? defaultMaxGuestsPerRoom : +maxOccupancy || maxRoomTypeOccupancy;
    return maxGuestsPerRoom;
  };

  const isValidChildrenAge = (value: RoomsAndGuestsCount) => {
    if (value.children >= 1 && value.childrenAge.some(age => age === roomsAndGuests.age)) {
      return roomsAndGuests.childrensAgeRangeError;
    }
    return true;
  };

  const handleDatesChange = (dates: string, startDate: string, endDate: string) => {
    setValue('dates', dates);
    setValue('startDate', startDate);
    setValue('endDate', endDate);
  };

  const handleRoomsAndGuestsChange = (updatedRoomsAndGuests: RoomsAndGuestsCount) => {
    clearErrors('roomsAndGuests');
    setValue('roomsAndGuests', updatedRoomsAndGuests);
  };

  const checkAvailabilityCallback = (data: PropertyCardFormData) => {
    const {
      startDate,
      endDate,
      roomsAndGuests: { rooms, adults, children, childrenAge },
    } = data;

    const params: { [key: string]: string } = {
      isResLink: 'true',
      propertyCode: propertyData?.property?.id.toString() || '',
      clusterCode: isCorporate ? 'corp' : 'group',
      fromDate: getFormattedCalendarDate(startDate, 'dateWithMonthNoAndYear'),
      toDate: getFormattedCalendarDate(endDate, 'dateWithMonthNoAndYear'),
      numberOfRooms: rooms.toString(),
      numberOfAdults: adults.toString(),
      numberOfChildren: children.toString(),
      childrenAges: childrenAge.map(item => (item === '<1' ? '0' : item)).join(),
    };

    if (isCorporate) {
      params['corporateCode'] = corporateCode;
      params['legacycorpreslink'] = 'false';
      params['israteCalendar'] = 'false';
    } else {
      params['groupCodes'] = groupCodes?.join();
      params['groupCode'] = groupCodes[0];
    }

    const queryParams = new URLSearchParams(params);
    const rlmLocale =
      locale !== DEFAULT_LANG && locale !== CHINESE_LANG && LOCALE_MAP[locale]
        ? `/${LOCALE_MAP[locale]['rlmLocale']}`
        : '';
    window.location.href = `${rlmLocale}${AVAILABILITY_SEARCH_URL}?${decodeURIComponent(queryParams.toString())}`;
  };

  const datesField = (
    <Controller
      control={control}
      name="dates"
      render={() => (
        <DatePickerDesktop
          dates={{ ...dateLabels, ariaLabel: `${dateLabels.ariaLabel} ${propertyName || ''}` }}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          isDateRangeFixed={!isCorporate}
          rangeStartDate={selectedStartDate}
          rangeEndDate={selectedEndDate}
          showFlexibleDates={false}
          align="left"
          onChange={handleDatesChange}
        />
      )}
    />
  );

  const mobileDatesField = (
    <Controller
      control={control}
      name="dates"
      render={() => (
        <DatePickerMobileContainer
          dates={{ ...dateLabels, ariaLabel: `${dateLabels.ariaLabel} ${propertyName || ''}` }}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          isDateRangeFixed={!isCorporate}
          rangeStartDate={selectedStartDate}
          rangeEndDate={selectedEndDate}
          onChange={handleDatesChange}
        />
      )}
    />
  );

  const roomsAndGuestsField = (
    <Controller
      control={control}
      name="roomsAndGuests"
      rules={{
        validate: isValidChildrenAge,
      }}
      render={({ field }) => (
        <RoomsAndGuests
          {...field}
          labels={{
            ...roomsAndGuests,
            maxGuestsPerRoomCount: getMaxGuestsPerRoom(),
          }}
          ariaLabel={`${roomsAndGuests.ariaLabel} ${propertyName || ''}`}
          showErrorMessage={!!errors.roomsAndGuests}
          errorMessage={roomsAndGuests.childrensAgeRangeError}
          onChange={handleRoomsAndGuestsChange}
        />
      )}
    />
  );

  return (
    <StyledPropertyCard data-testid="reslink-property-card">
      <CommonPropertyCard
        id={propertyData.property.id}
        type={type}
        media={media}
        brand={brand}
        mediaLabels={{
          carouselAriaLabel: propertyLabels.carouselAriaLabel,
          carouselImageAriaLabel: propertyLabels.carouselImageAriaLabel,
          logoAriaLabel: propertyLabels.logoAriaLabel,
        }}
        badge={isCorporate ? '' : basicInformation?.cutoffDate ? `${bookBy} ${cutoffDate}` : ''}
        propertyName={{
          title: propertyName || '',
          onClick: () => openQuickViewModal(property.id),
        }}
        reviewsAndDistance={{
          rating: `${getPropertyItemValue(reviews?.stars?.count)}`,
          reviewCount: reviewsText,
          reviewLink: reviewUrl,
          reviewTooltipText: propertyLabels.reviewTooltipText,
          distance: airportDistanceText,
        }}
        showDescription={!!isTabletAndAbove && !isMapView}
        description={overview || ''}
        viewDetailsCta={{
          label: viewHotelDetails,
          onClick: () => openQuickViewModal(property.id),
        }}
        price={{
          amount: +minRate,
          currencyLabel: `${rate?.currency} / ${priceType}`,
        }}
      />

      <form
        className={clsx('px-3 pt-4 pb-4 footer', isCompactCard && 'compact-footer')}
        onSubmit={handleSubmit(checkAvailabilityCallback)}
      >
        <div className="flex-grow-1 date-picker-container" data-testid="date-picker">
          {isTabletAndAbove && !isCompactCard ? datesField : mobileDatesField}
        </div>
        <div className="flex-grow-1 my-3 my-md-0 mx-md-3 rooms-guests" data-testid="room-and-guest">
          {roomsAndGuestsField}
        </div>
        <Button
          type={Types.ButtonTypeVariation.Submit}
          buttonCopy={checkAvailability}
          className="m-button-m m-button-primary check-availability"
        />
      </form>
    </StyledPropertyCard>
  );
};
