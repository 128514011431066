declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

export const getCurrentLocation = (callback: CallableFunction) => {
  let currentLocation: Record<string, string>;

  // fetching the latitude and longitude
  navigator.geolocation.getCurrentPosition(
    (location: GeolocationPosition) => {
      const lat = location.coords.latitude;
      const lng = location.coords.longitude;

      if (window.google) {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat, lng };
        // using Goggle Maps API reverse geocoding service to convert latlng to location details
        geocoder.geocode(
          { location: latlng },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (results: any, status: any) => {
            if (status === 'OK') {
              if (results[0]) {
                // only store the data required for hidden fields and input field
                currentLocation = {
                  placeId: results[0].place_id,
                  address: results[0].formatted_address,
                  types: results[0].types.join(),
                };
                // using callback to pass the data
                callback(currentLocation);
              } else {
                window.alert('No location available.');
              }
            }
          }
        );
      }
    },
    (error: GeolocationPositionError) => {
      window.alert('Please enable geolocation in your browser settings.');
      return error;
    }
  );
};

// To call geoCoder and get placeid for destinations from recent searches
export function getGeocodeAddress(location: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geocoder.geocode({ address: location }, (results: Record<string, any>[], status: string) => {
      if (status === 'OK') {
        if (results?.[0]) {
          resolve(results?.[0]?.['place_id']);
        } else {
          reject(new Error('No results found'));
        }
      } else {
        reject(new Error(`Geocoder failed due to: ${status}`));
      }
    });
  });
}
