import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { mediaQuery } = baseVariables;

export const StyledGroupSearchFormDesktop = styled.div<{ isSearchResultPage?: boolean }>`
  padding: ${props => (!props.isSearchResultPage ? `${toRem(32)} 0` : `0`)};

  .pb-40 {
    padding-bottom: ${toRem(40)};
  }

  .w-100 {
    width: 100%;
  }

  @media ${mediaQuery.allTablets} {
    padding: ${props => (!props.isSearchResultPage ? `${toRem(40)} ${toRem(16)}` : `0`)};
  }
`;
