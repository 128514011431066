import forge from 'node-forge';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

export const encryptPassword = (password: string) => {
  const clientVars = useClientEnvVarsStore.getState().envVarsObject;
  const publicKey = process.env['NEXT_PUBLIC_ENCRYPTION_KEY'] || clientVars['NEXT_PUBLIC_ENCRYPTION_KEY'];
  if (publicKey) {
    const key = forge.pki.publicKeyFromPem(publicKey);
    const encryptedPassword = key.encrypt(password, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha256.create(),
      },
    });
    return forge.util.encode64(encryptedPassword);
  } else {
    return password;
  }
};
