import { canUseDOM } from '@marriott/mi-ui-library';
import { useState, useEffect } from 'react';

function matchScreen(query: string) {
  return window.innerWidth >= parseInt(query.match(/\d+/)?.[0] as string);
}

export const useMediaQuery = (query: string): boolean | null => {
  // State to store the current match status
  const [matches, setMatches] = useState<boolean | null>(canUseDOM ? matchScreen(query) : null);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    // Attach the event listener to the media query
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Initial check of the media query
    setMatches(mediaQuery.matches);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [query]);

  // Return the current match status
  return matches;
};
