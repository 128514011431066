import { useState } from 'react';

export const useFocusState = (initialState = {}) => {
  const [focused, setFocused] = useState<{ [key: string]: boolean }>(initialState);

  const handleFocus = (fieldName: string) => {
    setFocused(prevState => ({
      ...prevState,
      [fieldName]: true,
    }));
  };

  const handleBlur = (fieldName: string) => {
    setFocused(prevState => ({
      ...prevState,
      [fieldName]: false,
    }));
  };

  return { focused, handleFocus, handleBlur };
};
