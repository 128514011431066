import { FC, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getSuggestedPlacesQuery, SuggestionDataTypesForInput, SuggestedPlacesNode } from '@marriott/mi-groups-graphql';
import type { TypeAheadFlyoutMobileProps } from './TypeAheadFlyoutMobile.types';
import { StyledTypeAheadFlyoutMobile } from './TypeAheadFlyoutMobile.styles';
import { RecentList } from '../DefaultFlyout/RecentList';
import { TYPE_AHEAD_DELAY } from '../../../../constants';

export const TypeAheadFlyoutMobile: FC<TypeAheadFlyoutMobileProps> = ({
  currentValue,
  customHeaders,
  updateDestination,
  setShowDefaultFlyout,
  setShowTypeAheadFlyout,
  onChange,
  show,
}) => {
  const [optionList, setOptionList] = useState<SuggestionDataTypesForInput[]>([]);
  const [getSuggestedPlaces, { data: suggestedPlacesData, loading }] = useLazyQuery(getSuggestedPlacesQuery);
  const [timerList, setTimerList] = useState<ReturnType<typeof setTimeout>[]>([]);

  useEffect(() => {
    if (loading) {
      setOptionList([]);
    } else {
      setOptionList(
        suggestedPlacesData?.suggestedPlaces.edges?.map((suggestedPlaces: SuggestedPlacesNode) => suggestedPlaces.node)
      );
      setShowDefaultFlyout(false);
    }
  }, [loading, setShowDefaultFlyout, suggestedPlacesData]);

  const clearTimerList = (timerList: ReturnType<typeof setTimeout>[]) => {
    if (timerList.length) {
      for (let i = timerList.length - 1; i >= 0; i--) {
        clearTimeout(timerList[i]);
      }
      setTimerList([]);
    }
  };

  useEffect(() => {
    // delaying API call till the input value finishes updating
    if (currentValue) {
      clearTimerList(timerList);
      const timer = setTimeout(() => {
        getSuggestedPlaces({
          variables: {
            query: currentValue,
          },
          context: {
            headers: customHeaders,
          },
          fetchPolicy: 'network-only',
        });
      }, TYPE_AHEAD_DELAY);
      setTimerList([...timerList, timer]);
    } else {
      // cancel current fetch timeout timer if user delete the input value
      clearTimerList(timerList);
      setOptionList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleDestinationSelection = (value: string, placeId?: string) => {
    onChange(value);
    updateDestination(value, placeId);
    setShowDefaultFlyout(true);
    setShowTypeAheadFlyout(false);
  };

  return (
    <StyledTypeAheadFlyoutMobile data-testid="type-ahead-mobile">
      {show && (
        <RecentList
          itemList={optionList}
          nameKey={'description'}
          valueKey={'description'}
          handleSelection={handleDestinationSelection}
        />
      )}
    </StyledTypeAheadFlyoutMobile>
  );
};
