import { FC, useEffect } from 'react';
import {
  GroupRate,
  GroupsRateProperty,
  Property,
  PropertySearchSortField,
  PropertySearchSortFieldDirection,
  PropertySearchSortFieldInput,
} from '@marriott/mi-groups-graphql';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PaginationPanel } from '@marriott/mi-rnb-components';
import { SingleSelectFlyout } from '../../../molecules';
import { useSearchResultsStore, useLocaleStore, useQuickGroupStore } from '../../../store';
import { SEARCH_RESULTS_PAGE_SIZE } from '../../../constants';
import { bookNow, generatePaginationLabel, getBookNowPayload } from '../../../utils';
import { PropertyCard } from '../PropertyCard';
import { SkeletonLoader, StyledSkeletonLoaderAtom } from '../SkeletonLoader';
import { ListViewProps } from './ListView.types';
import { StyledListView } from './ListView.styles';
import clsx from 'clsx';

export const ListView: FC<ListViewProps> = ({ sortFilter, property, pagination, ctas }) => {
  const { properties, searchResults, loader, currentPage, showMapView, updatePage, updateSort, searchQuery } =
    useSearchResultsStore();
  const { locale } = useLocaleStore();

  const { total: totalSearchResults, pageInfo } = searchResults;
  const { previous, next } = pagination;

  useEffect(() => {
    if (showMapView) {
      const cardPanel = document.getElementById('card-panel');
      if (cardPanel) {
        cardPanel.scrollTop = 0;
      }
    }
  }, [properties, showMapView]);

  const handleBookNow = async (property: Property, groupRate?: GroupRate | null) => {
    try {
      if (groupRate) {
        const bookNowPayload = getBookNowPayload(property, groupRate, searchQuery, locale);
        const response = await bookNow(ctas.bookNowURL, bookNowPayload);
        const url = new URL(response.request?.responseURL);
        url.searchParams.append('brandCode', bookNowPayload.brandCode);
        useQuickGroupStore.persist.clearStorage();
        window.location.href = url.toString();
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      updatePage((page - 1) * SEARCH_RESULTS_PAGE_SIZE);
      window.scrollTo(0, 0);
    }
  };

  return (
    <StyledListView>
      <div className={showMapView ? 'pl-5 pr-3' : ''}>
        <div
          className={clsx('d-flex justify-content-between align-items-baseline', showMapView && 'pagination-top-row')}
        >
          <div className="t-font-weight-m mb-2 pagination-text">
            {loader ? (
              <StyledSkeletonLoaderAtom className="loader loader__page-info" height={36} width={150} />
            ) : (
              generatePaginationLabel(
                pagination.paginationDetails,
                currentPage,
                SEARCH_RESULTS_PAGE_SIZE,
                totalSearchResults
              )
            )}
          </div>
          <SingleSelectFlyout
            name={sortFilter.sortBy}
            label={sortFilter.sortBy}
            ariaLabel={sortFilter.sortBy}
            options={sortFilter.options}
            selected={searchQuery.sort?.fields?.length ? searchQuery.sort.fields[0].field : ''}
            onChange={option =>
              updateSort(
                option.code as PropertySearchSortField,
                (option as PropertySearchSortFieldInput).direction as PropertySearchSortFieldDirection
              )
            }
          />
        </div>

        <div className={totalSearchResults <= SEARCH_RESULTS_PAGE_SIZE ? 'pb-5' : ''}>
          {loader ? (
            <SkeletonLoader
              itemCount={
                totalSearchResults
                  ? totalSearchResults - currentPage * SEARCH_RESULTS_PAGE_SIZE < SEARCH_RESULTS_PAGE_SIZE
                    ? totalSearchResults % (currentPage * SEARCH_RESULTS_PAGE_SIZE)
                    : SEARCH_RESULTS_PAGE_SIZE
                  : SEARCH_RESULTS_PAGE_SIZE
              }
              variant={showMapView ? 'compact' : 'regular'}
            />
          ) : (
            properties.map(data => (
              <PropertyCard
                key={data.node.id}
                propertyLabels={property}
                ctas={ctas}
                propertyData={data.node}
                groupRate={(data as GroupsRateProperty)?.groupRate}
                bookNowCallback={handleBookNow}
              />
            ))
          )}
        </div>
      </div>

      <div className={clsx('pagination', showMapView ? 'pagination-bottom-row' : '')}>
        {loader ? (
          <StyledSkeletonLoaderAtom className="loader loader__page-info mb-2" height={28} />
        ) : (
          pageInfo && (
            <PaginationPanel
              currentPage={currentPage}
              totalCount={totalSearchResults}
              pageSize={SEARCH_RESULTS_PAGE_SIZE}
              labels={{ previous, next }}
              onPageChange={handlePageChange}
            />
          )
        )}
      </div>
    </StyledListView>
  );
};
