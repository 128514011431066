import { Control, FieldErrors, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';
import { OptionType } from '../EventInfo/EventInfo.types';

export type WeddingInfoFormData = {
  weddingInfo: {
    firstSpouseType: string;
    firstSpouseLastName: string;
    secondSpouseType: string;
    secondSpouseLastName: string;
  };
};

export type WeddingInfoProps = {
  labels: WeddingInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<WeddingInfoFormData>;
  clearErrors: UseFormClearErrors<WeddingInfoFormData>;
};

export type WeddingInfo = {
  title: string;
  description: string;
  coupleType: DropdownType;
  firstCoupleLastName: InputField;
  secondCoupleLastName: InputField;
  requiredError: string;
};

type InputField = {
  label: string;
  ariaLabel: string;
};

type DropdownType = {
  label: string;
  ariaLabel: string;
  select: string;
  options: OptionType[];
  collapseIconAriaLabel: string;
  expandIconAriaLabel: string;
};

export enum SpouseType {
  BRIDE = 'Bride',
  GROOM = 'Groom',
}
