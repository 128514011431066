import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

const { color, font } = baseVariables;

export const StyledCheckBoxFormField = styled.div`
  &.is-error {
    input[type='checkbox'] + label:before {
      border-color: ${color.alert50};
    }

    input[type='checkbox']:focus + label:before {
      outline-color: ${color.alert50};
    }
  }

  input[type='checkbox'] + label {
    margin-bottom: 0;
    font-size: ${font.fontXs};
    line-height: ${font.lineHeightS};
  }

  .error-message {
    color: ${color.alert50};
    display: block;
  }
`;
