import React from 'react';
import type { SkeletonLoaderProps } from './SkeletonLoader.types';
import { StyledSkeletonLoaderDiv, StyledSkeletonLoaderContainer } from './SkeletonLoader.styles';

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ itemCount, variant }) => {
  return (
    <StyledSkeletonLoaderContainer className="sl-container">
      {[...Array(itemCount)].map((key, index) => (
        <StyledSkeletonLoaderDiv className={`sl ${variant}`} key={index}>
          {variant === 'regular' ? (
            <>
              <div className={`sl__left ${key ?? ''}`}>
                <div
                  className="loader loader__image"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                ></div>
              </div>
              <div className="sl__right">
                <div className="sl__right-top">
                  <div
                    className="loader loader__property-title mb-2"
                    style={{
                      width: '100%',
                      height: '33px',
                    }}
                  ></div>
                  <div
                    className="loader loader__property-reviews mb-3"
                    style={{
                      width: '100%',
                      height: '20px',
                    }}
                  ></div>
                  <div
                    className="loader loader__property-description mb-5"
                    style={{
                      width: '100%',
                      height: '36px',
                    }}
                  ></div>
                  <div
                    className="loader loader__view-hotel-details my-5"
                    style={{
                      width: '30%',
                      height: '17px',
                    }}
                  ></div>
                </div>
                <div className="sl__right-bottom">
                  <div
                    className="loader loader__add-to-rfp mt-5"
                    style={{
                      width: '25%',
                      height: '32px',
                      borderRadius: 'var(--t-button-border-radius, 22px)',
                    }}
                  ></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`sl__top ${key ?? ''}`}>
                <div
                  className="loader loader__image"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                ></div>
              </div>
              <div className="sl__bottom">
                <div className="sl__right-top">
                  <div
                    className="loader loader__property-title mb-1"
                    style={{
                      width: '100%',
                      height: '44px',
                    }}
                  ></div>
                  <div
                    className="loader loader__property-reviews mb-2"
                    style={{
                      width: '100%',
                      height: '20px',
                    }}
                  ></div>
                  <div
                    className="loader loader__view-hotel-details mb-2"
                    style={{
                      width: '30%',
                      height: '17px',
                    }}
                  ></div>
                </div>
                <div className="sl__right-bottom">
                  <div
                    className="loader loader__add-to-rfp mt-3"
                    style={{
                      width: '25%',
                      height: '32px',
                      borderRadius: 'var(--t-button-border-radius, 22px)',
                    }}
                  ></div>
                </div>
              </div>
            </>
          )}
        </StyledSkeletonLoaderDiv>
      ))}
    </StyledSkeletonLoaderContainer>
  );
};
