import { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { baseVariables } from '@marriott/mi-ui-library';
import { PropertyMapProps } from './PropertyMap.types';
import { StyledPropertyMap } from './PropertyMap.styles';
import { PropertyList } from '../PropertyList';
import { Drawer, MapView, ViewToggle } from '../../../molecules';
import { useMediaQuery } from '../../../hooks';
import { ViewType } from '../../SearchResults/SearchResults.types';
import { StyledDrawerContent } from '../../../molecules/Drawer/Drawer.styles';
import { RESLINK_MAP_VIEW_HEIGHT_OFFSET } from '../../../constants';
import { Property } from '@marriott/mi-groups-graphql';

export const PropertyMap: FC<PropertyMapProps> = ({ reslinkType, properties, propertyLabels, toggleView }) => {
  const { list, listAriaLabel, propertiesPanel } = propertyLabels;

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const [showDrawer, setShowDrawer] = useState(true);

  useEffect(() => {
    // it will be refactored when the model json structure will change
    const el = document.querySelectorAll('.root');
    const footer = el[el.length - 1];
    footer?.setAttribute('style', 'display: none');
    document.body.setAttribute('style', 'min-height: auto');
    return () => {
      footer?.setAttribute('style', 'display: block');
      document.body.setAttribute('style', 'min-height: 100vh');
    };
  }, []);

  useEffect(() => {
    setShowDrawer(!!isTabletAndAbove);
  }, [isTabletAndAbove]);

  const headerHeight = document.querySelector('.m-header')?.getBoundingClientRect()?.height || 0;
  const mapHeight = window.innerHeight - (headerHeight + RESLINK_MAP_VIEW_HEIGHT_OFFSET);

  const propertyList = properties.map(property => property.property) as unknown as Property[];

  const center = useMemo(
    () => ({
      lat: propertyList[0].basicInformation.latitude ? +propertyList[0].basicInformation.latitude : 0,
      lng: propertyList[0].basicInformation.longitude ? +propertyList[0].basicInformation.longitude : 0,
    }),
    [propertyList]
  );

  const scrollToTop = (propertyId: string | number) => {
    if (propertyId) {
      setShowDrawer(true);
      setTimeout(() => {
        const activePropery = document.querySelector(`.property-${propertyId}`);
        activePropery?.scrollIntoView();
      }, 100);
    }
  };

  return (
    <StyledPropertyMap className="m-container-fullbleed" data-testid="map-view">
      <div className={clsx('align-items-stretch', isTabletAndAbove && 'row mx-0')}>
        <div className={showDrawer ? 'card-panel' : ''} data-testid="card-panel">
          <Drawer
            placement="left"
            initialState={showDrawer}
            variant="drawer"
            labels={propertiesPanel}
            onChange={showDrawer => setShowDrawer(showDrawer)}
          >
            <StyledDrawerContent height={mapHeight} id="card-panel">
              <PropertyList
                reslinkType={reslinkType}
                isMapView={true}
                propertyLabels={propertyLabels}
                properties={properties}
              />
            </StyledDrawerContent>
          </Drawer>
        </div>

        <div className={showDrawer ? 'map-view' : 'col-12 p-0'}>
          <MapView center={center} height={mapHeight} properties={propertyList} onPropertyClick={scrollToTop} />
          <ViewToggle
            ctaLabel={list}
            variant={ViewType.LIST_VIEW}
            ctaAriaLabel={listAriaLabel}
            toggleView={toggleView}
          />
        </div>
      </div>
    </StyledPropertyMap>
  );
};
