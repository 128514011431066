export type ReslinkTypeModalProps = {
  reslinkType: ReservationTypeLabels;
  show: boolean;
  closeModal: () => void;
};

export type ReservationTypeLabels = {
  heading: string;
  group: ReservationType;
  corporate: ReservationType;
};

type ReservationType = {
  heading: string;
  helperText: string;
  ctaLabel: string;
  ctaAriaLabel: string;
  path: string;
};

export enum ReservationLinkType {
  GRP = 'GRP',
  CORP = 'CORP',
}
