import { rest } from 'msw';
import meetingPlanning from './meetingPlanning';

const { NEXT_PUBLIC_AEM_HOST, NEXT_PUBLIC_AEM_PATH } = process.env;
const basePath = `${NEXT_PUBLIC_AEM_HOST}${NEXT_PUBLIC_AEM_PATH}/na/en_us`;

export const modelHandlers = [
  rest.get(`${basePath}/meeting-event-hotels/meeting-planning.model.json`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(meetingPlanning));
  }),
];
