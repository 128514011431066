import { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery } from '@apollo/client';
import { SuggestedPlaceDetails, getSuggestedPlaceDetailsQuery } from '@marriott/mi-groups-graphql';
import { GroupSearchFormData, GroupSearchFormProps } from './GroupSearchForm.types';
import { SearchFormField } from './GroupSearchFormMobile/GroupSearchFormMobile.types';
import { StyledGroupSearchForm } from './GroupSearchForm.styles';
import { GroupSearchFormMobile } from './GroupSearchFormMobile';
import { GroupSearchFormDesktop } from './GroupSearchFormDesktop';
import {
  useLocaleStore,
  useSearchFromStore,
  useSearchResultsStore,
  useRfpTrayStore,
  useMediaQueryStore,
} from '../../store';
import {
  getQueryParams,
  getSearchCriteria,
  getSearchQueryOptions,
  submitSearchForm,
  updateAriesGroupSearchSession,
  updateRecentSearches,
} from '../../utils';
import { baseVariables } from '@marriott/mi-ui-library';
import { useMediaQuery } from '../../hooks';

export const GroupSearchFormConfig = {
  emptyLabel: 'GroupSearchForm',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/groupsearchform`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GroupSearchForm: FC<{ model: GroupSearchFormProps }> = (props: any) => {
  const { eventType, searchResultsUrl, isSearchResultPage, enabledSticky, appliedCssClassNames } = props.model;

  // api call to get the details of suggested places
  const [getSuggestedPlaceDetails] = useLazyQuery(getSuggestedPlaceDetailsQuery);

  const { locale } = useLocaleStore();
  const { isTabletAndAbove, setIsTabletAndAbove } = useMediaQueryStore();
  const { setLoader, setDefaultMobileView, currentStep } = useSearchFromStore();
  const { updateSearchQuery } = useSearchResultsStore();
  const { resetOnDestinationUpdate } = useRfpTrayStore();

  const matchesTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const searchFormRef = useRef<HTMLDivElement | null>(null);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (matchesTabletAndAbove !== null) {
      setIsTabletAndAbove(matchesTabletAndAbove);
    }
  }, [matchesTabletAndAbove, setIsTabletAndAbove]);

  useEffect(() => {
    const navMenu = document.querySelector('.nav-menu');
    const searchResultsForm = searchFormRef?.current?.querySelector('.form-container');
    let scrollPosition = window.scrollY;

    document.body.classList.add('no-scroll');

    // to remove the added style when we stop the scroll inbetween & isSticky is false
    if (!isSticky) {
      navMenu?.removeAttribute('style');
      searchResultsForm?.removeAttribute('style');
    }

    const handleScroll = () => {
      if (!enabledSticky) return;
      if (searchFormRef.current) {
        if (isTabletAndAbove && isSticky && window.scrollY < scrollPosition) {
          navMenu?.setAttribute('style', 'position: fixed; top: 0; z-index: 60');
          searchResultsForm?.setAttribute('style', `top: ${navMenu?.clientHeight || 0}px`);
        } else {
          navMenu?.removeAttribute('style');
          searchResultsForm?.removeAttribute('style');
        }
        scrollPosition = window.scrollY;
        const offsetTop = searchFormRef.current.getBoundingClientRect().top + scrollPosition;
        setIsSticky(offsetTop <= scrollPosition);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledSticky, isSticky]);

  const submitSuccessCallback = () => {
    setLoader(false);
    setDefaultMobileView(true);
  };

  const handleSubmit = async (data: GroupSearchFormData) => {
    let suggestedPlaceDetails = {} as SuggestedPlaceDetails;
    if (data.destination.placeId) {
      const response = await getPlaceDetails(data.destination.placeId);
      suggestedPlaceDetails = response?.suggestedPlaceDetails;
    }
    const queryData = updateRecentSearches(data, suggestedPlaceDetails);
    submitSearchForm(queryData, isSearchResultPage, searchResultsUrl, submitSuccessCallback);
    if (isSearchResultPage) {
      updateSearchQuery(getSearchQueryOptions(getQueryParams(window.location.search)), locale);
      resetOnDestinationUpdate(data.destination.description);
      updateAriesGroupSearchSession();
    }
    if (
      isSearchResultPage &&
      searchFormRef.current &&
      searchFormRef.current.getBoundingClientRect().top < window.scrollY
    ) {
      window.scrollTo(0, 0);
    }
  };

  const getPlaceDetails = async (placeId: string) => {
    try {
      const suggestedPlaceDetails = await getSuggestedPlaceDetails({
        variables: {
          placeId,
        },
        context: {
          headers: {
            'accept-language': locale,
          },
        },
        fetchPolicy: 'network-only',
      });
      return suggestedPlaceDetails.data;
    } catch (e) {
      return {};
    }
  };

  return (
    <EditableComponent config={GroupSearchFormConfig} {...props}>
      <StyledGroupSearchForm
        data-testid="groupsearchform"
        data-component-name="o-groups-groupsearchform"
        ref={searchFormRef}
        className={appliedCssClassNames}
      >
        {isTabletAndAbove !== null ? (
          <div
            className={clsx(
              'form-container',
              isSticky && 'my-5 pt-2 search-results-form sticky',
              !isSticky && isSearchResultPage && 'my-5 pt-2 search-results-form',
              !isSticky && !isSearchResultPage && 'search-form',
              !isSticky &&
                !isSearchResultPage &&
                !isTabletAndAbove &&
                currentStep !== SearchFormField.INITIAL &&
                'mobile-container'
            )}
          >
            <div className="container">
              {isTabletAndAbove ? (
                <GroupSearchFormDesktop
                  {...props.model}
                  isSticky={isSticky}
                  onSubmit={handleSubmit}
                  searchResultsFormData={isSearchResultPage ? getSearchCriteria(eventType) : undefined}
                />
              ) : (
                <GroupSearchFormMobile
                  {...props.model}
                  isSticky={isSticky}
                  onSubmit={handleSubmit}
                  searchResultsFormData={isSearchResultPage ? getSearchCriteria(eventType) : undefined}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="search-form-loading my-4 skeleton-loader"></div>
        )}
      </StyledGroupSearchForm>
    </EditableComponent>
  );
};
