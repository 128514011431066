import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import { RfpProperty } from '../organisms/RfpTray/RfpCard/RfpCard.types';
import { RfpTrayView } from '../organisms/RfpTray/RfpTray.types';
import { RFP_TRAY_MAX_SIZE } from '../constants';

type RfpTrayState = {
  propertyIds: string[];
  properties: RfpProperty[];
  visibility: RfpTrayView;
  destination: string;
};

type RfpTrayActions = {
  setPropertyIds: (propertyIds: string[]) => void;
  setProperties: (properties: RfpProperty[]) => void;
  addProperty: (property: RfpProperty) => void;
  removeProperty: (id: string | number) => void;
  markAsRemovedProperty: (id: string | number) => void;
  undoMarkAsRemovedProperty: (id: string | number) => void;
  isPropertySelected: (id: string | number) => boolean;
  setVisibility: (rfpTrayView: RfpTrayView) => void;
  resetOnDestinationUpdate: (destination: string) => void;
};

const initialState: RfpTrayState = {
  propertyIds: [],
  properties: [],
  visibility: RfpTrayView.HIDDEN,
  destination: '',
};

export const RfpTrayStore: StateCreator<RfpTrayState & RfpTrayActions> = (set, get) => {
  return {
    ...initialState,

    setPropertyIds: (propertyIds: string[]) => {
      set({ propertyIds });
    },

    setProperties: (properties: RfpProperty[]) => {
      set({ properties, visibility: RfpTrayView.COLLAPSED });
    },

    addProperty: (property: RfpProperty) => {
      set(prevState => {
        const isPropertySelected = prevState.properties.find(prevProperty => prevProperty.id === property.id);
        if (prevState.properties.length < RFP_TRAY_MAX_SIZE && !isPropertySelected) {
          return {
            properties: [...prevState.properties, property],
            visibility: RfpTrayView.EXPANDED,
          };
        }
        return { properties: [...prevState.properties] };
      });
    },

    removeProperty: (id: string | number) => {
      set(({ properties }) => {
        const remainingProperties = properties.filter(property => property.id !== id);
        if (remainingProperties.length) {
          return { properties: [...remainingProperties] };
        }
        return { properties: [], visibility: RfpTrayView.HIDDEN };
      });
    },

    markAsRemovedProperty: (id: string | number) => {
      set(({ properties }) => {
        const updatedProperties = properties.map(property =>
          property.id === id ? { ...property, isMarkForRemoved: true } : property
        );
        return { properties: updatedProperties };
      });
    },

    undoMarkAsRemovedProperty: (id: string | number) => {
      set(({ properties }) => {
        const updatedProperties = properties.map(property =>
          property.id === id ? { ...property, isMarkForRemoved: false } : property
        );
        return { properties: updatedProperties };
      });
    },

    isPropertySelected: (id: string | number) => {
      const { properties } = get();
      return properties.find(property => property.id === id) ? true : false;
    },

    setVisibility: (rfpTrayView: RfpTrayView) => {
      set({ visibility: rfpTrayView });
    },

    resetOnDestinationUpdate: (updatedDestination: string) => {
      const { destination } = get();
      if (destination !== updatedDestination) {
        set({ properties: [], visibility: RfpTrayView.HIDDEN, destination: updatedDestination });
      }
    },
  };
};

export const useRfpTrayStore = createAppStore(RfpTrayStore, {
  usePersistentStore: true,
  persistentStoreName: 'rfpTrayData',
  useLocalStorage: false,
});
