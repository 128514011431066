import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { font, color } = baseVariables;

export const StyledRecentListDiv = styled.div`
  h3 {
    line-height: ${toRem(14)};
    text-transform: uppercase;
    font-weight: ${font.fontWeightMedium};
    margin-bottom: ${toRem(8)};
    margin-left: ${toRem(16)};
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: ${toRem(8)};
    .list-item {
      padding: ${toRem(8)} ${toRem(16)};
      &:hover {
        background-color: ${color.neutral20};
      }
      a {
        display: block;
        text-decoration: none;
        line-height: ${toRem(20)};
        span {
          position: relative;
        }
      }
    }
  }
`;
