import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import { Icon, Text, Types } from '@marriott/mi-ui-library';
import { StyledTextFormField } from './TextFormField.styles';
import { TextFormFieldProps } from './TextFormField.types';
import { ENTER_KEY } from '../../constants';

const { size, tags } = Types;

export const TextFormField = React.forwardRef<HTMLInputElement, TextFormFieldProps>(
  (
    {
      type = 'text',
      label,
      placeholder,
      className = 'm-input-text-field',
      value,
      maxLength,
      minLength,
      ariaLabel,
      disabled = false,
      readOnly = false,
      hideCursor = false,
      showIcon,
      iconClass,
      iconAriaLabel,
      isIconClickable = true,
      iconClickHandler,
      showErrorMessage,
      errorMessage,
      showHelperText,
      helperText,
      showBadgeContent,
      badgeContent,
      custom_click_track_value,
      testId = 'text-input',
      getInputProps,
      getLabelProps,
      onFocus,
      onBlur,
      onKeyDown,
      onChange,
      ...rest
    },
    ref
  ) => {
    const isPasswordType = type === 'password';

    const inputId = React.useId();
    const [showPasswordText, setShowPasswordText] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (maxLength && value.length > maxLength) {
        event.target.value = value.substring(0, maxLength);
      }
      onChange?.(event);
    };

    return (
      <StyledTextFormField
        className={clsx(
          className,
          custom_click_track_value && 'custom_click_track',
          showErrorMessage && 'is-error',
          disabled && 'is-disabled',
          hideCursor && 'hide-cursor'
        )}
        data-component-name="m-groups-TextFormField"
        data-testid={testId}
        data-custom_click_track_value={custom_click_track_value}
      >
        <label htmlFor={inputId} {...getLabelProps?.()}>
          {label}
        </label>
        <input
          id={inputId}
          type={isPasswordType && showPasswordText ? 'text' : type}
          role="textbox"
          placeholder={placeholder || ''}
          className={clsx(showIcon && 'pr-5')}
          value={value}
          maxLength={maxLength}
          minLength={minLength}
          aria-label={ariaLabel}
          autoComplete="off"
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
          data-testid={`${testId}-input`}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          {...getInputProps?.()}
          {...rest}
        />
        {showIcon && (
          <div
            className={`trailing-element ${iconClass}`}
            {...(isIconClickable
              ? {
                  'aria-label': iconAriaLabel,
                  role: 'button',
                  tabIndex: 0,
                  onClick: iconClickHandler,
                  onKeyDown: event => {
                    if (event.key === ENTER_KEY) {
                      event.preventDefault();
                      iconClickHandler?.();
                    }
                  },
                  onMouseDown: event => event.preventDefault(),
                }
              : {})}
            data-testid={`${testId}-icon`}
          ></div>
        )}
        {isPasswordType && (
          <div
            className="trailing-element"
            tabIndex={0}
            data-testid={`${testId}-password-icon`}
            onClick={() => setShowPasswordText(!showPasswordText)}
            onMouseDown={event => {
              event.preventDefault();
            }}
            onKeyDown={event => {
              if (event.key === ENTER_KEY) {
                event.preventDefault();
                setShowPasswordText(!showPasswordText);
              }
            }}
          >
            <Icon
              ariaLabel={iconAriaLabel}
              iconClass={`icon ${showPasswordText ? 'icon-visibility' : 'icon-visibility-off'}`}
            ></Icon>
          </div>
        )}
        {showErrorMessage && (
          <Text
            copyText={errorMessage || ''}
            customClass={`d-inline-block mt-1`}
            element={tags.span}
            fontSize={size.extraSmall}
          ></Text>
        )}
        <div className={showBadgeContent ? 'text-container' : 'text-right contain'}>
          {showBadgeContent && !showIcon && !showErrorMessage && (
            <span className="m-badge-inline-neutral trailing-element">{badgeContent}</span>
          )}
          {showHelperText && !showErrorMessage && (
            <Text
              copyText={helperText || ''}
              customClass={`text-right mt-1`}
              element={tags.span}
              fontSize={size.extraSmall}
            ></Text>
          )}
        </div>
      </StyledTextFormField>
    );
  }
);
