import { MINIMUM_GUEST_ROOMS_COUNT, NUM_UPTO_FIVE_REGEX } from '../constants';

export const isValidNumeric = (value: string) => NUM_UPTO_FIVE_REGEX.test(value);

const isEmpty = (value: string) => value.trim() === '' || +value === 0;

const isAtLeastOneNotEmpty = (value1: string, value2: string) => {
  return !isEmpty(value1) || !isEmpty(value2);
};

export const validateAttendees = (value: string, guestRoomsValue: string, requiredError: string) => {
  return !isAtLeastOneNotEmpty(value, guestRoomsValue) ? requiredError : true;
};

export const validateGuestRooms = (
  value: string,
  attendeesValue: string,
  requiredError: string,
  minCountError: string
) => {
  if (!isAtLeastOneNotEmpty(value, attendeesValue)) {
    return requiredError;
  } else if (+value < MINIMUM_GUEST_ROOMS_COUNT && isEmpty(attendeesValue)) {
    return minCountError;
  } else {
    return true;
  }
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};
