export type ContractTermsModalProps = {
  labels: ContractTerms;
  show: boolean;
  onCloseModal: () => void;
};

export type ContractTerms = {
  heading: string;
  contractHighlights: ContractDescription;
  contractDetails: ContractDescription;
  ctas: ContractTermCtas;
};

export type ContractDescription = {
  heading: string;
  typeADescription: string;
  typeBDescription: string;
  typeDDescription: string;
  typeEDescription: string;
};

type ContractTermCtas = {
  closeAriaLabel: string;
  printLabel: string;
  printAriaLabel: string;
};

export enum ContractType {
  A = 'A',
  B = 'B',
  D = 'D',
  E = 'E',
}
