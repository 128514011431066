import { Connection } from '../search/searchPropertiesByGeolocation.types';

export type ExcludedFeesAndTaxesResponse = {
  roomFees: Connection<RoomFeeDetails>;
  roomTaxes: Connection<RoomTaxDetails>;
  serviceChargeTaxDetails: {
    serviceChargeTax: ServiceChargeTax[];
  };
};

export type RoomFeeDetails = {
  feeId: string;
  feeAmount: number;
  feeAmountType: AmountType;
  otaCode: string;
  includedInRate: boolean;
  effectiveDate: string;
  dependencyAttribute: {
    positiveDependency: boolean;
  };
  chargeUnit: ChargeUnit;
  chargeFrequency: ChargeFrequency;
};

export type RoomTaxDetails = {
  taxId: string;
  taxAmount: number;
  taxAmountType: AmountType;
  otaCode: string;
  includedInRate: boolean;
  effectiveDate: string;
  chargeUnit: ChargeUnit;
  chargeFrequency: ChargeFrequency;
};

export enum AmountType {
  AMOUNT = 'AMT',
  PERCENTAGE = 'PCT',
}

type ChargeUnit = {
  description: string;
};

type ChargeFrequency = {
  description: ChargeFrequencyType | string;
};

export enum ChargeFrequencyType {
  DAILY = 'Daily',
  STAY = 'Stay',
}

type ServiceChargeTax = {
  serviceChargeTaxDescription: string;
};
