import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type AuthCookie = {
  RememberMeFlag?: string;
  UserIdToken?: string;
  sessionID: string;
};

type AuthState = {
  isAuthenticated?: boolean;
  isRemembered: boolean;
  sessionId: string;
};

type AuthActions = {
  setAuthCookie: (cookie: AuthCookie) => void;
};

const initialState: AuthState = {
  isRemembered: false,
  sessionId: '',
};

export const authStore: StateCreator<AuthState & AuthActions> = set => {
  return {
    ...initialState,

    setAuthCookie: (cookie: AuthCookie) => {
      set(prevState => {
        return {
          ...prevState,
          isAuthenticated: cookie.UserIdToken ? true : false,
          isRemembered: !cookie.UserIdToken && cookie.RememberMeFlag ? true : false,
          sessionId: cookie.sessionID || '',
        };
      });
    },
  };
};

export const useAuthStore = createAppStore(authStore);
