import React, { FC } from 'react';
import { BrandLogoProps } from './BrandLogo.types';
import { StyledBrandLogo } from './BrandLogo.styles';

export const BrandLogo: FC<BrandLogoProps> = ({
  variation = 'small',
  brandCode,
  customClass,
  ariaLabel,
  tabIndex = -1,
}) => {
  return (
    <StyledBrandLogo
      role="img"
      className={`brand-logo-${variation} brand-logo-${brandCode} ${customClass || 'brand-logo-default'}`}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
    ></StyledBrandLogo>
  );
};
