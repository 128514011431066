import React, { forwardRef, ForwardedRef, useEffect } from 'react';
import clsx from 'clsx';
import { Types, Text } from '@marriott/mi-ui-library';
import { ENTER_KEY } from '../../constants';
import { CheckBoxFormFieldProps } from './CheckBoxFormField.types';
import { StyledCheckBoxFormField } from './CheckBoxFormField.styles';

const { tags, size } = Types;

export const CheckBoxFormField = forwardRef<HTMLInputElement, CheckBoxFormFieldProps>(
  (
    {
      options,
      name,
      ariaLabel,
      className,
      labelClassName,
      showErrorMessage,
      errorMessage,
      testId,
      custom_click_track_value = '',
      onChange,
      colLength = 6,
      value: initialValue,
      ...props
    },
    forwardRef?: ForwardedRef<HTMLInputElement>
  ) => {
    const [currentValue, setCurrentValue] = React.useState(initialValue);

    useEffect(() => {
      if (JSON.stringify(initialValue) !== JSON.stringify(currentValue)) {
        setCurrentValue(initialValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const updateValue = (value: string | number) => {
      let updatedValue;
      if (Array.isArray(currentValue)) {
        updatedValue = currentValue.includes(value)
          ? currentValue.filter((v: string | number) => v !== value)
          : [...currentValue, value];
      } else {
        updatedValue = currentValue === value ? '' : value;
      }
      setCurrentValue(updatedValue);
      onChange(updatedValue);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      updateValue(value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === ENTER_KEY) {
        event.preventDefault();
        const value = event.currentTarget.value;
        updateValue(value);
      }
    };

    const id = name.replace(/ /g, ''); //remove spaces from name

    return (
      <StyledCheckBoxFormField
        className={clsx(className, custom_click_track_value && 'custom_click_track', showErrorMessage && 'is-error')}
        aria-label={ariaLabel}
        data-testid={testId}
        data-component-name="m-groups-CheckBoxFormField"
        data-custom_click_track_value={custom_click_track_value}
      >
        <div className="row">
          {options.map((option, index) => (
            <div key={`${id}_${index}`} className={`mt-2 mb-2 col-${colLength}`}>
              <input
                type="checkbox"
                id={`${id}_${index}`}
                name={name}
                value={option?.value}
                checked={
                  Array.isArray(currentValue) ? currentValue.includes(option?.value) : currentValue === option?.value
                }
                disabled={option?.disabled}
                ref={forwardRef}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                {...props}
              />
              <label htmlFor={`${id}_${index}`} className={clsx('mr-3', labelClassName)}>
                {option?.label}
              </label>
            </div>
          ))}
        </div>
        {showErrorMessage && (
          <Text
            fontSize={size.extraSmall}
            copyText={errorMessage || ''}
            element={tags.span}
            customClass={'error-message'}
          ></Text>
        )}
      </StyledCheckBoxFormField>
    );
  }
);
