import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// this lint disable will be removed once logger moved to mi-ui-library
// eslint-disable-next-line @nx/enforce-module-boundaries
import { logger } from '@marriott/mi-headless-utils';

type ErrorFallbackProps = {
  error: { message: string };
  resetErrorBoundary: () => void;
};

export const ErrorHandlerAlert: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: JSX.Element | any;
}> = ({ children }) => {
  const { log } = logger({})('GroupsError');

  function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
    // this is temporary fallback UI. need to import the page once created
    return (
      <div role="alert">
        <span>Something went wrong: {error.message}</span>
        <div>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      </div>
    );
  }

  const errorHandler = (error: Error, info: { componentStack: string }) => {
    // handle error here
    log.error(`Component error: ${error}`);
    log.debug(`Component error stack trace:  ${info.componentStack}`);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      {children}
    </ErrorBoundary>
  );
};
