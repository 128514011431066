import { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button, Icon, baseVariables } from '@marriott/mi-ui-library';
import { ContactNumberListProps } from './ContactNumberList.types';
import { StyledContactNumberList } from './ContactNumberList.styles';
import { useFocusOut, useMediaQuery } from '../../hooks';
import { ENTER_KEY, MARRIOTT_DOMAIN } from '../../constants';

export const ContactNumberList: FC<ContactNumberListProps> = ({ contactNumbers, tollFreeCta }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const contactNumberListRef = useRef<HTMLDivElement>(null);

  useFocusOut({
    ref: contactNumberListRef.current,
    onFocusOut: (isFocusOut: boolean) => {
      if (isFocusOut) {
        setToggleDropdown(false);
      }
    },
    handleKeyup: true,
  });

  return (
    <StyledContactNumberList
      data-component-name="m-groups-ContactNumberList"
      data-testid="groups-ContactNumberList"
      ref={contactNumberListRef}
    >
      <div className="p-0 mt-1 contact-info">
        <div
          id="contact-number"
          role="button"
          className="d-flex flex-row align-items-center contact-number-container"
          tabIndex={0}
          aria-label={contactNumbers[0]?.ariaLabel}
          aria-expanded={toggleDropdown}
          onClick={() => setToggleDropdown(!toggleDropdown)}
          onKeyDown={event => event.key === ENTER_KEY && setToggleDropdown(!toggleDropdown)}
        >
          <Icon iconClass={`icon-phone icon-s mt-1`}></Icon>
          <p className="m-0 contact-number">
            {isTabletAndAbove ? contactNumbers[0]?.number : contactNumbers[0]?.label?.replace(':', '')}
          </p>
          <Icon iconClass={clsx(toggleDropdown ? 'icon-arrow-up' : 'icon-arrow-down', 'icon-s mt-1 ml-1')}></Icon>
        </div>
        <ul
          role="menu"
          className={toggleDropdown ? 'p-2 m-dropdown-container contact-list' : 'p-0'}
          aria-labelledby="contact-number"
        >
          {contactNumbers.map(contact => {
            return (
              <li
                role="menuitem"
                key={contact.label}
                className="d-flex flex-row justify-content-between align-items-center contact-option"
              >
                <p className="t-font-m m-0">{contact.label}</p>
                <Button
                  ariaLabel={`${contact.ariaLabel} ${contact.number}`}
                  className="t-font-m m-0"
                  isLink={true}
                  href={`tel:${contact.number}`}
                >
                  {contact.number}
                </Button>
              </li>
            );
          })}
          {tollFreeCta && (
            <li role="menuitem" className="d-flex flex-row justify-content-between align-items-center contact-option">
              <Button
                ariaLabel={tollFreeCta.tollFreeAriaLabel}
                className="t-font-s m-0 toll-free-cta"
                isLink={true}
                href={`${MARRIOTT_DOMAIN}${tollFreeCta.tollFreeUrl}`}
                target={'_blank'}
              >
                {tollFreeCta.tollFreeLabel}
              </Button>
            </li>
          )}
        </ul>
      </div>
    </StyledContactNumberList>
  );
};
