import { FC, SyntheticEvent } from 'react';
import { StyledCurrentLocation } from './CurrentLocation.styles';
import type { CurrentLocationProps } from './CurrentLocation.types';
import { getCurrentLocation } from '../../../../../utils';

export const CurrentLocation: FC<CurrentLocationProps> = ({ handleSelection, label }) => {
  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    // callback function to receive the current location and pass it to destination updater
    const processLocation = (data: Record<string, string>) => {
      const currentLocationInfo = data;
      handleSelection(currentLocationInfo['address']);
    };
    // call geolocation api to get the current location of the user
    getCurrentLocation(processLocation);
  };

  return (
    <StyledCurrentLocation>
      <div className="t-label-alt-s">
        <a href="#" onTouchStart={handleClick} onClick={handleClick} className="t-label-alt-s p-0 m-0">
          <span>{label}</span>
        </a>
      </div>
    </StyledCurrentLocation>
  );
};
