import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Types, RadiobuttonControlsList, getNextDateObject } from '@marriott/mi-ui-library';
import { GroupSearchFormMobileContainerProps, SelectedDateType } from './GroupSearchFormMobileContainer.types';
import {
  StyledGroupSearchFormMobileContainer,
  StyledGroupSearchFormMobileContainerHeader,
  StyledGroupSearchFormMobileContainerFooter,
  StyledGroupSearchFormMobileContainerBody,
  StyledGroupSearchFormMobileContainerEventType,
  GlobalStyles,
} from './GroupSearchFormMobileContainer.styles';
import { SearchFormField } from '../GroupSearchFormMobile.types';
import { GroupSearchExpandedFormMobile } from '../GroupSearchExpandedFormMobile';
import { GroupSearchFormData } from '../../GroupSearchForm.types';
import { useLocaleStore, useSearchFromStore } from '../../../../store';
import { TextFormField, DatePickerMobile } from '../../../../molecules';
import {
  getFormattedDate,
  getFormattedDateObject,
  getFormattedDates,
  getFormattedDatesObject,
} from '../../../../utils';
import { CALENDAR_END_DATE, CALENDAR_START_DATE } from '../../../../constants';
import { Destination } from '../../Destination';

export const GroupSearchFormMobileContainer: FC<GroupSearchFormMobileContainerProps> = ({
  labels,
  onCancel,
  onSubmit,
  onSubmitSearch,
  isSearchResultPage,
  searchResultsFormData,
}) => {
  const { destination, dates, eventType, ctas } = labels;
  const { cancel, done } = ctas;

  const defaultEventType = eventType.options.filter(option => option.selected === true);

  const {
    currentStep,
    formData,
    persistedFormData,
    setCurrentStep,
    setFormData,
    setPersistedFormData,
    resetFormData,
    resetPersistedFormData,
  } = useSearchFromStore();
  const { locale } = useLocaleStore();

  const [visitedSteps, setVisitedSteps] = useState<SearchFormField[]>([]);
  const [destinationOnFocus, setDestinationOnFocus] = useState(false);
  const [selectedDateType, setSelectedDateType] = useState<SelectedDateType | undefined>(CALENDAR_START_DATE);

  useEffect(() => {
    setVisitedSteps(visitedSteps => [...visitedSteps, currentStep]);
  }, [currentStep]);

  useEffect(() => {
    if (isSearchResultPage && searchResultsFormData) {
      const { startDate, endDate, dates } = getFormattedDatesObject(
        searchResultsFormData?.startDate,
        searchResultsFormData?.endDate
      );

      const updatedFormData = {
        ...searchResultsFormData,
        destination: persistedFormData.destination ?? searchResultsFormData.destination,
        dates: persistedFormData.dates || dates,
        startDate: persistedFormData.startDate || startDate,
        endDate: persistedFormData.endDate || endDate,
        eventType: persistedFormData.eventType.code ? persistedFormData.eventType : searchResultsFormData.eventType,
        guestRooms: persistedFormData.guestRooms || searchResultsFormData.guestRooms,
        attendees: persistedFormData.attendees || searchResultsFormData.attendees,
      };

      setFormData(updatedFormData);
      setPersistedFormData(updatedFormData);
      setCurrentStep(SearchFormField.SEARCH_FORM_FULL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = {
    'accept-language': locale,
  };

  const updateEndDate = () => {
    const { startDate, endDate } = formData;

    if (startDate && !endDate) {
      const formatedStartDate = getFormattedDateObject(startDate, 'dateWithDayMonthAndYear');
      const newEndDate = getNextDateObject(formatedStartDate);
      const formattedDates = getFormattedDates(formatedStartDate, newEndDate);
      const formattedEndDate = getFormattedDate(newEndDate, 'dateWithDayMonthAndYear');

      const updatedFormData = {
        ...formData,
        dates: formattedDates,
        endDate: formattedEndDate,
      };

      updateFormData(updatedFormData);
      setPersistedFormData(updatedFormData);
    }

    setSelectedDateType(CALENDAR_END_DATE);
  };

  const goToNextStep = (currentStep: SearchFormField) => {
    if (
      visitedSteps.includes(SearchFormField.SEARCH_FORM_FULL) ||
      (visitedSteps.includes(SearchFormField.DATES) && visitedSteps.includes(SearchFormField.DESTINATION))
    ) {
      setCurrentStep(SearchFormField.SEARCH_FORM_FULL);
    } else {
      if (currentStep === SearchFormField.DESTINATION && !visitedSteps.includes(SearchFormField.DATES)) {
        setCurrentStep(SearchFormField.DATES);
      } else if (currentStep === SearchFormField.DATES && !visitedSteps.includes(SearchFormField.DESTINATION)) {
        setCurrentStep(SearchFormField.DESTINATION);
      }
    }
  };

  const handleDoneClick = (value?: Partial<GroupSearchFormData>) => {
    if (value) {
      setPersistedFormData({
        ...persistedFormData,
        ...value,
      });
    } else {
      setPersistedFormData(formData);
    }

    currentStep === SearchFormField.DATES && updateEndDate();
    onSubmit?.();
    goToNextStep(currentStep);
  };

  const reset = () => {
    setCurrentStep(SearchFormField.INITIAL);
    setVisitedSteps([]);
    resetFormData();
    setSelectedDateType(CALENDAR_START_DATE);
    if (!isSearchResultPage) {
      resetPersistedFormData();
    }
  };

  const handleCancelClick = () => {
    if (visitedSteps.includes(SearchFormField.SEARCH_FORM_FULL)) {
      setCurrentStep(SearchFormField.SEARCH_FORM_FULL);
      setFormData(persistedFormData);
    } else {
      reset();
    }
    setDestinationOnFocus(false);
    onCancel?.();
  };

  useEffect(() => {
    updateFormData({ destination: persistedFormData.destination });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedFormData.destination]);

  // it will be used as a callback on child component
  const updateFormData = (value: Partial<GroupSearchFormData>) => {
    setFormData(value);
  };

  const updateDestination = (value: Partial<GroupSearchFormData>) => {
    setDestinationOnFocus(false);
    updateFormData(value);
    setPersistedFormData({
      ...persistedFormData,
      ...value,
    });
    setTimeout(() => {
      goToNextStep(currentStep);
    }, 100);
  };

  const destinationField = (
    <TextFormField
      className="text-field"
      label={destination.label}
      placeholder={destination.mobilePlaceholder}
      ariaLabel={destination.ariaLabel}
      onChange={e => updateFormData({ destination: { description: e.target.value, placeId: '' } })}
      value={formData.destination.description}
      showIcon={destinationOnFocus && !!formData.destination.description}
      iconClass={'icon icon-s icon-cancel icon-inverse'}
      iconAriaLabel={`${ctas.clearLabel} ${destination.label}`}
      iconClickHandler={() => updateFormData({ destination: { description: '' } })}
      getInputProps={() => ({
        onFocus: () => setDestinationOnFocus(true),
        onBlur: () => {
          setTimeout(() => {
            setDestinationOnFocus(false);
          }, 0);
        },
      })}
    />
  );

  const destinationChildren = (
    <Destination
      currentValue={formData.destination.description}
      labels={labels.destination}
      customHeaders={headers}
      updateFormData={updateDestination}
      isInputFocused={destinationOnFocus}
    />
  );

  const datesField = (
    <TextFormField
      className="text-field"
      label={dates['label']}
      value={formData.dates}
      getInputProps={() => ({
        readOnly: true,
      })}
      ariaLabel={dates['ariaLabel']}
      testId="dates"
    />
  );

  const datesChildren = (
    <DatePickerMobile
      dates={dates}
      onChange={updateFormData}
      onFlexibleDateChange={updateFormData}
      startDate={formData.startDate ? getFormattedDateObject(formData.startDate, 'dateWithDayMonthAndYear') : null}
      endDate={formData.endDate ? getFormattedDateObject(formData.endDate, 'dateWithDayMonthAndYear') : null}
      flexibleDates={formData.flexibleDates}
      selectedDateType={selectedDateType}
    />
  );

  const eventTypeField = (
    <TextFormField
      className="text-field"
      label={eventType.label}
      placeholder={eventType.selectEventType}
      ariaLabel={eventType.ariaLabel}
      value={formData.eventType?.label}
      getInputProps={() => ({
        readOnly: true,
      })}
    />
  );

  const eventTypeChildren = (
    <StyledGroupSearchFormMobileContainerEventType>
      <RadiobuttonControlsList
        name="Event Type"
        controls={eventType.options}
        defaultSelected={[{ label: formData.eventType?.label }] || [{ label: defaultEventType[0]?.label }]}
        colLength={10}
        tabIndexForInput={-1}
        onChange={option => {
          const selectedEventType = { eventType: { label: option[0].label, code: option[0].code || '' } };
          updateFormData(selectedEventType);
          handleDoneClick(selectedEventType);
        }}
      />
    </StyledGroupSearchFormMobileContainerEventType>
  );

  const getTextField = () => {
    switch (currentStep) {
      case SearchFormField.DESTINATION:
        return destinationField;
      case SearchFormField.DATES:
        return datesField;
      case SearchFormField.EVENT_TYPE:
        return eventTypeField;
      default:
        return <div />;
    }
  };

  const getChildren = () => {
    switch (currentStep) {
      case SearchFormField.DESTINATION:
        return destinationChildren;
      case SearchFormField.DATES:
        return datesChildren;
      case SearchFormField.EVENT_TYPE:
        return eventTypeChildren;
      case SearchFormField.SEARCH_FORM_FULL:
        return <GroupSearchExpandedFormMobile expandedFormLabels={labels} onBack={reset} onSubmit={onSubmitSearch} />;
      default:
        return <div />;
    }
  };

  return (
    <StyledGroupSearchFormMobileContainer
      className={clsx(
        'mobile-container',
        currentStep === SearchFormField.SEARCH_FORM_FULL && 'expanded-form',
        currentStep === SearchFormField.DATES && 'show-footer'
      )}
    >
      <GlobalStyles />
      {currentStep === SearchFormField.SEARCH_FORM_FULL ? null : (
        <StyledGroupSearchFormMobileContainerHeader>
          <div className="subheader">
            <div className="w-100">{getTextField()}</div>
            <div>
              <Button
                type={Types.ButtonTypeVariation.Button}
                className="m-button-primary pr-0 cancel-cta"
                callback={handleCancelClick}
              >
                <span className="t-label-inverse-s"> {cancel}</span>
              </Button>
            </div>
          </div>
        </StyledGroupSearchFormMobileContainerHeader>
      )}
      <StyledGroupSearchFormMobileContainerBody
        className={clsx(currentStep === SearchFormField.SEARCH_FORM_FULL && 'expanded-form')}
      >
        {getChildren()}
      </StyledGroupSearchFormMobileContainerBody>
      {currentStep === SearchFormField.DATES ? (
        <StyledGroupSearchFormMobileContainerFooter>
          <Button
            type={Types.ButtonTypeVariation.Button}
            className="m-button-primary w-100"
            callback={() => handleDoneClick()}
          >
            {done}
          </Button>
        </StyledGroupSearchFormMobileContainerFooter>
      ) : null}
    </StyledGroupSearchFormMobileContainer>
  );
};
