import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { LazyQueryHookOptions, useLazyQuery, useMutation } from '@apollo/client';
import { Link, baseVariables, Image } from '@marriott/mi-ui-library';
import {
  GetCustomerSavedPropertiesResponse,
  PropertyDetailsForHeaderResponse,
  UpdateCustomerSavedPropertiesResponse,
  getPropertyDetailsForHeaderQuery,
  getCustomerSavedPropertiesQuery,
  updateCustomerSavedPropertiesMutation,
  UpdateCustomerSavedPropertiesInput,
} from '@marriott/mi-groups-graphql';
import { StyledPropertyHeader } from './PropertyHeader.styles';
import { PropertyHeaderLabels } from './PropertyHeader.types';
import { useAuthStore, useLocaleStore, useQuickGroupStore } from '../../store';
import { quickGroupDataStorage } from '../../utils/search';
import {
  getOrderedContactNumbers,
  getSession,
  getAddress,
  getHotelOverviewUrl,
  getPropertyItemValue,
  getReviewsUrl,
  updateDataLayerWithQuickGroupAttributes,
} from '../../utils';
import { useMediaQuery, CustomerSessionData } from '../../hooks';
import { BrandLogo } from '../../admin';
import { ContactNumberList } from '../../molecules';
import { ASSET_DOMAIN } from '../../constants';

export const PropertyHeaderConfig = {
  emptyLabel: 'PropertyHeader',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/quickgroup/propertycardheader`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PropertyHeader: FC<{ model: PropertyHeaderLabels }> = (props: any) => {
  const { address: addressLabel, review, contactInfo, save, saved, appliedCssClassNames } = props.model;

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const { locale } = useLocaleStore();
  const { isAuthenticated } = useAuthStore();
  const { contractData } = useQuickGroupStore();

  const [isLoading, setIsLoading] = useState(true);
  const [customerId, setCustomerId] = useState('');
  const [customerSavedProperties, setCustomerSavedProperties] = useState<GetCustomerSavedPropertiesResponse>();
  const [isPropertySaved, setIsPropertySaved] = useState(false);

  const [getPropertyDetailsForHeader, { data: propertyDetailsData, loading }] = useLazyQuery<{
    property: PropertyDetailsForHeaderResponse;
  }>(getPropertyDetailsForHeaderQuery);

  const [
    getCustomerSavedProperties,
    {
      loading: getCustomerSavedPropertiesLoading,
      data: customerSavedPropertiesData,
      refetch: refetchCustomerSavedProperties,
    },
  ] = useLazyQuery<{
    customer: GetCustomerSavedPropertiesResponse;
  }>(getCustomerSavedPropertiesQuery);

  const [updateCustomerSavedProperties, { loading: updateCustomerSavedPropertiesLoading }] = useMutation<{
    updateCustomerSavedProperties: UpdateCustomerSavedPropertiesResponse;
  }>(updateCustomerSavedPropertiesMutation);

  useEffect(() => {
    getPropertyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getCustomerDetails = async () => {
      const { sessionData } = (await getSession()) as CustomerSessionData;
      const { consumerID: customerId } = sessionData?.cacheData?.data || {};
      if (customerId) {
        setCustomerId(customerId);
        getCustomerSavedProperties({
          variables: {
            customerId,
          },
          context: {
            headers: {
              'accept-language': locale,
            },
          },
          fetchPolicy: 'network-only',
        });
      }
    };

    isAuthenticated && getCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (customerSavedPropertiesData) {
      const { customer } = customerSavedPropertiesData;
      const savedProperties = customer.savedProperties.map(property => ({ id: property.id }));
      setCustomerSavedProperties({ revisionToken: customer.revisionToken, savedProperties });

      const { propertyId } = quickGroupDataStorage.getItem();
      setIsPropertySaved(!!customer.savedProperties.find(property => property.id === propertyId));
    }
  }, [customerSavedPropertiesData]);

  const getPropertyDetails = () => {
    const { propertyId } = quickGroupDataStorage.getItem();
    const uxlOptions: LazyQueryHookOptions = {
      variables: {
        propertyId,
      },
      context: {
        headers: {
          'accept-language': locale,
        },
      },
      fetchPolicy: 'network-only',
    };
    getPropertyDetailsForHeader(uxlOptions);
  };

  const { seoNickname, basicInformation, contactInformation, reviews, media } = propertyDetailsData?.property || {};
  const { brand, name } = basicInformation || {};
  const { address } = contactInformation || {};
  const hotelLogo = media?.hotelLogo?.edges[0].node.imageUrls.classicHorizontal;

  useEffect(() => {
    if (basicInformation?.brand?.id && address) {
      const { propertyId, eventType, startDate, endDate, numberOfRooms, numberOfAttendees } =
        quickGroupDataStorage.getItem();
      const quickGroupOption = {
        propertyId,
        eventType,
        startDate,
        endDate,
        numberOfRooms,
        numberOfAttendees,
      };
      updateDataLayerWithQuickGroupAttributes(quickGroupOption, basicInformation, address, contractData.contractType);
    }
  }, [basicInformation, address, contractData.contractType]);

  const propertyAddress = getAddress(
    address?.line1 ?? '',
    address?.city ?? '',
    address?.stateProvince?.description ?? '',
    address?.country?.description ?? '',
    address?.postalCode ?? ''
  );

  const orderedContactNumbers = contactInformation?.contactNumbers?.length
    ? getOrderedContactNumbers([...contactInformation.contactNumbers], contactInfo)
    : [];

  const reviewLabel = reviews?.numberOfReviews?.count
    ? `(${getPropertyItemValue(reviews.numberOfReviews.count).trim()}${isTabletAndAbove ? ` ${review.label}` : ''})`
    : '';
  const reviewUrl = getReviewsUrl(seoNickname as string, brand?.id);

  const togglePropertySave = () => {
    if (customerSavedProperties) {
      const { propertyId } = quickGroupDataStorage.getItem();
      const { revisionToken, savedProperties } = customerSavedProperties;
      const updatedSavedProperties = isPropertySaved
        ? savedProperties.filter(property => property.id !== propertyId)
        : [...savedProperties, { id: propertyId }];

      const updateCustomerSavedPropertiesInput: UpdateCustomerSavedPropertiesInput = {
        id: customerId,
        revisionToken,
        savedProperties: updatedSavedProperties,
      };

      updateCustomerSavedProperties({
        variables: {
          input: updateCustomerSavedPropertiesInput,
        },
        context: {
          headers: {
            'accept-language': locale,
          },
        },
        fetchPolicy: 'network-only',
        onCompleted: ({ updateCustomerSavedProperties: data }) => {
          if (data.status[0].httpStatus === 200) {
            refetchCustomerSavedProperties();
          }
        },
      });
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <EditableComponent config={PropertyHeaderConfig} {...props}>
      <StyledPropertyHeader
        className="brand-css"
        data-component-name="o-groups-propertycardheader"
        data-testid="propertycardheader"
      >
        <div className={clsx('m-container-fullbleed t-background-color', appliedCssClassNames)}>
          {isLoading ? (
            <div className="skeleton-loader loader"></div>
          ) : propertyDetailsData?.property ? (
            <div className="container">
              <div className="d-flex property-header">
                {isTabletAndAbove && brand?.id && (
                  <Link
                    linkClassName="d-flex align-items-center brand-logo"
                    linkHref={getHotelOverviewUrl(seoNickname as string)}
                  >
                    {hotelLogo ? (
                      <Image
                        defaultImageURL={`${ASSET_DOMAIN}${hotelLogo}`}
                        altText={name}
                        customClass="property-logo"
                      />
                    ) : (
                      <BrandLogo variation={'large'} brandCode={brand.id} ariaLabel={name} />
                    )}
                  </Link>
                )}
                <div>
                  <Link
                    linkClassName={clsx(isTabletAndAbove ? 't-subtitle-xl' : 't-subtitle-l')}
                    linkHref={getHotelOverviewUrl(seoNickname as string)}
                  >
                    {name}
                  </Link>
                  <div className="d-flex flex-wrap property-details">
                    {address && (
                      <Link linkClassName="m-icon-text" linkHref={getHotelOverviewUrl(seoNickname as string, true)}>
                        <span className="icon-s icon-location"></span>
                        <span className={clsx(isTabletAndAbove ? 't-font-xs' : 't-font-s')}>
                          {isTabletAndAbove ? propertyAddress : addressLabel}
                        </span>
                      </Link>
                    )}

                    {orderedContactNumbers.length && (
                      <ContactNumberList
                        contactNumbers={orderedContactNumbers}
                        tollFreeCta={{
                          tollFreeAriaLabel: contactInfo.tollFreeAriaLabel,
                          tollFreeLabel: contactInfo.tollFreeLabel,
                          tollFreeUrl: contactInfo.tollFreeUrl,
                        }}
                      />
                    )}

                    {reviewLabel && (
                      <Link linkClassName="d-flex" linkHref={reviewUrl}>
                        <div className="m-ratings-md align-items-center">
                          <div className="m-ratings-stars mr-1">
                            <span className="icon-star-fill" />
                          </div>
                          <span className={clsx(isTabletAndAbove ? 't-font-xs' : 't-font-s')}>
                            {reviews?.stars?.count} {reviewLabel}
                          </span>
                        </div>
                      </Link>
                    )}

                    {isAuthenticated && (
                      <div
                        role="button"
                        className="m-icon-text"
                        tabIndex={0}
                        onClick={() =>
                          customerId &&
                          !getCustomerSavedPropertiesLoading &&
                          !updateCustomerSavedPropertiesLoading &&
                          togglePropertySave()
                        }
                      >
                        {getCustomerSavedPropertiesLoading ? (
                          <div className="m-spinner-s"></div>
                        ) : (
                          <>
                            <span
                              className={clsx('icon-s', isPropertySaved ? 'icon-heart-fill' : 'icon-heart-outline')}
                            ></span>
                            <span className={clsx(isTabletAndAbove ? 't-font-xs' : 't-font-s')}>
                              {isPropertySaved ? saved : save}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </StyledPropertyHeader>
    </EditableComponent>
  );
};
