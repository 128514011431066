import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, shadows, border, mediaQuery } = baseVariables;

const ScrollbarStyles = `
  ::-webkit-scrollbar {
    width: ${toRem(8)};
  }
  ::-webkit-scrollbar-track {
    background: ${color.base20};
    border-radius: ${toRem(4)};
    margin: ${toRem(50)} 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${color.neutral30} !important;
    background-clip: unset;
    height: ${toRem(183)};
    border-radius: ${toRem(4)};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${color.neutral30} !important;
  }
`;

export const StyledDrawerContainer = styled.div`
  &.drawer-container {
    background-color: transparent;
    position: absolute;
    bottom: ${toRem(50)};
    left: 0;
    right: 0;
    z-index: 9;
  }

  &.drawer {
    box-sizing: border-box;
    border: ${border.borderWidth01} ${border.borderStyleDefault} ${color.neutral20};
    background-color: ${color.base20};
    box-shadow: ${shadows.shadowBlur02};
    z-index: 9;
  }

  &.drawer-placement-left {
    border-radius: 0 ${toRem(12)} ${toRem(12)} 0;
    position: absolute;
    height: 100%;

    &.open {
      position: sticky;
      left: 0;
      padding-right: ${toRem(6)};
    }
  }

  &.drawer-placement-bottom {
    border-radius: ${toRem(12)} ${toRem(12)} 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const StyledDrawer = styled.div`
  position: relative;
`;

export const StyledDrawerContent = styled.div<{ height: number }>`
  height: ${props => toRem(props.height)};
  overflow-x: hidden;
  overflow-y: auto;
  ${ScrollbarStyles}
`;

export const StyledDrawerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.base20};
  font-size: ${toRem(20)};
  box-shadow: ${shadows.shadow03};
  position: absolute;

  &:focus {
    outline: ${toRem(5)} auto -webkit-focus-ring-color;
  }

  &.drawer-icon-container {
    color: ${color.neutral00};
    .icon-arrow-left,
    .icon-arrow-right {
      position: absolute;
      z-index: 1;
    }

    &.button-placement-right {
      width: ${toRem(40)};
      height: ${toRem(36)};
      border-radius: ${toRem(30)} ${toRem(30)} 0 0;
      top: ${toRem(-36)};
      right: ${toRem(50)};
      @media ${mediaQuery.md} {
        right: unset;
      }

      .icon-arrow-left,
      .icon-arrow-right {
        &.first {
          top: ${toRem(2)};
          rotate: 270deg;
        }

        &.second {
          top: ${toRem(8)};
          rotate: 270deg;
        }
      }
    }

    &.button-placement-top {
      width: ${toRem(36)};
      height: ${toRem(40)};
      border-radius: 0 ${toRem(30)} ${toRem(30)} 0;
      right: ${toRem(-36)};
      top: ${toRem(184)};

      .icon-arrow-left,
      .icon-arrow-right {
        &.second {
          left: 0;
        }
      }
    }
  }
`;
