import { FC, useEffect, ReactNode } from 'react';

export const CustomDomContentLoaded: FC<{
  children: ReactNode;
}> = ({ children }) => {
  // The dispatch event in the useEffect hook triggers the reinitialization of the common thin header component.
  // This is necessary for maintaining the user's login state during Single Page Application (SPA) transitions from one page to another.
  // The common thin header component, as currently implemented, is not fully compatible with SPAs.
  // Therefore, this reinitialization ensures that the header correctly reflects the user's login state during SPA navigation.

  useEffect(() => {
    document.dispatchEvent(new Event('DOMContentLoaded'));

    return () => {
      document.body.classList.remove('fixed-header');
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
