import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { mediaQuery } = baseVariables;

export const StyledMapView = styled.section`
  position: relative;
  .card-panel {
    width: ${toRem(400)};
  }
  .map-view {
    width: calc(100% - ${toRem(400)});
  }
  .map-view-loader {
    position: absolute;
    height: 100%;
    margin: 0 auto;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    top: 0;
    right: 0;
    .loader {
      height: 100%;
    }
    @media ${mediaQuery.lg} {
      width: calc(100% - ${toRem(384)});
    }
  }
`;
