import React, { FC } from 'react';
import clsx from 'clsx';
import DOMPurify from 'isomorphic-dompurify';
import { Button, Heading, Types, RichText, baseVariables } from '@marriott/mi-ui-library';
import { ImageCarousel } from '../../../molecules';
import { useMediaQuery } from '../../../hooks';
import { isValidUrl } from '../../../utils';
import { EventBannerProps } from './EventBanner.types';
import { StyledEventBanner } from './EventBanner.styles';

export const EventBanner: FC<EventBannerProps> = ({
  eventTitle,
  eventDescription,
  customLinks,
  externalLinkAriaLabel,
  media,
}) => {
  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);
  const sanitizedNode = DOMPurify.sanitize(eventDescription, { RETURN_DOM: true });

  const fragmentString = sanitizedNode.innerHTML; // Get the HTML content of the container

  return (
    <StyledEventBanner className={clsx(media && 'with-media')}>
      {media ? (
        <div className="media-container">
          <ImageCarousel media={media} isCarouselIndicatorCentered containerClass="fullbleed-carousel" />
        </div>
      ) : null}
      <div className={'event-banner'} data-testid="event-banner">
        {eventTitle ? (
          <Heading
            variation={Types.headingType.title}
            fontSize={Types.size.large}
            titleText={eventTitle}
            customClass={clsx('event-title')}
          />
        ) : null}
        {eventDescription ? (
          <RichText
            componentId={'eventDescription'}
            text={fragmentString}
            customClass="custom-scrollbar event-description"
          />
        ) : null}
        {customLinks ? (
          <div className="custom-links">
            {customLinks.map((cta, index) => (
              <Button
                key={index}
                buttonCopy={cta.name}
                isLink
                className={clsx('m-button-external py-0', isDesktop && 'm-button-l')}
                href={isValidUrl(cta.link) ? cta.link : `//${cta.link}`}
                target={'_blank'}
                linkType={'external'}
                rel={'noopener noreferrer'}
                linkAriaLabelOpenNewWindow={externalLinkAriaLabel}
              />
            ))}
          </div>
        ) : null}
      </div>
    </StyledEventBanner>
  );
};
