import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, mediaQuery } = baseVariables;

export const StyledEventBanner = styled.div`
  .event-banner {
    padding: ${toRem(40)} ${toRem(32)};
    text-align: center;
    .event-title {
      margin: 0 auto;
      font-size: ${toRem(30)};
      line-height: ${toRem(36)};
      word-break: break-word;
    }
    .event-description {
      font-size: ${toRem(14)};
      color: ${color.neutral40};
      margin: ${toRem(16)} auto 0 auto;
      padding-bottom: ${toRem(8)};
      word-wrap: break-word;
    }
    .custom-links {
      margin: ${toRem(16)} auto 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-wrap: anywhere;
        &:nth-child(2) {
          margin-top: ${toRem(24)};
        }
      }
    }
  }

  &.with-media {
    .media-container {
      width: 100%;
      .fullbleed-carousel {
        .glide__slides {
          background-color: ${color.neutral10};
          .glide__slide {
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            .carousel-image {
              height: auto;
              max-height: ${toRem(536)};
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  @media ${mediaQuery.md} {
    .event-banner {
      .event-title {
        max-width: ${toRem(460)};
      }
      .event-description {
        max-width: ${toRem(584)};
        p {
          margin-bottom: ${toRem(8)};
        }
        &.custom-scrollbar {
          ::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            border: ${toRem(4)} solid rgba(0, 0, 0, 0);
            border-radius: ${toRem(10)};
            background-color: rgb(187 182 182);
          }
          ::-webkit-scrollbar {
            width: ${toRem(14)};
          }
          ::-webkit-scrollbar-track {
            background-color: transparent;
          }
        }
      }
      .custom-links {
        flex-direction: row;
        max-width: ${toRem(460)};
        gap: ${toRem(16)};
        a {
          flex: 1;
          height: auto;
          min-height: ${toRem(50)};
          max-width: 50%;
          padding: 0 ${toRem(24)};
          &:nth-child(2) {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media ${mediaQuery.lg} {
    &.with-media {
      display: grid;
      align-items: center;
      grid-template-columns: 55% 45%;
      .fullbleed-carousel {
        .glide__slides {
          height: ${toRem(536)};
          .glide__slide {
            height: auto;
          }
        }
      }
      .event-banner {
        padding: 0 ${toRem(40)};
        .custom-links {
          padding: 0;
        }
      }
    }
    &:not(.with-media) {
      text-align: center;
      .event-banner {
        max-width: ${toRem(744)};
        margin: auto;
      }
    }
    .event-description {
      max-height: ${toRem(170)};
      overflow-y: auto;
    }
  }

  @media ${mediaQuery.xl} {
    .event-description {
      max-height: ${toRem(250)};
    }

    &.with-media {
      .media-container {
        .fullbleed-carousel {
          .glide__slides {
            .glide__slide {
              .carousel-image {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
`;
