import { FC, useState, useRef, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  baseVariables,
  getCurrentDateObject,
  getNextDateObject,
  getPrevDateObject,
} from '@marriott/mi-ui-library';
import {
  getDatesLabel,
  getFormattedDates,
  getFormattedDate,
  getFormattedDateObject,
  setCalendarOptions,
} from '../../../utils';
import { TextFormField, DatePickerMobile } from '../../../molecules';
import { onChangeParams as DateType } from '../DatePickerMobile/DatePickerMobile.types';
import { DatePickerMobileContainerProps } from './DatePickerMobileContainer.types';
import { StyledDatePickerMobileContainer } from './DatePickerMobileContainer.styles';
import { useFocusOut, useMediaQuery } from '../../../hooks';
import { ENTER_KEY } from '../../../constants';

export const DatePickerMobileContainer: FC<DatePickerMobileContainerProps> = ({
  dates,
  startDate,
  endDate,
  isDateRangeFixed = false,
  rangeStartDate,
  rangeEndDate,
  setDefaultDates = true,
  showErrorMessage = false,
  errorMessage,
  isPastDateSelectionDisabled,
  enableFromPastDate,
  onChange,
  onClose,
}) => {
  const { shortWeekdayNames, longWeekdayNames, shortMonthNames, longMonthNames } = dates;

  const defaultStartDate = getCurrentDateObject();
  const defaultEndDate = getNextDateObject(defaultStartDate);
  const initialStartDate = setDefaultDates ? startDate || defaultStartDate : null;
  const initialEndDate = setDefaultDates ? endDate || defaultEndDate : null;

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const datePickerContainerRef = useRef<HTMLDivElement>(null);
  const prevDateValueRef = useRef<DateType>();

  const [isOpen, setIsOpen] = useState(false);
  const [dateObject, setDateObject] = useState<DateType>({
    dates: '',
    startDate: '',
    endDate: '',
    datesLabel: '',
  });

  useEffect(() => {
    setCalendarOptions({
      shortWeekdayNames,
      longWeekdayNames,
      shortMonthNames,
      longMonthNames,
    });
  }, [shortWeekdayNames, longWeekdayNames, shortMonthNames, longMonthNames]);

  useEffect(() => {
    setDateObject({
      dates: getFormattedDates(moment(initialStartDate), moment(initialEndDate)),
      startDate: getFormattedDate(initialStartDate),
      endDate: getFormattedDate(initialEndDate),
      datesLabel: getDatesLabel(initialStartDate, initialEndDate, dates.label, dates.night, dates.nights),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange?.(dateObject.dates, dateObject.startDate, dateObject.endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateObject]);

  useFocusOut({
    ref: datePickerContainerRef.current,
    onFocusOut: (isFocusOut: boolean) => {
      if (isFocusOut) {
        handleCancel();
      }
    },
    handleClick: false,
    handleKeyup: true,
  });

  const updateEndDate = () => {
    const { startDate, endDate } = dateObject;
    if (startDate && !endDate) {
      const startDateObject = getFormattedDateObject(startDate, 'dateWithDayMonthAndYear');
      const isStartDateLastDayInRange = isDateRangeFixed && startDateObject.isSame(rangeEndDate, 'days');
      const selectedStartDate = isStartDateLastDayInRange ? getPrevDateObject(startDateObject) : startDateObject;
      const selectedEndDate = isStartDateLastDayInRange ? startDateObject : getNextDateObject(startDateObject);
      const formattedDates = getFormattedDates(selectedStartDate, selectedEndDate);

      setDateObject(prevDate => ({
        ...prevDate,
        dates: formattedDates,
        startDate: getFormattedDate(selectedStartDate),
        endDate: getFormattedDate(selectedEndDate),
        datesLabel: getDatesLabel(selectedStartDate, selectedEndDate, dates.label, dates.night, dates.nights),
      }));
    }
  };

  const handleDone = () => {
    updateEndDate();
    onChange?.(dateObject.dates, dateObject.startDate, dateObject.endDate);
    setIsOpen(false);
    onClose?.();
  };

  const handleCancel = () => {
    if (prevDateValueRef.current) {
      setDateObject(prevDateValueRef.current);
    }
    setIsOpen(false);
    onClose?.();
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.relatedTarget) {
      prevDateValueRef.current = { ...dateObject };
      setIsOpen(true);
    }
  };

  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      prevDateValueRef.current = { ...dateObject };
      setIsOpen(true);
    }
  };

  return (
    <StyledDatePickerMobileContainer>
      <TextFormField
        label={dateObject.startDate && dateObject.endDate ? dateObject.datesLabel : dates.label}
        ariaLabel={dates.ariaLabel}
        value={dateObject.dates}
        className="m-input-field"
        showErrorMessage={showErrorMessage}
        errorMessage={errorMessage}
        getInputProps={() => ({
          readOnly: true,
        })}
        onClick={() => {
          prevDateValueRef.current = { ...dateObject };
          setIsOpen(true);
        }}
        onFocus={handleFocus}
        onKeyDown={handleKeydown}
      />
      {isOpen ? (
        <div
          ref={isTabletAndAbove ? datePickerContainerRef : null}
          className="mobile-container"
          data-testid="mobile-container"
        >
          <div className="mobile-container-header">
            <div className="t-label-inverse-s">{dates.label}</div>
            <div className="d-flex justify-content-between mt-2">
              <div className="t-font-inverse-m">{dateObject.dates}</div>
              <Button
                buttonCopy={dates.cancel}
                ariaLabel={dates.cancelAriaLabel}
                className="t-label-inverse-s p-0 cancel-cta"
                callback={handleCancel}
              />
            </div>
          </div>
          <div className="mobile-container-body">
            <DatePickerMobile
              dates={dates}
              startDate={
                dateObject.startDate ? getFormattedDateObject(dateObject.startDate, 'dateWithDayMonthAndYear') : null
              }
              endDate={
                dateObject.endDate ? getFormattedDateObject(dateObject.endDate, 'dateWithDayMonthAndYear') : null
              }
              isDateRangeFixed={isDateRangeFixed}
              rangeStartDate={rangeStartDate}
              rangeEndDate={rangeEndDate}
              setDefaultDates={setDefaultDates}
              showFlexibleDates={false}
              isPastDateSelectionDisabled={isPastDateSelectionDisabled}
              enableFromPastDate={enableFromPastDate}
              onChange={dates => {
                setDateObject(dates);
              }}
            />
          </div>
          <div className="px-3 py-5 mobile-container-footer">
            <Button
              buttonCopy={dates.done}
              ariaLabel={dates.doneAriaLabel}
              className="m-button-primary d-block w-100"
              callback={handleDone}
            />
          </div>
        </div>
      ) : null}
    </StyledDatePickerMobileContainer>
  );
};
