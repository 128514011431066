import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, font, shadows, mediaQuery } = baseVariables;

export const StyledRoomsAndGuests = styled.div`
  position: relative;

  .icon-s {
    cursor: pointer;
  }

  .dropdown-container {
    position: absolute;
    top: ${toRem(46)};
    width: 100%;
    z-index: 3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    @media ${mediaQuery.md} {
      width: auto;
    }
    @media ${mediaQuery.lg} {
      width: 100%;
    }
  }

  .stepper-container {
    border-bottom: ${toRem(1)} solid ${color.neutral20};
    .m-stepper-label {
      width: ${toRem(170)};
      .t-font-s {
        font-size: ${toRem(18)};
        font-weight: ${font.fontWeightMedium};
      }
    }
  }

  .reset-cta {
    color: ${color.neutral00};
    background: transparent;
    font-size: ${toRem(14)};
    line-height: ${toRem(20)};
    font-weight: ${font.fontWeightMedium};
    border-bottom-width: 0;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
  }

  .contain {
    margin: 0;
  }

  .mobile-container {
    background: ${color.base20};
    height: 100%;
    display: grid;
    grid-template-rows: ${toRem(106)} 1fr ${toRem(157)};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    &-header {
      background-color: ${color.base10};
      padding: ${toRem(48)} ${toRem(16)} ${toRem(12)};
      .clear-cta {
        background: transparent;
      }
    }
    &-body {
      overflow-x: auto;
    }
    &-footer {
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      box-shadow: 0 0 ${toRem(8)} 0 ${shadows.boxShadowTile};
      .apply-cta {
        border-top: ${toRem(1)} solid ${color.neutral20};
        .w-100 {
          width: 100%;
        }
      }
    }
  }
`;
