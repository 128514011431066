import { FC, useEffect, useRef, useState } from 'react';
import { LocaleUtils } from 'react-day-picker';
import { Calendar, getCurrentDateObject, getNextDateObject, getPrevDateObject } from '@marriott/mi-ui-library';
import {
  CALENDAR_END_DATE,
  CALENDAR_SHORT_WEEKDAY_INITIALS,
  CALENDAR_SHORT_WEEKDAY_NAMES,
  CALENDAR_LONG_WEEKDAY_NAMES,
  CALENDAR_SHORT_MONTH_NAMES,
  CALENDAR_MAX_ALLOWED_DAYS,
  CALENDAR_START_DATE,
} from '../../constants';
import { DatePickerProps, DateObject, DatePickerState, SelectedDateType } from './DatePicker.types';
import { StyledDatePickerContainer } from './DatePicker.styles';
import { getDefaultDates, getLocaleValue, getCurrentLocale } from '../../utils';
import { ErrorHandlerAlert } from '../../components';

export const DatePicker: FC<DatePickerProps> = ({
  variation,
  nightLabel,
  nightsLabel,
  shortWeekdayNames = CALENDAR_SHORT_WEEKDAY_NAMES,
  longWeekdayNames = CALENDAR_LONG_WEEKDAY_NAMES,
  shortMonthNames = CALENDAR_SHORT_MONTH_NAMES,
  longMonthNames = LocaleUtils.getMonths(),
  setInitialDates,
  setDates,
  selectedDateType: focusedInputDateType = CALENDAR_START_DATE,
  selectedStartDate,
  selectedEndDate,
  isDateRangeFixed = false,
  rangeStartDate,
  rangeEndDate,
  setDefaultDates,
  resetDatePicker,
  isDatePickerClosed = true,
  isThereTwoDateInputBox = false,
  isPastDateSelectionDisabled = true,
  enableFromPastDate,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calendarRef = useRef<any>(null);
  const initialStartDate = useRef<DateObject | null>(null);
  const initialEndDate = useRef<DateObject | null>(null);

  const [state, setState] = useState<DatePickerState>({
    startDate: null,
    endDate: null,
    selectedDateType: focusedInputDateType,
  });

  const { startDate, endDate, selectedDateType } = state;

  useEffect(() => {
    const { defaultStartDate, defaultEndDate } = setDefaultDates
      ? getDefaultDates(selectedStartDate, selectedEndDate)
      : { defaultStartDate: null, defaultEndDate: null };

    initialStartDate.current = isDateRangeFixed
      ? rangeStartDate || defaultStartDate
      : setDefaultDates
      ? getCurrentDateObject()
      : null;
    initialEndDate.current = isDateRangeFixed
      ? rangeEndDate || defaultEndDate
      : setDefaultDates && initialStartDate.current
      ? getNextDateObject(initialStartDate.current)
      : null;

    updateState(defaultStartDate, defaultEndDate);
    updateView(defaultStartDate, defaultEndDate);

    if (defaultStartDate && defaultEndDate && initialStartDate.current && initialEndDate.current) {
      setInitialDates?.(
        defaultStartDate,
        defaultEndDate,
        initialStartDate.current,
        initialEndDate.current,
        selectedDateType
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetDatePicker) {
      resetDates();
    }
    if (!resetDatePicker && !isDatePickerClosed && !endDate) {
      closeDatePicker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDatePicker, isDatePickerClosed]);

  const updateDates = (
    startDate: DateObject | null,
    endDate?: DateObject | null,
    selectedDateType: SelectedDateType = CALENDAR_END_DATE
  ) => {
    const selectedStartDate = startDate;
    let selectedEndDate = endDate || null;
    if (selectedStartDate?.isSame(selectedEndDate, 'date')) {
      selectedEndDate = null;
    }
    setSelectedDateType(selectedDateType);
    updateState(selectedStartDate, selectedEndDate);
    setDates(selectedStartDate, selectedEndDate, selectedDateType);
  };

  const updateState = (selectedStartDate: DateObject | null, selectedEndDate: DateObject | null) => {
    setState(prevState => ({
      ...prevState,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    }));
  };

  const updateView = (startDate: DateObject | null, endDate: DateObject | null) => {
    calendarRef?.current?.setFromEndDate(startDate?.toDate(), endDate?.toDate());
    calendarRef?.current?.setDefaultMonth(startDate?.toDate());
  };

  const resetDates = () => {
    updateDates(initialStartDate.current, initialEndDate.current, CALENDAR_START_DATE);
    updateView(initialStartDate.current, initialEndDate.current);
  };

  const closeDatePicker = () => {
    const { startDate, endDate } = state;

    if (startDate && endDate === null) {
      const isStartDateLastDateInRange = isDateRangeFixed && startDate?.isSame(initialEndDate.current, 'days');
      const selectedStartDate = isStartDateLastDateInRange ? getPrevDateObject(startDate) : startDate;
      const selectedEndDate = isStartDateLastDateInRange ? startDate : getNextDateObject(startDate);

      updateState(selectedStartDate, selectedEndDate);
      setDates(selectedStartDate, selectedEndDate, selectedDateType);
      updateView(selectedStartDate, selectedEndDate);
    }
  };

  const setSelectedDateType = (value: SelectedDateType) => {
    setState(prevState => ({ ...prevState, selectedDateType: value }));
  };

  const calendar = (startDate || !setDefaultDates) && (
    <Calendar
      ref={calendarRef}
      startDate={startDate}
      endDate={endDate}
      variation={variation}
      focusedInput={selectedDateType}
      weekdaysShort={shortWeekdayNames}
      weekdays={shortWeekdayNames}
      weekdaysLong={longWeekdayNames}
      monthsShort={shortMonthNames}
      months={longMonthNames}
      showOutsideDays={false}
      defaultMonth={initialStartDate.current?.toDate()}
      firstDayOfWeek={getLocaleValue('firstDayofWeek')}
      maxAllowedDays={CALENDAR_MAX_ALLOWED_DAYS}
      yearLabel={getLocaleValue('year') || ''}
      nightLabel={nightLabel}
      nightsLabel={nightsLabel}
      isThereTwoDateInputBox={isThereTwoDateInputBox}
      customDatesFlag={isDateRangeFixed}
      isPastDateSelectionDisabled={isPastDateSelectionDisabled}
      enableFromPastDate={enableFromPastDate}
      {...(isDateRangeFixed && {
        customFromDate: initialStartDate.current?.toDate(),
        customToDate: initialEndDate.current?.toDate(),
      })}
      setDateHandler={(startDate, endDate) => updateDates(startDate, endDate)}
      setFocusedInputHandler={val => setSelectedDateType(val)}
      locale={getCurrentLocale()}
    />
  );

  return (
    <StyledDatePickerContainer data-component-name="m-groups-DatePicker" data-testid="groups-DatePicker">
      <ErrorHandlerAlert>{calendar}</ErrorHandlerAlert>
    </StyledDatePickerContainer>
  );
};
