import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, spacers, font } = baseVariables;

export const GlobalStyles = createGlobalStyle`
  body {
    height: 100vh;
    overflow-y: hidden;
  }
`;

export const StyledGroupSearchFormMobileContainer = styled.div`
  display: grid;
  grid-template-rows: ${toRem(106)} 1fr;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  right: 0;
  bottom: 0;
  .w-100 {
    width: 100%;
  }
  &.expanded-form {
    grid-template-rows: 1fr;
  }
  &.show-footer {
    grid-template-rows: ${toRem(106)} 1fr ${toRem(104)};
  }
`;

export const StyledGroupSearchFormMobileContainerHeader = styled.div`
  min-height: ${toRem(106)};
  padding: ${toRem(48)} ${spacers.spacer} ${toRem(12)};
  background-color: ${color.base10};
  .cancel-cta {
    padding-top: ${toRem(21)};
    border-width: 0;
    border-radius: unset;
    white-space: nowrap;
  }
  .cancel-cta:hover {
    box-shadow: unset;
    border: unset;
  }
  .cancel-cta:focus {
    outline: unset;
  }

  .subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text-field {
    position: relative;
    input {
      font-size: ${toRem(16)} !important;
      line-height: ${toRem(20)} !important;
      padding: 0;
      color: ${color.base20} !important;
      display: block;
      border-bottom: none;
      &:focus {
        border-bottom: none;
      }
      &::placeholder {
        color: ${color.neutral30} !important;
      }
    }
    input:focus {
      max-width: 90%;
      text-overflow: ellipsis;
      word-wrap: nowwrap;
    }

    label {
      font-size: ${toRem(14)};
      font-weight: ${font.fontWeightMedium};
      line-height: ${toRem(16)};
      color: ${color.base20};
      text-transform: none;
    }
    .icon {
      left: initial;
      position: absolute;
      right: 0;
      top: ${toRem(24)};
    }
  }
`;

export const StyledGroupSearchFormMobileContainerBody = styled.div`
  background-color: ${color.base20};
  overflow-y: auto;
  padding-top: ${spacers.spacers?.[4]};
  &.expanded-form {
    padding: 0;
  }
`;

export const StyledGroupSearchFormMobileContainerFooter = styled.div`
  width: 100%;
  padding: ${spacers.spacers?.[5]} ${spacers.spacers?.[3]};
  background-color: ${color.base20};
  box-shadow: 0 0 ${toRem(8)} 0 rgba(0, 0, 0, 0.07);
  position: fixed;
  bottom: 0;
`;

export const StyledGroupSearchFormMobileContainerEventType = styled.div`
  padding-left: ${toRem(21)};
  .radio-controls-list__radio {
    margin-bottom: ${toRem(14)};
  }
`;
