import { setupServer } from 'msw/node';
import { handlers } from './handlers';

if (typeof window === 'undefined') {
  const server = setupServer(...handlers);
  if (server) {
    server.listen();
  }
} else {
  const { worker } = require('./browser');
  worker.start();
}
