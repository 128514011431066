import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledSingleSelectList = styled.div`
  &.m-input-field {
    position: relative;
    &.is-error {
      label {
        color: unset;
      }
    }
  }
  .option-layout {
    position: absolute !important;
    width: 100%;
    z-index: 1;
    background-color: ${color.base20};
    box-shadow: 0 ${toRem(2)} ${toRem(8)} 0 rgba(0, 0, 0, 0.24);
    .filter-sub-heading {
      padding-top: ${toRem(16)} !important;
    }
    .row {
      padding-bottom: ${toRem(16)};
    }
    .radio-controls-list__radio {
      padding-left: 0;
      padding-top: ${toRem(8)};
      overflow-wrap: break-word;
    }
  }
  .icon-arrow-up,
  .icon-arrow-down {
    font-size: ${toRem(12)} !important;
    cursor: pointer;
  }
  .contain {
    line-height: 0;
    margin-top: 0;
  }
`;
