import styled, { keyframes } from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const animationFadeIn = keyframes`
  10% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.6;
  }
`;

export const StyledSkeletonLoaderAtom = styled.div<{ height?: number; width?: number }>`
  height: ${props => (props.height ? props.height + 'px' : '100%')};
  width: ${props => (props.width ? props.width + 'px' : '100%')};
  background-color: ${baseVariables.color.neutral30};
  color: white;
  animation: ${animationFadeIn} 2s ease-in alternate infinite;
  -webkit-animation: ${animationFadeIn} 2s ease-in alternate infinite;
  -moz-animation: ${animationFadeIn} 2s ease-in alternate infinite;
`;

export const StyledSkeletonLoaderDiv = styled.div`
  display: flex;
  box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
  background-color: ${baseVariables.color.base20};
  border-radius: ${toRem(14)};
  flex-flow: wrap;
  @media ${baseVariables.mediaQuery.md} {
    flex-flow: nowrap;
    height: ${toRem(298)};
  }

  &.compact {
    flex-direction: column;
    height: auto;
  }

  .loader {
    background-color: ${baseVariables.color.neutral30};
    color: white;
    -webkit-animation: ${animationFadeIn} 2s ease-in alternate infinite;
    -moz-animation: ${animationFadeIn} 2s ease-in alternate infinite;
    animation: ${animationFadeIn} 2s ease-in alternate infinite;
  }

  .sl__left {
    width: 100%;
    min-height: ${toRem(200)};
    @media ${baseVariables.mediaQuery.md} {
      width: 45%;
    }
    .loader__image {
      border-radius: ${toRem(10)};
      border-top-left-radius: ${toRem(10)};
      border-top-right-radius: ${toRem(10)};
      border-bottom-left-radius: ${toRem(0)};
      border-bottom-right-radius: ${toRem(0)};
      @media ${baseVariables.mediaQuery.md} {
        border-top-left-radius: ${toRem(10)};
        border-bottom-left-radius: ${toRem(10)};
        border-top-right-radius: ${toRem(0)};
        border-bottom-right-radius: ${toRem(0)};
      }
    }
  }

  .sl__right {
    width: 100%;
    padding: ${toRem(20)} ${toRem(15)};
    @media ${baseVariables.mediaQuery.md} {
      width: 55%;
    }
    .sl__right-top {
      width: 100%;
    }
    .sl__right-bottom {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .sl__top {
    width: 100%;
    min-height: ${toRem(222)};
    .loader__image {
      min-height: ${toRem(222)};
      border-radius: ${toRem(10)};
      border-top-left-radius: ${toRem(10)};
      border-top-right-radius: ${toRem(10)};
      border-bottom-left-radius: ${toRem(0)};
      border-bottom-right-radius: ${toRem(0)};
    }
  }

  .sl__bottom {
    width: 100%;
    padding: ${toRem(20)} ${toRem(15)};

    .sl__right-top {
      width: 100%;
    }

    .sl__right-bottom {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .loader__property-description {
    display: none;
    @media ${baseVariables.mediaQuery.md} {
      display: block;
    }
  }
`;

export const StyledSkeletonLoaderContainer = styled.div`
  ${StyledSkeletonLoaderDiv} {
    margin-top: ${toRem(16)};
  }
`;
