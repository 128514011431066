import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { baseVariables } from '@marriott/mi-ui-library';
import { useMediaQuery } from '../../../hooks';
import { DefaultFlyout } from './DefaultFlyout';
import { TypeAheadFlyout } from './TypeAheadFlyout';
import { TypeAheadFlyoutMobile } from './TypeAheadFlyoutMobile';
import type { DestinationProps } from './Destination.types';
import { StyledDestination } from './Destination.styles';

export const Destination = React.forwardRef<HTMLInputElement, DestinationProps>(
  (
    { labels, currentValue, customHeaders, showErrorMessage, isInputFocused, updateFormData, destinationOnChange },
    ref
  ) => {
    const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

    const [showTypeAheadFlyout, setShowTypeAheadFlyout] = useState(false);
    const [showDefaultFlyout, setShowDefaultFlyout] = useState(false);
    const [destinationOnFocus, setDestinationOnFocus] = useState(false); //investigate and change it
    const [value, setValue] = useState<string | null>(currentValue || '');

    useEffect(() => {
      if (isInputFocused) {
        setDestinationOnFocus(true);
      } else {
        setDestinationOnFocus(false);
      }
    }, [isInputFocused]);

    useEffect(() => {
      if (destinationOnFocus) {
        if (currentValue) {
          setShowTypeAheadFlyout(true);
          setShowDefaultFlyout(false);
        } else {
          setShowTypeAheadFlyout(false);
          setShowDefaultFlyout(true);
        }
      } else {
        if (currentValue) {
          setShowDefaultFlyout(false);
        } else {
          setShowDefaultFlyout(!isTabletAndAbove);
        }
        setShowTypeAheadFlyout(false);
      }
    }, [destinationOnFocus, value, currentValue, isTabletAndAbove]);

    const closeFlyOut = () => {
      setShowTypeAheadFlyout(false);
      if (isTabletAndAbove) {
        setShowDefaultFlyout(false);
      }
    };

    const updateDestination = (description: string, placeId?: string) => {
      updateFormData?.({ destination: { description, placeId: placeId || '' } });
      destinationOnChange?.({ description, placeId: placeId || '' });
    };

    const onTypeAheadSelected = (value: string | null) => {
      setValue(value);
      setDestinationOnFocus(false);
      if (!value) {
        updateDestination('', '');
      }
    };

    return (
      <StyledDestination ref={ref}>
        {isTabletAndAbove ? (
          <TypeAheadFlyout
            show={showTypeAheadFlyout}
            className={clsx(showTypeAheadFlyout ? 'type-ahead-flyout' : '', 'search-page-destination')}
            destinationOnFocus={destinationOnFocus}
            setDestinationOnFocus={setDestinationOnFocus}
            setShowTypeAheadFlyout={setShowTypeAheadFlyout}
            setShowDefaultFlyout={setShowDefaultFlyout}
            updateDestination={updateDestination}
            labels={labels}
            value={value || currentValue}
            onChange={onTypeAheadSelected}
            customHeaders={customHeaders}
            showErrorMessage={showErrorMessage}
          />
        ) : (
          <TypeAheadFlyoutMobile
            show={showTypeAheadFlyout}
            currentValue={currentValue || ''}
            customHeaders={customHeaders}
            updateDestination={updateDestination}
            setShowDefaultFlyout={setShowDefaultFlyout}
            setShowTypeAheadFlyout={setShowTypeAheadFlyout}
            onChange={onTypeAheadSelected}
          />
        )}
        <div aria-live="assertive" className="screen-reader-text sr-only" />
        <div id="default-flyout" className="flyout-container">
          {showDefaultFlyout && (
            <div className="show-flyout-scrollable custom-scrollbar">
              <DefaultFlyout
                updateDestination={updateDestination}
                setDestinationOnFocus={setDestinationOnFocus}
                setValue={setValue}
                closeFlyOut={closeFlyOut}
                labels={labels}
                setShowDefaultFlyout={setShowDefaultFlyout}
              />
            </div>
          )}
        </div>
      </StyledDestination>
    );
  }
);
