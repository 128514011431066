import React, { FC, useMemo } from 'react';
import { Button, baseVariables } from '@marriott/mi-ui-library';
import { ViewToggleProps } from './ViewToggle.types';
import { StyledViewToggle } from './ViewToggle.styles';
import { ViewType } from '../../organisms/SearchResults/SearchResults.types';
import { useRfpTrayStore } from '../../store';
import { RfpTrayView } from '../../organisms/RfpTray/RfpTray.types';
import { RFP_TRAY_HEIGHT } from '../../constants';
import { useMediaQuery } from '../../hooks';

export const ViewToggle: FC<ViewToggleProps> = ({ ctaLabel, variant, trackLocation, toggleView }) => {
  const { visibility } = useRfpTrayStore();
  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);
  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);

  const rfpTrayHeight = useMemo(() => {
    if (visibility === RfpTrayView.EXPANDED) {
      return isDesktop
        ? RFP_TRAY_HEIGHT.DESKTOP_EXPANDED
        : isTablet
        ? RFP_TRAY_HEIGHT.TABLET_EXPANDED
        : RFP_TRAY_HEIGHT.MOBILE_EXPANDED;
    } else if (visibility === RfpTrayView.COLLAPSED) {
      return isTablet ? RFP_TRAY_HEIGHT.TABLET_AND_ABOVE_COLLAPSED : RFP_TRAY_HEIGHT.MOBILE_COLLAPSED;
    }
    return 0;
  }, [visibility, isTablet, isDesktop]);

  return (
    <StyledViewToggle
      data-component-name="m-groups-ViewToggle"
      data-testid="groups-ViewToggle"
      className={`${variant === ViewType.MAP_VIEW ? `map-view-toggle-container` : `list-view-toggle-container`}`}
      bottom={rfpTrayHeight}
    >
      <Button
        className="m-button-primary-inverse view-toggle-cta"
        custom_click_track_value={`${trackLocation} | ${ctaLabel} |internal`}
        callback={toggleView}
      >
        <span className={`icon ${variant === ViewType.MAP_VIEW ? `icon-map` : `icon-list-view`}`} />
        {ctaLabel}
      </Button>
    </StyledViewToggle>
  );
};
