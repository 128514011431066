import { FC, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Heading, Text, Types } from '@marriott/mi-ui-library';
import { GroupSearchForm } from '../GroupSearchForm';
import { InvalidSessionProps } from './InvalidSession.types';
import { StyledInvalidSession } from './InvalidSession.styles';

export const InvalidSessionConfig = {
  emptyLabel: 'InvalidSession',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/quickgroup/invalidsession`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InvalidSession: FC<InvalidSessionProps> = (props: any) => {
  const { heading, subHeading, groupSearchForm } = props.model;

  useEffect(() => {
    const mainElement = document.getElementsByTagName('main')[0];
    if (mainElement) mainElement.classList.add('grid-layout-container');
    return () => {
      if (mainElement) mainElement.classList.remove('grid-layout-container');
    };
  }, []);

  return (
    <EditableComponent config={InvalidSessionConfig} {...props}>
      <StyledInvalidSession>
        <div>
          <Heading
            variation={Types.headingType.title}
            element={Types.tags.h1}
            titleText={heading}
            customClass="t-title-s mb-2 mt-5"
          />
          <Text element={Types.tags.paragraph} copyText={subHeading} fontSize={Types.size.medium} />
        </div>
        <GroupSearchForm model={groupSearchForm} />
      </StyledInvalidSession>
    </EditableComponent>
  );
};
