import { Lookup } from '../rfp/getLookupsByType.types';
import { Amount } from '../search/searchGroupRatesByGeolocation.types';
import { Media } from '../search/searchPropertiesByGeolocation.types';

export type GroupAvailabilityByPropertyInput = {
  groupId: string;
  startDate: string;
  endDate: string;
  numberOfRooms: number;
  numberOfAttendees: number;
  properties: PropertyDetails[];
};

type PropertyDetails = {
  brandId: string;
  propertyId: string;
  requestCode: string;
};

export type GroupAvailabilityByPropertyResponse = {
  groupRates: GroupRate[];
};

type GroupRate = {
  type: GroupRateType;
  roomRates: RoomRate[];
  cutoffDate: string;
};

export enum GroupRateType {
  GPO = 'GPO',
  GRAM = 'GRAM',
  NONE = '',
}

export type RoomRate = {
  roomType: RoomType;
  amount: {
    origin: Amount;
  };
  startDate: string;
  maximumInventory: number;
  rateOffset: number;
  isPrimary: boolean;
};

type RoomType = {
  id: string;
  name: string;
  description: string;
  photoGallery: Media;
  details: RoomTypeDescription[];
};

export type RoomTypeDescription = {
  type: Lookup;
  descriptions: string[];
};
