import { toRem, baseVariables } from '@marriott/mi-ui-library';
import styled from 'styled-components';

const { spacers, mediaQuery, font, color } = baseVariables;

const outline = `&:focus-visible:not(:active) {
  outline: -webkit-focus-ring-color auto ${toRem(1)}
}`;

export const StyledImageCarousel = styled.div`
  .carousel-container {
    width: 100%;
    .carousel-image {
      width: 100%;
    }
    &.standalone-carousel {
      margin-bottom: ${toRem(16)};
      margin-top: ${spacers.spacer};
      .carousel-image {
        border-radius: ${toRem(14)};
      }
      @media ${mediaQuery.md} {
        height: ${toRem(374)};
        .carousel-image {
          height: ${toRem(374)};
        }
      }
    }
    &.regular-carousel {
      height: ${toRem(221)};
      .carousel-image {
        height: ${toRem(221)};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      @media ${mediaQuery.md} {
        width: ${toRem(320)};
        height: ${toRem(240)};
        .carousel-image {
          width: ${toRem(320)};
          height: ${toRem(240)};
          border-top-right-radius: 0;
          border-bottom-left-radius: ${toRem(13)};
        }
      }
      @media ${mediaQuery.lg} {
        width: ${toRem(504)};
        height: ${toRem(338)};
        .carousel-image {
          width: ${toRem(504)};
          height: ${toRem(338)};
        }
      }
      @media ${mediaQuery.xl} {
        height: ${toRem(298)};
        .carousel-image {
          height: ${toRem(298)};
        }
      }
    }
    &.compact-carousel {
      height: 100%;
      .carousel-image {
        width: 100%;
        height: ${toRem(222)};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      @media ${mediaQuery.md} {
        .carousel-image {
          width: ${toRem(333)};
        }
      }
    }
    &.fullbleed-carousel {
      .carousel-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        aspect-ratio: 3/2;
      }
    }
    .carousel-scrim-effect {
      position: absolute;
      bottom: 0;
      height: ${toRem(116)};
      width: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 45%, rgba(0, 0, 0, 0.52) 100%);
      border-bottom-left-radius: ${toRem(14)};
    }
  }
  .glide__slides {
    .glide__slide {
      width: 100%;
      list-style: none;
      position: relative;
    }
  }
  .glide__track {
    position: relative;
    overflow: hidden;
  }
  .glide__arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    border-radius: 50%;
    border-color: transparent;
    width: ${toRem(40)};
    height: ${toRem(40)};
    background-color: rgba(0, 0, 0, 0.54);
    font-size: ${font.fontXxxl};
    color: ${color.merch50};
    box-shadow: 0 ${toRem(4)} ${toRem(8)} 0 rgba(0, 0, 0, 0.1);
    &.right-arrow {
      right: ${spacers.spacer};
      ${outline}
    }
    &.left-arrow {
      left: ${spacers.spacer};
      ${outline}
    }
    .icon-arrow-left,
    .icon-arrow-right {
      &:before {
        position: absolute;
        top: 15%;
        right: 50%;
        bottom: 50%;
        left: 15%;
      }
    }
  }
  .glide-bullet-container {
    justify-content: space-evenly;
    position: absolute;
    right: ${spacers.spacer};
    bottom: ${toRem(8)};
    border-radius: ${toRem(22)};
    height: ${toRem(32)};
    min-width: ${toRem(94)};
    background-color: rgba(0, 0, 0, 0.33);
    &.center {
      left: 0;
      right: 0;
      margin: auto;
      width: fit-content;
    }
    .glide__bullet {
      border-radius: 50%;
      background-color: ${color.merch50};
      height: ${toRem(6)};
      width: ${toRem(6)};
      opacity: 0.34;
      outline: none;
      pointer-events: none;
      cursor: default;
      &--active {
        opacity: 1;
        height: ${toRem(8)};
        width: ${toRem(8)};
      }
    }
  }
`;
