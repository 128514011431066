import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type RequestIdState = {
  requestId: string;
};

type RequestIdActions = {
  setRequestId: (requestId: string) => void;
};

const initialState: RequestIdState = {
  requestId: '',
};

export const requestIdStore: StateCreator<RequestIdState & RequestIdActions> = set => {
  return {
    ...initialState,

    setRequestId: (requestId: string) => {
      set(prevState => {
        return {
          ...prevState,
          requestId,
        };
      });
    },
  };
};

export const useRequestIdStore = createAppStore(requestIdStore);
