import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, spacers } = baseVariables;

export const StyledExpandedFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.base20};
`;

export const StyledExpandedForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: ${toRem(50)} ${spacers.spacers?.[2]} 0 ${spacers.spacers?.[2]};
`;

export const StyledHeader = styled.div`
  background-color: ${color.base10};
  button {
    height: ${toRem(53)};
    padding: ${toRem(3)};
    color: ${color.base20};
    background: transparent;
  }
`;

export const StyledFooter = styled.div`
  padding: ${spacers.spacers?.[3]} 0 ${spacers.spacers?.[5]} 0;
  margin: auto ${spacers.spacers?.[2]} 0 ${spacers.spacers?.[2]};
  button {
    width: 100%;
  }
`;
