import {
  MediaContent,
  Node,
  GalleryContent,
  ProductImage,
  BrandContent,
  PrimaryImage,
} from '@marriott/mi-groups-graphql';
import {
  PROPERTY_DEFAULT_IMAGE_URL,
  PROPERTY_IMAGE_DIMENSIONS,
  PROPERTY_IMAGE_OUTPUT_INTERPOLATION,
  PROPERTY_IMAGE_OUTPUT_QUALITY,
  PROPERTY_IMAGE_CAROUSEL_LENGTH,
  PROPERTY_IMAGE_RENDITION_CLASSIC,
  PROPERTY_IMAGE_RENDITION_WIDE,
  PROPERTY_IMAGE_RENDITION_SQUARE,
  PROPERTY_IMAGE_RENDITION,
  PROPERTY_IMAGE_CROPPING_RATIO,
  ASSET_DOMAIN,
} from '../constants';
import {
  PropertyImage,
  PropertyImageKey,
  PropertyImageType,
} from '../organisms/SearchResults/PropertyCard/PropertyCard.types';
import { ViewType } from '../organisms/SearchResults/SearchResults.types';

type Rendition = keyof typeof PROPERTY_IMAGE_RENDITION;

const getUniquePropertyImages = (imageList: PropertyImage[], key: string): PropertyImage[] => {
  return Array.from(
    new Map(imageList?.map(item => [getPropertyImageFileName(item[key as PropertyImageKey]), item])).values()
  );
};

const getPropertyImageFileName = (imgURL: string) => {
  if (!imgURL) return '';
  const pathSegments = new URL(imgURL).pathname.split('/');
  const fileNameWithExtension = pathSegments[pathSegments.length - 1];
  const fileName = fileNameWithExtension.split('.')[0];
  return fileName;
};

const getPropertyImageConfig = (imageType: PropertyImageType) => {
  switch (imageType) {
    case PropertyImageType.MINI:
      return {
        width: PROPERTY_IMAGE_DIMENSIONS.MINI_IMAGE_WIDTH,
        height: PROPERTY_IMAGE_DIMENSIONS.MINI_IMAGE_HEIGHT,
        rendition: PROPERTY_IMAGE_RENDITION_SQUARE,
        fallbackImageUrl: PROPERTY_DEFAULT_IMAGE_URL.square,
      };
    case PropertyImageType.REGULAR_MOBILE:
      return {
        width: PROPERTY_IMAGE_DIMENSIONS.REGULAR_MOBILE_IMAGE_WIDTH,
        height: PROPERTY_IMAGE_DIMENSIONS.REGULAR_MOBILE_IMAGE_HEIGHT,
        rendition: PROPERTY_IMAGE_RENDITION_CLASSIC,
        fallbackImageUrl: PROPERTY_DEFAULT_IMAGE_URL.classic,
      };
    case PropertyImageType.REGULAR_TABLET:
      return {
        width: PROPERTY_IMAGE_DIMENSIONS.REGULAR_TABLET_IMAGE_WIDTH,
        height: PROPERTY_IMAGE_DIMENSIONS.REGULAR_TABLET_IMAGE_HEIGHT,
        rendition: PROPERTY_IMAGE_RENDITION_CLASSIC,
        fallbackImageUrl: PROPERTY_DEFAULT_IMAGE_URL.classic,
      };
    case PropertyImageType.REGULAR_DESKTOP:
      return {
        width: PROPERTY_IMAGE_DIMENSIONS.REGULAR_DESKTOP_IMAGE_WIDTH,
        height: PROPERTY_IMAGE_DIMENSIONS.REGULAR_DESKTOP_IMAGE_HEIGHT,
        rendition: PROPERTY_IMAGE_RENDITION_WIDE,
        fallbackImageUrl: PROPERTY_DEFAULT_IMAGE_URL.wide,
      };
    case PropertyImageType.LARGE_DESKTOP:
      return {
        width: PROPERTY_IMAGE_DIMENSIONS.LARGE_DESKTOP_IMAGE_WIDTH,
        height: PROPERTY_IMAGE_DIMENSIONS.LARGE_DESKTOP_IMAGE_HEIGHT,
        rendition: PROPERTY_IMAGE_RENDITION_WIDE,
        fallbackImageUrl: PROPERTY_DEFAULT_IMAGE_URL.wide,
      };
  }
};

const getPropertyImageUrlParams = (width: number) => {
  return `?output-quality=${PROPERTY_IMAGE_OUTPUT_QUALITY}&interpolation=${PROPERTY_IMAGE_OUTPUT_INTERPOLATION}&downsize=${width}px:*`;
};

const getPropertyBrandImageUrlParams = (width: number, height: number) => {
  return `?output-quality=${PROPERTY_IMAGE_OUTPUT_QUALITY}&interpolation=${PROPERTY_IMAGE_OUTPUT_INTERPOLATION}&crop=${
    width * PROPERTY_IMAGE_CROPPING_RATIO
  }:${height * PROPERTY_IMAGE_CROPPING_RATIO};*,*&downsize=${width}px:*`;
};

const getPropertyImagesByRendition = (
  edges: Node<MediaContent>[],
  rendition: keyof typeof PROPERTY_IMAGE_RENDITION,
  width: number
) => {
  const images: PropertyImage[] = [];
  const imageUrlRendition = PROPERTY_IMAGE_RENDITION[rendition] as keyof ProductImage;

  edges.forEach((edge: Node<MediaContent>) => {
    const {
      node: { imageUrls, title, alternateDescription },
    } = edge;

    const selectedImageByRendition = Object.keys(imageUrls).filter(
      imageType => imageType.includes(rendition) && imageUrls[imageUrlRendition]
    );

    if (selectedImageByRendition.length) {
      const assetDomain =
        imageUrls[imageUrlRendition]?.startsWith('http://') || imageUrls[imageUrlRendition]?.startsWith('https://')
          ? ''
          : ASSET_DOMAIN;
      images.push({
        defaultImageUrl: `${assetDomain}${imageUrls[imageUrlRendition]}${getPropertyImageUrlParams(width)}`,
        title: title || '',
        altText: alternateDescription || '',
      });
    }
  });

  return images;
};

export const getPropertyImages = (
  imageType: PropertyImageType,
  primaryImage: PrimaryImage,
  photoGallery: GalleryContent | undefined = undefined,
  brandImage: BrandContent | undefined = undefined
) => {
  const { width, rendition, fallbackImageUrl, height } = getPropertyImageConfig(imageType);

  const fallbackImage: PropertyImage = {
    defaultImageUrl: `${fallbackImageUrl}${getPropertyImageUrlParams(width)}`,
    title: PROPERTY_DEFAULT_IMAGE_URL.altText,
    altText: PROPERTY_DEFAULT_IMAGE_URL.altText,
  };

  if (!primaryImage && !photoGallery && !brandImage) return [fallbackImage];

  const propertyImages: PropertyImage[] = [];

  if (primaryImage?.edges?.length) {
    const images = getPropertyImagesByRendition(primaryImage.edges, rendition as Rendition, width);
    propertyImages.push(...images);
  }

  if (photoGallery && Object.keys(photoGallery).length) {
    for (const categoryKey in photoGallery) {
      const category = photoGallery[categoryKey as keyof GalleryContent];
      if (category && categoryKey !== '__typename') {
        const images = getPropertyImagesByRendition(category.edges, rendition as Rendition, width);
        propertyImages.push(...images);
      }
    }
  }

  if (!propertyImages.length && brandImage) {
    propertyImages.push({
      defaultImageUrl: `${brandImage?.url}${getPropertyBrandImageUrlParams(width, height)}`,
      altText: brandImage?.alternateText || brandImage?.name,
      title: brandImage?.name,
    });
  }

  if (propertyImages.length === 0) {
    return [fallbackImage];
  } else if (propertyImages.length === 1) {
    return propertyImages;
  } else if (!(photoGallery && Object.keys(photoGallery).length)) {
    return [propertyImages[0]]; //to make sure that we always return a single node image array on initial render
  } else {
    const uniquePropertyImages = getUniquePropertyImages(propertyImages, 'defaultImageUrl');
    return uniquePropertyImages.length > PROPERTY_IMAGE_CAROUSEL_LENGTH
      ? uniquePropertyImages.slice(0, PROPERTY_IMAGE_CAROUSEL_LENGTH)
      : uniquePropertyImages;
  }
};

export const getImageType = (
  viewType: ViewType,
  isDesktop: boolean | null,
  isTablet: boolean | null,
  isMini?: boolean
) => {
  if (isMini) {
    return PropertyImageType.MINI;
  } else if (viewType === ViewType.MAP_VIEW) {
    if (isDesktop) {
      return PropertyImageType.REGULAR_MOBILE;
    } else {
      return PropertyImageType.REGULAR_TABLET;
    }
  } else if (viewType === ViewType.QUICK_VIEW) {
    if (isTablet) {
      return PropertyImageType.LARGE_DESKTOP;
    } else {
      return PropertyImageType.REGULAR_MOBILE;
    }
  } else {
    if (isDesktop) {
      return PropertyImageType.REGULAR_DESKTOP;
    } else {
      return PropertyImageType.REGULAR_TABLET;
    }
  }
};
