import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, shadows, font, border } = baseVariables;

export const StyledDatePickerMobile = styled.div`
  .DayPicker-tooltip-value {
    display: none;
  }
`;

export const StyledFlexibleDatesField = styled.div`
  display: flex;
  align-items: center;
  padding: ${toRem(8)} 0 ${toRem(8)} ${toRem(16)};
  box-shadow: 0 0 ${toRem(8)} 0 ${shadows.boxShadowTile};
  background: ${color.base20};
  position: fixed;
  bottom: ${toRem(104)};
  left: 0;
  width: 100%;
  z-index: 50;
  border-bottom: ${border.borderWidth02} ${border.borderStyleDefault} ${color.neutral30};

  .flexible-dates {
    .flexible-dates-label {
      font-size: ${font.fontS};
      letter-spacing: 0;
      line-height: ${toRem(20)};
    }
  }
`;
