import React, { FC, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { baseVariables } from '@marriott/mi-ui-library';
import type { MapViewProps, Marker } from './MapView.types';
import { StyledMapView } from './MapView.styles';
import { MapComponent, MapMarker, MapInfoWindow } from '@marriott/mi-ui-library';
import { PropertyCard } from '../../organisms/SearchResults/PropertyCard';
import { Property } from '@marriott/mi-groups-graphql';
import { useMediaQuery } from '../../hooks';

export const MapView: FC<MapViewProps> = ({
  center,
  height,
  propertyLabels,
  properties,
  children,
  ctas,
  activePropertyId,
  onPropertyClick,
}) => {
  const apiKey = process.env['GOOGLE_MAP_API_KEY'] ?? '';
  const mapId = process.env['GOOGLE_MAP_ID'] ?? '';
  const { color } = baseVariables;

  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);

  const markers = useMemo(
    () =>
      properties.map(property => ({
        id: property.id,
        name: property.basicInformation?.name || '',
        lat: property.basicInformation?.latitude ? +property.basicInformation.latitude : 0,
        lng: property.basicInformation?.longitude ? +property.basicInformation.longitude : 0,
        isActive: false,
      })),
    [properties]
  );

  const [mapMarkers, setMapMarkers] = useState<Marker[]>(markers);
  const [mapCenter, setMapCenter] = useState(center);
  const [selectedProperty, setSelectedProperty] = useState<Property | undefined>(undefined);

  const mapRef = useRef<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    mapIds: [mapId],
  });

  const setFitBound = useCallback(
    (map?: google.maps.Map) => {
      const mapInstance = map || mapRef.current;
      if (mapInstance) {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(marker => {
          bounds.extend({ lat: marker.lat, lng: marker.lng });
        });
        mapInstance?.fitBounds(bounds);
      }
    },
    [markers]
  );

  const showPropertyMiniCard = useCallback(
    (marker: Marker) => {
      const updatedMarkers = mapMarkers.map(mapMarker => {
        return {
          ...mapMarker,
          showDetails: isDesktop ? mapMarker.id === marker.id : false,
          isActive: mapMarker.id === marker.id,
        };
      });
      setMapMarkers(updatedMarkers);
      const propertyDetails = properties.find(property => property.id === marker.id);
      setSelectedProperty(propertyDetails);
      if (!isDesktop) {
        setMapCenter({ lat: marker.lat, lng: marker.lng });
      }
      onPropertyClick?.(marker.id);
    },
    [isDesktop, mapMarkers, properties, onPropertyClick]
  );

  const hidePropertyMiniCard = () => {
    const updatedMarkers = mapMarkers.map(mapMarker => ({
      ...mapMarker,
      showDetails: false,
      isActive: false,
    }));
    setMapMarkers(updatedMarkers);
    setSelectedProperty(undefined);
    onPropertyClick?.('');
  };

  useEffect(() => {
    setMapMarkers(markers);
    setFitBound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFitBound]);

  useEffect(() => {
    setMapCenter(center);
  }, [center]);

  useEffect(() => {
    const marker = mapMarkers.find(marker => marker.id === activePropertyId);
    if (marker) {
      showPropertyMiniCard(marker);
    } else {
      hidePropertyMiniCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePropertyId]);

  const mapOptions = {
    mapId,
    fullscreenControl: false,
    zoomControl: isDesktop,
    streetViewControl: isDesktop,
    gestureHandling: 'greedy',
    mapTypeControl: false,
  };

  const onMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setFitBound(map);
  };

  const markersEl = document.querySelectorAll('.map-marker');

  return (
    <StyledMapView data-component-name="m-groups-MapView" data-testid="groups-MapView">
      {isLoaded && (
        <MapComponent
          googleMapsApiKey={apiKey}
          mapId={mapId}
          mapHeight={height}
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={mapCenter}
          options={mapOptions}
          onLoad={onMapLoad}
          onClick={hidePropertyMiniCard}
          children={
            <>
              <MapMarker position={{ lat: center.lat, lng: center.lng }} />
              {mapMarkers?.map((marker: Marker, index) => (
                <div>
                  {marker.showDetails && propertyLabels && ctas && selectedProperty ? (
                    <MapInfoWindow
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onCloseClick={hidePropertyMiniCard}
                      children={
                        <PropertyCard
                          key={marker.id}
                          propertyData={selectedProperty}
                          propertyLabels={propertyLabels}
                          ctas={ctas}
                          variant="mini"
                        />
                      }
                    ></MapInfoWindow>
                  ) : (
                    <MapMarker
                      icon={{
                        url: '',
                        scaledSize: new google.maps.Size(
                          markersEl[index]?.getBoundingClientRect()?.width || 180,
                          markersEl[index]?.getBoundingClientRect()?.height || 50
                        ),
                      }}
                      label={{
                        text: marker.name?.toString(),
                        color: marker.isActive ? color.base10 : color.base20,
                        className: `m-map-pin map-marker ${marker.isActive ? 'active' : ''} `,
                      }}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() => showPropertyMiniCard(marker)}
                    />
                  )}
                </div>
              ))}
              {children}
            </>
          }
        ></MapComponent>
      )}
    </StyledMapView>
  );
};
