import React, { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Icon, RadiobuttonControlsList } from '@marriott/mi-ui-library';
import { SingleSelectFlyoutProps } from './SingleSelectFlyout.types';
import { StyledSingleSelectFlyout, StyledSingleSelectFlyoutOptions } from './SingleSelectFlyout.styles';
import { ControlOptionProps } from '../SingleSelectList/SingleSelectList.types';
import { useFocusOut } from '../../hooks';

export const SingleSelectFlyout: FC<SingleSelectFlyoutProps> = ({
  variant = 'regular',
  name,
  label,
  ariaLabel,
  options,
  selected,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ControlOptionProps[]>([]);
  const flyoutRef = useRef<HTMLDivElement>(null);
  const caretRef = useRef<HTMLButtonElement>(null);

  useFocusOut({
    ref: flyoutRef.current,
    onFocusOut: (isFocusOut: boolean) => {
      if (isFocusOut) {
        setIsOpen(false);
      }
    },
    handleKeyup: true,
  });

  useEffect(() => {
    if (selected && selected.trim() !== '') {
      const foundOption = options.find(option => option.code === selected);
      foundOption ? setSelectedOption([foundOption]) : setSelectedOption([options[0]]);
    }
  }, [selected, options]);

  const handleChange = (option: ControlOptionProps[]) => {
    setSelectedOption(option);
    onChange(option[0]);
    setIsOpen(false);
  };

  return (
    <StyledSingleSelectFlyout
      data-component-name="m-groups-SingleSelectFlyout"
      data-testid="groups-SingleSelectFlyout"
      ref={flyoutRef}
    >
      <div className={clsx('m-custom-select-block align-right', variant === 'inverse' && 'inverse')}>
        <span className="t-font-weight-m t-font-m">{label}:</span>
        <button
          type="button"
          ref={caretRef}
          className={'t-font-weight-m t-font-m'}
          name={name}
          onClick={() => setIsOpen(!isOpen)}
          aria-label={`${ariaLabel} ${selectedOption?.[0]?.label} selected`}
          aria-expanded={isOpen}
        >
          {selectedOption?.[0]?.label}
          <Icon iconClass={isOpen ? 'icon-arrow-up' : 'icon-arrow-down'} ariaLabel="dropdown-icon" />
        </button>
      </div>
      <StyledSingleSelectFlyoutOptions tabIndex={-1} className={clsx(isOpen ? '' : 'd-none', 'pl-2', 'pb-4')}>
        <RadiobuttonControlsList
          name={name}
          controls={options}
          enableShowMoreToggle={false}
          colLength={12}
          defaultSelected={selectedOption}
          tabIndexForInput={-1}
          onChange={handleChange}
        />
      </StyledSingleSelectFlyoutOptions>
    </StyledSingleSelectFlyout>
  );
};
