import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { mediaQuery } = baseVariables;
const drawerWidth = toRem(398);

export const StyledPropertyMap = styled.div`
  position: relative;
  .card-panel {
    width: 100vw;
    position: absolute;
  }
  .drawer {
    z-index: 50;
    .rooms-guests {
      margin: ${toRem(16)} 0 !important;
    }
  }
  .drawer-placement-left {
    .button-placement-top {
      border-radius: ${toRem(30)} 0 0 ${toRem(30)};
      top: ${toRem(80)};
      &.open {
        right: 0;
      }
      &.close {
        border-radius: 0 ${toRem(30)} ${toRem(30)} 0;
      }
    }
  }
  .map-view {
    width: calc(100% - ${drawerWidth});
  }

  @media ${mediaQuery.md} {
    .card-panel {
      width: ${drawerWidth};
      position: static;
      .mobile-container {
        position: absolute;
      }
      .dropdown-container {
        width: 100%;
      }
    }
    .drawer-placement-left {
      &.open {
        width: ${drawerWidth};
      }
      .button-placement-top {
        border-radius: 0 ${toRem(30)} ${toRem(30)} 0;
        top: ${toRem(184)};
        &.open {
          right: ${toRem(-36)};
        }
        &.close {
          right: ${toRem(-32)};
        }
      }
    }
  }
`;
