import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { font, color, mediaQuery, shadows, border } = baseVariables;

export const StyledDatePickerDesktop = styled.div<{ align?: string }>`
  position: relative;

  .m-dropdown-container {
    padding: 0 ${toRem(8)} ${toRem(24)} ${toRem(8)};
    position: absolute;
    z-index: 10;
    top: ${toRem(-16)};
    right: ${toRem(-10)};
    width: ${toRem(360)};
    box-shadow: ${shadows.shadowBlur01};
    border: ${border.borderWidth01} ${border.borderStyleDefault} ${color.neutral30} !important;
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};

    @media ${mediaQuery.md} {
      ${props => `${props.align === 'right' ? 'right' : 'left'}: ${toRem(-10)};`};
    }

    @media ${mediaQuery.lg} {
      padding-top: ${toRem(8)};
      width: ${toRem(766)};
    }

    .m-dropdown-content {
      max-height: none;
    }
  }

  .reset-cta {
    background: transparent;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }

    &.text-right {
      display: block;
      margin-right: ${toRem(16)};
      margin-left: auto;
    }
  }

  .datepicker-footer {
    border-top: ${border.borderWidth01} ${border.borderStyleDefault} ${color.neutral30};

    .flexible-dates-label {
      font-size: ${font.fontS};
      letter-spacing: 0;
      line-height: ${toRem(20)};
    }

    .flexible-dates {
      .col-12 {
        padding: 0;
      }
    }

    .w-100 {
      width: 100%;
    }
  }

  .m-input-field {
    &.focus-input {
      input {
        border-color: ${color.base10};
        box-shadow: 0 0 0 ${toRem(0.5)} inset;
      }
    }
  }
`;
