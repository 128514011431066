import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { font } = baseVariables;

export const StyledAllFiltersModal = styled.div`
  .brand-filter__title {
    margin-top: 0 !important;
  }
  .item-name.checkbox-radio {
    line-height: ${font.lineHeightM};
  }
  .show-more-btn {
    font-size: ${toRem(13)};
    line-height: ${font.lineHeightXs};
    font-weight: ${font.fontWeightMedium};
  }
  .filter-separator {
    margin-bottom: 0 !important;
    margin-top: ${toRem(16)} !important;
  }
`;
