import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Types, Icon } from '@marriott/mi-ui-library';
import { ExpandedFormProps, TextFieldRenderProps } from './GroupSearchExpandedFormMobile.types';
import {
  StyledExpandedFormContainer,
  StyledHeader,
  StyledFooter,
  StyledExpandedForm,
} from './GroupSearchExpandedFormMobile.styles';
import { SearchFormField } from '../GroupSearchFormMobile.types';
import { GroupSearchFormData } from '../../GroupSearchForm.types';
import { useFocusState } from '../../../../hooks';
import { useSearchFromStore } from '../../../../store';
import { TextFormField } from '../../../../molecules';
import {
  getDatesLabel,
  getFormattedDateObject,
  isValidNumeric,
  validateAttendees,
  validateGuestRooms,
} from '../../../../utils';

export const GroupSearchExpandedFormMobile: FC<ExpandedFormProps> = ({ expandedFormLabels, onBack, onSubmit }) => {
  const { destination, dates, eventType, guestRooms, attendees, ctas } = expandedFormLabels;

  const { setCurrentStep, formData, setFormData, persistedFormData, setPersistedFormData, loader, setLoader } =
    useSearchFromStore();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm<GroupSearchFormData>({
    defaultValues: {
      destination: persistedFormData.destination || { description: '', placeId: '' },
      dates: persistedFormData.dates || '',
      eventType: persistedFormData.eventType || { label: '', code: '' },
      startDate: persistedFormData.startDate || '',
      endDate: persistedFormData.endDate || '',
      flexibleDates: persistedFormData.flexibleDates,
      attendees: persistedFormData.attendees || '',
      guestRooms: persistedFormData.guestRooms || '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { handleFocus, handleBlur } = useFocusState({});

  const submitCallback = (data: GroupSearchFormData) => {
    setLoader(true);
    onSubmit(data);
  };

  const handleTextFieldChange = (value: string, field: TextFieldRenderProps) => {
    setPersistedFormData({ ...persistedFormData, [field.name]: value });
    setFormData({ ...formData, [field.name]: value });
    field.onChange(value);
  };

  const destinationField = (
    <Controller
      name="destination"
      control={control}
      rules={{
        required: destination.requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={destination.label}
          placeholder={destination.mobilePlaceholder}
          ariaLabel={destination.ariaLabel}
          className="m-input-field"
          value={persistedFormData.destination.description || ''}
          getInputProps={() => ({
            readOnly: true,
          })}
          showErrorMessage={!!errors.destination?.message}
          errorMessage={errors.destination?.message}
          onClick={() => setCurrentStep(SearchFormField.DESTINATION)}
        />
      )}
    />
  );

  const datesFieldLabel =
    persistedFormData.startDate && persistedFormData.endDate
      ? getDatesLabel(
          getFormattedDateObject(persistedFormData.startDate, 'dateWithDayMonthAndYear'),
          getFormattedDateObject(persistedFormData.endDate, 'dateWithDayMonthAndYear'),
          dates.label,
          dates.night,
          dates.nights
        )
      : dates.label;

  const datesField = (
    <TextFormField
      label={datesFieldLabel}
      placeholder={dates.mobilePlaceholder}
      ariaLabel={dates.ariaLabel}
      className="m-input-field"
      value={persistedFormData.dates || ''}
      getInputProps={() => ({
        readOnly: true,
      })}
      onClick={() => setCurrentStep(SearchFormField.DATES)}
    />
  );

  const eventTypeField = (
    <TextFormField
      label={eventType.label}
      ariaLabel={eventType.ariaLabel}
      placeholder={eventType.selectEventType}
      className="m-input-field"
      value={persistedFormData.eventType.label || ''}
      showIcon={true}
      iconClass={'icon-s icon-arrow-down'}
      iconClickHandler={() => setCurrentStep(SearchFormField.EVENT_TYPE)}
      getInputProps={() => ({
        readOnly: true,
      })}
      onClick={() => setCurrentStep(SearchFormField.EVENT_TYPE)}
    />
  );

  const guestRoomsField = (
    <Controller
      name="guestRooms"
      control={control}
      rules={{
        validate: value =>
          validateGuestRooms(value, getValues('attendees'), guestRooms.requiredError, guestRooms.minCountError) || true,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={guestRooms.label}
          placeholder={guestRooms.placeholder}
          ariaLabel={guestRooms.ariaLabel}
          value={persistedFormData.guestRooms}
          className="m-input-field"
          showIcon={!!field.value}
          iconClass={'icon icon-s icon-cancel'}
          iconAriaLabel={`${ctas.clearLabel} ${guestRooms.label}`}
          iconClickHandler={() => {
            handleTextFieldChange('', field);
            setPersistedFormData({ ...persistedFormData, guestRooms: '' });
            clearErrors('guestRooms');
          }}
          showErrorMessage={!!errors.guestRooms?.message}
          errorMessage={errors.guestRooms?.message}
          onChange={event => {
            isValidNumeric(event.target.value) && handleTextFieldChange(event.target.value, field);
            clearErrors('guestRooms');
          }}
          onFocus={() => handleFocus(field.name)}
          onBlur={() => handleBlur(field.name)}
        />
      )}
    />
  );

  const attendeesField = (
    <Controller
      name="attendees"
      control={control}
      rules={{
        validate: value => validateAttendees(value, getValues('guestRooms'), attendees.requiredError) || true,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={attendees.label}
          placeholder={attendees.placeholder}
          ariaLabel={attendees.ariaLabel}
          value={persistedFormData.attendees}
          className="m-input-field"
          showHelperText={!!field.value}
          helperText={`${attendees.helperText}: ${Math.floor(+(field?.value || 0) * attendees.spaceMultiplier)}`}
          showIcon={!!field.value}
          iconClass={'icon icon-s icon-cancel'}
          iconAriaLabel={`${ctas.clearLabel} ${attendees.label}`}
          iconClickHandler={() => {
            handleTextFieldChange('', field);
            setPersistedFormData({ ...persistedFormData, attendees: '' });
            clearErrors('attendees');
          }}
          showErrorMessage={!!errors.attendees?.message}
          errorMessage={errors.attendees?.message}
          onChange={event => {
            isValidNumeric(event.target.value) && handleTextFieldChange(event.target.value, field);
            clearErrors('attendees');
          }}
          onFocus={() => handleFocus(field.name)}
          onBlur={() => handleBlur(field.name)}
        />
      )}
    />
  );

  return (
    <StyledExpandedFormContainer>
      <StyledHeader>
        <Button className="ml-3" callback={onBack} testId="back-cta">
          <Icon iconClass="icon-m icon-back-arrow d-flex" />
        </Button>
      </StyledHeader>
      <StyledExpandedForm onSubmit={handleSubmit(submitCallback)}>
        <div className="row">
          <div className="col-12 my-3">{destinationField}</div>
        </div>
        <div className="row">
          <div className="col-12 my-3"> {datesField}</div>
        </div>
        <div className="row">
          <div className="col-12 my-3">{eventTypeField}</div>
        </div>
        <div className="row">
          <div className="col-6 my-3">{guestRoomsField}</div>
          <div className="col-6 my-3">{attendeesField}</div>
        </div>
        <StyledFooter>
          <Button
            type={Types.ButtonTypeVariation.Submit}
            ariaLabel={ctas.findAriaLabel}
            className={`m-button-primary w-100 ${loader ? `m-button skeleton-loader` : ''}`}
            isDisabled={loader}
          >
            {ctas.find}
          </Button>
        </StyledFooter>
      </StyledExpandedForm>
    </StyledExpandedFormContainer>
  );
};
