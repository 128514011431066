import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { spacers, mediaQuery } = baseVariables;

export const StyledPropertyList = styled.div`
  padding: ${toRem(4)} 0 ${toRem(24)} 0;
  .compact-card-list {
    padding-right: ${spacers.spacer};
    padding-left: ${spacers.spacer};
    @media ${mediaQuery.md} {
      padding-right: ${spacers.spacers?.[4]};
      padding-left: ${spacers.spacers?.[4]};
    }
  }
`;
