import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, font, mediaQuery, spacers, border } = baseVariables;

export const StyledDefaultFlyout = styled.div`
  position: relative;
  height: 100%;
  @media ${mediaQuery.md} {
    margin: ${toRem(14)} 0 ${toRem(16)} 0;
    height: auto;
  }

  .separator {
    box-sizing: border-box;
    height: ${toRem(1)};
    border: ${toRem(1)} solid ${color.alert20};
    margin-left: ${toRem(16)};
    margin-right: ${toRem(16)};
  }

  .t-label-alt-s {
    line-height: ${toRem(20)};
    font-weight: ${font.fontWeightRegular};
  }

  .main {
    height: calc(100% - ${toRem(57)});
    @media ${baseVariables.mediaQuery.md} {
      max-height: ${toRem(309)};
      padding: inherit;
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: ${toRem(18)};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${color.neutral20};
        border-radius: ${toRem(10)};
        border: ${toRem(5)} solid ${color.base20};
      }
      ::-webkit-scrollbar-track {
        background-color: ${color.base20};
        margin: ${toRem(10)} 0;
      }
    }
  }

  .footer {
    padding: ${spacers.spacers?.[3]};
    background-color: ${color.base20};
    box-shadow: 0 0 ${toRem(8)} 0 rgba(0, 0, 0, 0.07);
    border-bottom: ${border.borderWidth02} ${border.borderStyleDefault} ${color.neutral30};
    z-index: 1;
    position: relative;
    @media ${baseVariables.mediaQuery.md} {
      padding: unset;
      box-shadow: unset;
      border-bottom: none;
    }
  }
  .clear-recent-cta {
    @media ${baseVariables.mediaQuery.md} {
      margin-left: ${spacers.spacers?.[3]};
    }
    background: transparent;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
  }
`;
