import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, shadows } = baseVariables;

export const StyledDatePickerMobileContainer = styled.div`
  .mobile-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    grid-template-rows: ${toRem(108)} 1fr ${toRem(104)};
    display: grid;
    height: 100%;
    background: ${color.base20};
    &-header {
      padding: ${toRem(48)} ${toRem(16)} ${toRem(12)};
      background-color: ${color.base10};
      .cancel-cta {
        background: transparent;
        &:focus-visible {
          outline: -webkit-focus-ring-color auto ${toRem(1)};
        }
      }
    }
    &-body {
      overflow-x: auto;
    }
    &-footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      box-shadow: 0 0 ${toRem(8)} 0 ${shadows.boxShadowTile};
      .w-100 {
        width: 100%;
      }
    }
  }
`;
