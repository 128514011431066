import { EventSpaceGalleryLabels } from './EventSpaceGallery/EventSpaceGallery.types';
import { EventType, OptionType } from '../../molecules/EventType/EventType.types';
import { EventSpaceDetailsLabels } from './EventSpaceDetails/EventSpaceDetails.types';
import { AdditionalInfo } from '../../molecules/AdditionalInfo/AdditionalInfo.types';
import { EstimatedTotal } from '../../molecules/EstimatedTotal/EstimatedTotal.types';
import { SittingArrangement } from '@marriott/mi-groups-graphql';

export type EventSpacesFormData = {
  eventType: OptionType;
  eventSpaces: EventSpaceInfo[];
  totalAmount: number;
  additionalInfo: string;
};

export type EventSpaceInfo = {
  date: string;
  time: [string, string];
  attendees: string;
  rate: string;
  currency: string;
  configuration: SittingArrangement | string;
  removed: boolean;
  applyAll: string;
};

export type EventSpacesProps = EventSpaceDetailsLabels & {
  eventSpaceGallary: EventSpaceGalleryLabels;
  eventType: EventType;
  estimatedTotal: EstimatedTotal;
  additionalInfo: AdditionalInfo;
  skipBooking: string;
  ctas: {
    continueLabel: string;
    continueAriaLabel: string;
    continueUrl: string;
    submitInquiryLabel: string;
    submitInquiryAriaLabel: string;
    submitInquiryUrl: string;
  };
  apiError: string;
  invalidAccessPageUrl: string;
  errorPageUrl: string;
};

export const eventSpaceConfigurationMap: Record<string, string> = {
  CO: 'CONFERENCE',
  CR: 'CRESCENT ROUNDS',
  EX: 'EXHIBITS',
  HS: 'HOLLOW SQUARE',
  RL: 'LOUNGE',
  RO: 'ROUNDS OF 10',
  SC: 'SCHOOLROOM',
  TH: 'THEATRE',
  US: 'U-SHAPE',
};
