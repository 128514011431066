export type ContactNumberListProps = {
  contactNumbers: ContactNo[];
  tollFreeCta?: {
    tollFreeLabel: string;
    tollFreeAriaLabel: string;
    tollFreeUrl: string;
  };
};

export type ContactNo = {
  label: string;
  ariaLabel?: string;
  number: string;
};

export enum ContactType {
  RESERVATION = 'reservation',
  FAX = 'fax',
  PHONE = 'phone',
  SALES_FAX = 'sales fax',
  SALES = 'sales',
}
