import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledCurrentLocation = styled.div`
  .t-label-alt-s {
    padding: ${toRem(8)} ${toRem(16)};

    &:hover {
      background-color: ${color.neutral20};
    }
  }

  a {
    display: block;
    text-decoration: none;
  }
`;
