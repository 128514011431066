import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Button, StepperTextBlock, baseVariables } from '@marriott/mi-ui-library';
import { ENTER_KEY } from '../../constants';
import { useFocusOut, useMediaQuery } from '../../hooks';
import { TextFormField } from '../index';
import { RoomsAndGuestsProps, RoomsAndGuestsCount } from './RoomsAndGuests.types';
import { StyledRoomsAndGuests } from './RoomsAndGuests.styles';

export const RoomsAndGuests = React.forwardRef<HTMLInputElement, RoomsAndGuestsProps>((props, ref) => {
  const { labels, ariaLabel, showErrorMessage = false, errorMessage = '', onChange } = props;
  const { roomLabel, roomsLabel, adultLabel, adultsLabel, childLabel, childrenLabel } = labels;

  const roomsAndGuestsElRef = useRef<HTMLInputElement>(null);
  const prevRoomsAndGuestsValueRef = useRef<RoomsAndGuestsCount>();

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const [isOpen, setIsOpen] = useState(false);
  const [roomsAndGuests, setRoomsAndGuests] = useState<RoomsAndGuestsCount>({
    rooms: labels.minimumRoom,
    adults: labels.minimumAdults,
    children: labels.minimumChildren,
    childrenAge: [],
  });

  useEffect(() => {
    onChange(roomsAndGuests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsAndGuests]);

  useFocusOut({
    ref: roomsAndGuestsElRef.current,
    onFocusOut: isFocusOut => {
      if (isFocusOut) {
        setIsOpen(false);
      }
    },
    handleKeyup: true,
  });

  const formatRoomsAndGuestsText = (count: number, singularLabel: string, pluralLabel: string) =>
    (count > 1 ? pluralLabel : singularLabel).replace('{xx}', count.toString());

  const getRoomsAndGuestsText = (): string => {
    const { rooms, adults, children } = roomsAndGuests;
    const roomText = formatRoomsAndGuestsText(rooms, roomLabel, roomsLabel);
    const adultText = formatRoomsAndGuestsText(adults, adultLabel, adultsLabel);
    let result = `${roomText}: ${adultText}`;
    if (children > 0) {
      const childText = formatRoomsAndGuestsText(children, childLabel, childrenLabel);
      result += `, ${childText}`;
    }
    return result;
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      setIsOpen(!isOpen);
    }
  };

  const handleRoomsChange = (type: string) => {
    setRoomsAndGuests(prevRoomsAndGuests => ({
      ...prevRoomsAndGuests,
      rooms: type === 'inc' ? prevRoomsAndGuests.rooms + 1 : prevRoomsAndGuests.rooms - 1,
    }));
  };

  const handleAdultsChange = (type: string) => {
    setRoomsAndGuests(prevRoomsAndGuests => ({
      ...prevRoomsAndGuests,
      adults: type === 'inc' ? prevRoomsAndGuests.adults + 1 : prevRoomsAndGuests.adults - 1,
    }));
  };

  const handleChildrenChange = (type: string) => {
    setRoomsAndGuests(prevRoomsAndGuests => {
      const children = type === 'inc' ? prevRoomsAndGuests.children + 1 : prevRoomsAndGuests.children - 1;
      const childrenAge = [...prevRoomsAndGuests.childrenAge];
      type === 'inc' ? childrenAge.push(labels.age) : childrenAge.pop();

      return {
        ...prevRoomsAndGuests,
        children,
        childrenAge,
      };
    });
  };

  const handleAgeChange = (type: string, index: number) => {
    setRoomsAndGuests((prevRoomsAndGuests: RoomsAndGuestsCount) => {
      const childrenAge = [...prevRoomsAndGuests.childrenAge];
      childrenAge[index] =
        type === 'inc'
          ? childrenAge[index] === labels.age
            ? `<${labels.minimumAge}`
            : childrenAge[index] === `<${labels.minimumAge}`
            ? 1
            : +childrenAge[index] + 1
          : +childrenAge[index] > 1
          ? +childrenAge[index] - 1
          : `<${labels.minimumAge}`;

      return {
        ...prevRoomsAndGuests,
        childrenAge,
      };
    });
  };

  const handleReset = () => {
    setRoomsAndGuests({
      rooms: labels.minimumRoom,
      adults: labels.minimumAdults,
      children: labels.minimumChildren,
      childrenAge: [],
    });
  };

  const handleCancel = () => {
    if (prevRoomsAndGuestsValueRef.current) {
      setRoomsAndGuests(prevRoomsAndGuestsValueRef.current);
    }
    setIsOpen(false);
  };

  const handleApply = () => {
    setIsOpen(false);
  };

  return (
    <StyledRoomsAndGuests
      data-component-name="m-groups-RoomsAndGuests"
      data-testid="groups-RoomsAndGuests"
      ref={roomsAndGuestsElRef}
    >
      <TextFormField
        type="text"
        className="m-input-field"
        label={labels.roomsAndGuests}
        ariaLabel={ariaLabel}
        value={getRoomsAndGuestsText()}
        ref={ref}
        hideCursor={true}
        showIcon={true}
        iconClass={clsx('icon-s', !isOpen ? 'icon-arrow-down' : 'icon-arrow-up')}
        iconAriaLabel={!isOpen ? labels.expandIconAriaLabel : labels.collapseIconAriaLabel}
        isIconClickable={true}
        iconClickHandler={() => {
          prevRoomsAndGuestsValueRef.current = { ...roomsAndGuests };
          setIsOpen(!isOpen);
        }}
        showErrorMessage={showErrorMessage}
        errorMessage={errorMessage}
        onFocus={() => {
          prevRoomsAndGuestsValueRef.current = { ...roomsAndGuests };
          setIsOpen(true);
        }}
        onClick={() => {
          prevRoomsAndGuestsValueRef.current = { ...roomsAndGuests };
          setIsOpen(true);
        }}
        onKeyDown={handleKeydown}
      />

      {isOpen ? (
        <div
          className={clsx(isTabletAndAbove ? 'm-dropdown-container dropdown-container' : 'mobile-container')}
          data-testid="rooms-guests-flyout"
        >
          {!isTabletAndAbove ? (
            <div className="mobile-container-header">
              <div className="t-label-inverse-s">{labels.roomsAndGuests}</div>
              <div className="d-flex justify-content-between mt-2">
                <div className="t-font-inverse-m">{getRoomsAndGuestsText()}</div>
                <Button
                  buttonCopy={labels.cancel}
                  ariaLabel={labels.cancelAriaLabel}
                  className="t-label-inverse-s p-0 clear-cta"
                  callback={handleCancel}
                />
              </div>
            </div>
          ) : null}

          <div
            className={clsx(
              'py-3 custom-scrollbar',
              isTabletAndAbove ? 'm-dropdown-content' : 'px-3 mobile-container-body'
            )}
          >
            <div className="stepper-container">
              <StepperTextBlock
                copyText={labels.rooms}
                subHeadingText={labels.maxRooms.replace('{x}', labels.maxRoomsCount.toString())}
                stepperValue={roomsAndGuests.rooms}
                handleIncrement={() => handleRoomsChange('inc')}
                handleDecrement={() => handleRoomsChange('dec')}
                disableIncrement={roomsAndGuests.rooms === labels.maxRoomsCount}
                disableDecrement={roomsAndGuests.rooms === labels.minimumRoom}
                increamentLabel={`${labels.increase} ${labels.room}`}
                decreamentLabel={`${labels.decrease} ${labels.room}`}
              />
            </div>

            <div className="stepper-container">
              <StepperTextBlock
                copyText={labels.adults}
                subHeadingText={labels.maxGuestsPerRoom.replace('{x}', labels.maxGuestsPerRoomCount.toString())}
                stepperValue={roomsAndGuests.adults}
                handleIncrement={() => handleAdultsChange('inc')}
                handleDecrement={() => handleAdultsChange('dec')}
                disableIncrement={roomsAndGuests.adults + roomsAndGuests.children === labels.maxGuestsPerRoomCount}
                disableDecrement={roomsAndGuests.adults === labels.minimumAdults}
                increamentLabel={`${labels.increase} ${labels.adults}`}
                decreamentLabel={`${labels.decrease} ${labels.adults}`}
              />
            </div>

            <div className="stepper-container">
              <StepperTextBlock
                copyText={labels.children}
                subHeadingText={labels.maxGuestsPerRoom.replace('{x}', labels.maxGuestsPerRoomCount.toString())}
                stepperValue={roomsAndGuests.children}
                handleIncrement={() => handleChildrenChange('inc')}
                handleDecrement={() => handleChildrenChange('dec')}
                disableIncrement={roomsAndGuests.adults + roomsAndGuests.children === labels.maxGuestsPerRoomCount}
                disableDecrement={roomsAndGuests.children === labels.minimumChildren}
                increamentLabel={`${labels.increase} ${labels.children}`}
                decreamentLabel={`${labels.decrease} ${labels.children}`}
              />
            </div>

            {roomsAndGuests.children ? (
              <div className="mt-2">
                <span className="t-font-s">{labels.ageBasedRates}</span>
                {roomsAndGuests.childrenAge.map((age, index) => (
                  <div key={index} className="stepper-container">
                    <StepperTextBlock
                      copyText={`${labels.child} ${index + 1}: ${labels.age}`}
                      subHeadingText={labels.firstRoomOnly}
                      stepperValue={age}
                      handleIncrement={() => handleAgeChange('inc', index)}
                      handleDecrement={() => handleAgeChange('dec', index)}
                      disableIncrement={age === labels.maximumAge}
                      disableDecrement={age === `<${labels.minimumAge}` || age === labels.age}
                      increamentLabel={`${labels.increase} ${labels.child} ${index + 1} ${labels.age}`}
                      decreamentLabel={`${labels.decrease} ${labels.child} ${index + 1} ${labels.age}`}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <div className={clsx(isTabletAndAbove ? 'text-right pb-3 pr-3' : 'mobile-container-footer')}>
            <Button
              buttonCopy={labels.reset}
              ariaLabel={labels.resetAriaLabel}
              className={clsx('p-0 reset-cta', isTabletAndAbove ? '' : 'd-block text-center mx-auto my-3')}
              callback={handleReset}
            />

            {!isTabletAndAbove ? (
              <div className="px-3 py-5 apply-cta">
                <Button
                  buttonCopy={labels.apply}
                  ariaLabel={labels.applyAriaLabel}
                  className="m-button-primary d-block w-100"
                  callback={handleApply}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </StyledRoomsAndGuests>
  );
});
