export class CacheManager<T> {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  /**
   * set value in local storage
   * @param value
   *
   */
  setItem(value: T) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  /**
   * parse and return the value
   * @returns value
   */
  getItem(): T {
    const value = localStorage.getItem(this.key);
    return value ? JSON.parse(value) : [];
  }

  /**
   * gets details from local storage
   * calls updater callback function
   * set updated details in local storage
   *
   * @param callback
   * callback should return a value to be stored
   */
  updateItem(callback: (currentValue: T) => T) {
    const currentValue = this.getItem();
    if (currentValue) {
      const updatedValue = callback(currentValue);
      this.setItem(updatedValue);
    }
  }

  /**
   * clears local storage
   */
  removeItem() {
    localStorage.removeItem(this.key);
  }

  hasItem() {
    const value = localStorage.getItem(this.key);
    return value && value.length > 0;
  }

  getLatestItem() {
    const value = localStorage.getItem(this.key);
    if (!value) return {};
    const parsedValue = JSON.parse(value);
    return parsedValue[parsedValue.length - 1];
  }
}
