export type RfpTrayProps = {
  property: string;
  selectedProperties: string;
  optional: string;
  createRfpCta: string;
  targetUrl: string;
  undoProperty?: string;
  compare?: string;
  compareHotels?: CompareHotelLabels;
  showAriaLabel: string;
  hideAriaLabel: string;
  removeAriaLabel: string;
  isSearchResultPage: boolean;
  createRfpTrackLocation: string;
};

export type CompareHotelLabels = {
  title: string;
  carouselImageAriaLabel: string;
  carouselAriaLabel: string;
  logoAriaLabel: string;
  toAirport: string;
  reviews: string;
  meetingEventRooms: string;
  totalEventSpace: string;
  guestRooms: string;
  largestRoom: string;
  spaceUnit: string;
};

export enum RfpTrayView {
  HIDDEN,
  EXPANDED,
  COLLAPSED,
}

export type RfpSessionInput = {
  AriesGroupSearch: {
    groupPropertyRecordsCount: number;
    groupSearchCriteria: {
      address: {
        latitude: string;
        longitude: string;
        destination: string;
        destinationAddressMainText: string;
        city: string;
        stateProvince: string;
        country: string;
      };
      checkInDate: string;
      checkOutDate: string;
      isFlexibleDate: string;
      eventSearchType: string;
      guestRoomCount: number;
      sizeLargestMeetingRoom: number;
      roomsOnlySelected: boolean;
    };
    isGroupSearch: boolean;
    isQuickGroupEnabled: boolean;
    rfpShortlistedMarshaCodes: Record<string, string>;
    localeForERFP: string;
  };
  AriesCommon?: {
    memState: string;
  };
};
