export class SessionManager<T> {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  // Save data to sessionStorage
  setItem(value: T) {
    try {
      const serializedValue = JSON.stringify(value);
      sessionStorage.setItem(this.key, serializedValue);
    } catch (error) {
      console.error(`Error saving to sessionStorage: ${error}`);
    }
  }

  // Retrieve data from sessionStorage
  getItem<T>(): T | null {
    try {
      const serializedValue = sessionStorage.getItem(this.key);
      if (serializedValue === null) {
        return null;
      }
      return JSON.parse(serializedValue);
    } catch (error) {
      console.error(`Error retrieving from sessionStorage: ${error}`);
      return null;
    }
  }

  // Remove data from sessionStorage
  removeItem() {
    try {
      sessionStorage.removeItem(this.key);
    } catch (error) {
      console.error(`Error removing from sessionStorage: ${error}`);
    }
  }

  // Clear all data from sessionStorage
  clear() {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error(`Error clearing sessionStorage: ${error}`);
    }
  }
}
