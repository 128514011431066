import React, { FC, useState } from 'react';
import { CheckboxControlsList, baseVariables } from '@marriott/mi-ui-library';
import { FilterModal } from '../../../molecules/FilterModal';
import {
  AmenitiesFilterModalProps,
  CheckboxControlsListControlProp,
  PropertySearchTermFacetBucket,
} from './AmenitiesFilterModal.types';
import { StyledAmenitiesFilterModal } from './AmenitiesFilterModal.styles';
import { useMediaQuery } from '../../../hooks';

const codeIconMap: Record<string, string> = {
  'EV Charging': 'ev-charging-station',
  'Whirlpool in Room': 'pool',
  'Whirlpool on Site': 'pool',
  'new-lobby': 'check',
};

export const getAmenitiesCheckboxControlsList = (amenities: PropertySearchTermFacetBucket[]) => {
  return amenities.map(({ code, ...rest }) => ({
    ...rest,
    code,
    iconClass: `icon-${codeIconMap[code] ?? code ?? 'check'}`,
  }));
};

export const AmenitiesFilterModal: FC<AmenitiesFilterModalProps> = ({
  filterType,
  labels,
  amenities,
  defaultSelected = [],
  showModal,
  isLoading,
  onFilterChange,
  onApply,
  onClear,
  onCloseModal,
}) => {
  const checkboxControlsListControlProp: CheckboxControlsListControlProp[] =
    getAmenitiesCheckboxControlsList(amenities);

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);
  const [selectedAmenities, setSelectedAmenities] = useState<PropertySearchTermFacetBucket[]>(defaultSelected);

  const handleChange = (selectedValues: PropertySearchTermFacetBucket[]) => {
    setSelectedAmenities(selectedValues);
    onFilterChange(filterType, selectedValues);
  };

  const handleClear = () => {
    setSelectedAmenities([]);
    onClear(filterType);
  };

  const amenitiesFilter = (
    <CheckboxControlsList
      name={labels.heading}
      key={labels.heading}
      controls={checkboxControlsListControlProp}
      defaultSelected={selectedAmenities}
      onChange={selectedValues => handleChange(selectedValues as PropertySearchTermFacetBucket[])}
      colLength={isTabletAndAbove ? 6 : 12}
    />
  );

  return (
    <StyledAmenitiesFilterModal>
      <FilterModal
        labels={labels}
        show={showModal}
        bodyCustomClass={'custom-scrollbar'}
        children={amenitiesFilter}
        isLoading={isLoading}
        onApplySelectedFilters={() => onApply(filterType, selectedAmenities)}
        onClearSelectedFilters={handleClear}
        onCloseModal={onCloseModal}
      />
    </StyledAmenitiesFilterModal>
  );
};
