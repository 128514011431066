import { GroupRate, Property as PropertyDetails } from '@marriott/mi-groups-graphql';
import { Ctas, Property } from '../SearchResults.types';

export type PropertyCardProps = {
  variant?: 'regular' | 'mini';
  propertyLabels: Property;
  ctas: Ctas;
  propertyData: PropertyDetails;
  groupRate?: GroupRate | null;
  bookNowCallback?: (property: PropertyDetails, groupRate?: GroupRate | null) => void;
};

export type PropertyImage = {
  defaultImageUrl: string;
  title: string;
  altText: string;
};

export enum PropertyImageType {
  LARGE_DESKTOP,
  REGULAR_DESKTOP,
  REGULAR_TABLET,
  REGULAR_MOBILE,
  MINI,
}

export type PropertyImageKey = keyof PropertyImage;
