/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import { GroupSearchFormData } from '../organisms/GroupSearchForm/GroupSearchForm.types';
import { SearchFormField } from '../organisms/GroupSearchForm/GroupSearchFormMobile/GroupSearchFormMobile.types';

type SearchFormState = {
  formData: GroupSearchFormData;
  persistedFormData: GroupSearchFormData;
  currentStep: SearchFormField;
  defaultMobileView: boolean;
  loader: boolean;
  setFormData: (formData: Partial<GroupSearchFormData>) => void;
  setPersistedFormData: (formData: GroupSearchFormData) => void;
  resetFormData: () => void;
  resetPersistedFormData: () => void;
  setCurrentStep: (currentStep: SearchFormField) => void;
  setDefaultMobileView: (defaultMobileView: boolean) => void;
  setLoader: (loader: boolean) => void;
};

const initialFormData = {
  destination: { description: '', placeId: '' },
  dates: '',
  startDate: '',
  endDate: '',
  flexibleDates: false,
  eventType: { label: '', code: '' },
  guestRooms: '',
  attendees: '',
};

const initialState: SearchFormState = {
  formData: { ...initialFormData },
  persistedFormData: { ...initialFormData },
  currentStep: SearchFormField.INITIAL,
  defaultMobileView: false,
  loader: false,
  setFormData: () => {},
  setPersistedFormData: () => {},
  resetFormData: () => {},
  resetPersistedFormData: () => {},
  setCurrentStep: () => {},
  setDefaultMobileView: () => {},
  setLoader: () => {},
};

export const SearchFormStore: StateCreator<SearchFormState> = set => {
  return {
    ...initialState,
    setFormData: (data: Partial<GroupSearchFormData>) =>
      set(prevState => ({
        formData: {
          ...prevState.formData,
          ...data,
        },
      })),
    setPersistedFormData: (data: GroupSearchFormData) => set({ persistedFormData: { ...data } }),
    resetFormData: () => set({ formData: initialState.formData }),
    resetPersistedFormData: () => set({ persistedFormData: initialState.formData }),
    setCurrentStep: (data: SearchFormField) => set({ currentStep: data }),
    setDefaultMobileView: (data: boolean) => set({ defaultMobileView: data }),
    setLoader: (data: boolean) => set(() => ({ loader: data })),
  };
};

export const useSearchFromStore = createAppStore(SearchFormStore);
