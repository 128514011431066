import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, font } = baseVariables;

export const StyledContactNumberList = styled.div`
  .contact-info {
    width: ${toRem(368)};
    position: relative;
    display: block;
    margin-bottom: 0;
    .contact-number-container {
      cursor: pointer;
      color: ${color.alert40};
      .contact-number {
        font-weight: 500;
      }
    }
    ul {
      display: none;
    }
    .contact-list {
      width: 100%;
      height: ${282} !important;
      background: ${color.base20};
      position: absolute;
      top: ${toRem(22)};
      left: 0;
      z-index: 10;
      opacity: 1 !important;
      display: block !important;
      transform-origin: top center;
      animation: growDown 100ms ease-in-out forwards;
      .contact-option {
        list-style-type: none;
        padding: ${toRem(12)} ${toRem(6)};
        :not(:first-child) {
          border-top: ${toRem(1)} solid ${color.neutral20};
        }
      }
      .toll-free-cta {
        font-weight: ${font.fontWeightMedium};
        text-decoration: underline;
      }
    }
  }
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;
