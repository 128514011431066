import React, { FC, useState } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BrandFilter } from '@marriott/mi-shop-components';
import { BrandFilterModalProps, PropertySearchTermFacetBucket } from './BrandFilterModal.types';
import { BrandCategory, BrandItem } from '../SearchResults.types';
import { StyledBrandFilterModal } from './BrandFilterModal.styles';
import { FilterModal } from '../../../molecules';

export const getAllBrands = (brandCategories: BrandCategory[], availableBrands?: PropertySearchTermFacetBucket[]) => {
  return brandCategories.map(category => ({
    ...category,
    brands: category.brands.map(brand => ({
      ...brand,
      isDisabled: !availableBrands?.some((currentBrand: { code: string }) => currentBrand.code === brand.brandTagId),
    })),
  }));
};

export const getSelectableBrands = (brandItems: BrandItem[]) => {
  const uniqueBrandIds = new Set<string>();
  const selectableBrands: BrandItem[] = [];
  brandItems.forEach((brandItem: BrandItem) => {
    if (!brandItem.isDisabled && !uniqueBrandIds.has(brandItem.brandTagId)) {
      uniqueBrandIds.add(brandItem.brandTagId);
      selectableBrands.push(brandItem);
    }
  });
  return selectableBrands;
};

export const BrandFilterModal: FC<BrandFilterModalProps> = ({
  filterType,
  labels,
  brandCategories,
  availableBrands,
  selectedBrands = [],
  showModal,
  isLoading,
  onFilterChange,
  onApply,
  onClear,
  onCloseModal,
}) => {
  const [brandSelection, setBrandSelection] = useState<BrandItem[]>(selectedBrands);

  const modifiedBrandCategories = getAllBrands(brandCategories, availableBrands);

  const handleBrandChange = (selectedBrandItems: BrandItem[]) => {
    const selectableBrands = getSelectableBrands(selectedBrandItems);
    setBrandSelection(selectableBrands);
    onFilterChange(filterType, selectableBrands);
  };

  const handleClear = () => {
    setBrandSelection([]);
    onClear(filterType);
  };

  const brandFilters = (
    <BrandFilter
      key={labels.heading}
      title={''}
      brandCategories={modifiedBrandCategories}
      defaultSelected={brandSelection}
      isHotelBrandSelectedFlag={true}
      onChange={handleBrandChange}
    />
  );

  return (
    <StyledBrandFilterModal>
      <FilterModal
        labels={labels}
        show={showModal}
        bodyCustomClass={'custom-scrollbar'}
        children={brandFilters}
        isLoading={isLoading}
        onCloseModal={onCloseModal}
        onClearSelectedFilters={handleClear}
        onApplySelectedFilters={() => onApply(filterType, brandSelection)}
      />
    </StyledBrandFilterModal>
  );
};
