import { FC, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import { convertFeetToMeters, convertSqFeetToSqMeters } from '../../../utils';
import { CapacityChartProps } from './CapacityChart.types';
import { StyledCapacityChart } from './CapacityChart.styles';

export const CapacityChart: FC<CapacityChartProps> = ({ labels, values }) => {
  const [isMeters, setIsMeters] = useState(false);

  return (
    <StyledCapacityChart>
      <div className="mb-3 t-font-xs">
        <Button
          className={clsx('p-0 unit-cta', !isMeters && 'active')}
          buttonCopy={labels.feet}
          callback={() => setIsMeters(false)}
        />
        {' | '}
        <Button
          className={clsx('mr-1 p-0 unit-cta', isMeters && 'active')}
          buttonCopy={labels.meters}
          callback={() => setIsMeters(true)}
        />
      </div>
      <div className="custom-scrollbar capacity-table-container">
        <table className="capacity-table">
          <thead>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.roomType}</th>
              {values.map(item => {
                return (
                  <td key={item.node.name} className="t-font-s text-center capacity-table-cell room-type">
                    {item.node.name}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.dimensions}</th>
              {values.map((item, index) => {
                const { length, width, height } = item.node;
                return (
                  <td
                    key={`${index}_${length}_${width}_${height}`}
                    className="t-font-s text-center capacity-table-cell"
                  >
                    {isMeters
                      ? `${length ? convertFeetToMeters(length) : 0}x${width ? convertFeetToMeters(width) : 0}x${
                          height ? convertFeetToMeters(height) : 0
                        }`
                      : `${length ?? 0}x${width ?? 0}x${height ?? 0}`}
                  </td>
                );
              })}
            </tr>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">
                {isMeters ? labels.areaSquareMeter : labels.areaSquareFeet}
              </th>
              {values.map((item, index) => {
                return (
                  <td key={`${index}_${item.node.area}`} className="t-font-s text-center capacity-table-cell">
                    {isMeters ? (item.node.area ? convertSqFeetToSqMeters(item.node.area) : '') : item.node.area}
                  </td>
                );
              })}
            </tr>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.theater}</th>
              {values.map((item, index) => {
                return (
                  <td key={`${index}_${item.node.theater}`} className="t-font-s text-center capacity-table-cell">
                    {item.node.theater}
                  </td>
                );
              })}
            </tr>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.schoolRoom}</th>
              {values.map((item, index) => {
                return (
                  <td key={`${index}_${item.node.schoolroom}`} className="t-font-s text-center capacity-table-cell">
                    {item.node.schoolroom}
                  </td>
                );
              })}
            </tr>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.conference}</th>
              {values.map((item, index) => {
                return (
                  <td key={`${index}_${item.node.conference}`} className="t-font-s text-center capacity-table-cell">
                    {item.node.conference}
                  </td>
                );
              })}
            </tr>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.uShape}</th>
              {values.map((item, index) => {
                return (
                  <td key={`${index}_${item.node.uShape}`} className="t-font-s text-center capacity-table-cell">
                    {item.node.uShape}
                  </td>
                );
              })}
            </tr>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.reception}</th>
              {values.map((item, index) => {
                return (
                  <td key={`${index}_${item.node.reception}`} className="t-font-s text-center capacity-table-cell">
                    {item.node.reception}
                  </td>
                );
              })}
            </tr>
            <tr className="capacity-table-row">
              <th className="t-font-s capacity-table-cell">{labels.banquet}</th>
              {values.map((item, index) => {
                return (
                  <td key={`${index}_${item.node.banquet}`} className="t-font-s text-center capacity-table-cell">
                    {item.node.banquet}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </StyledCapacityChart>
  );
};
