import moment from 'moment';
import { v4 } from 'uuid';
import {
  FunctionSpaceStyleInfo,
  RoomRate,
  FunctionSpacePricingResponse,
  FunctionSpacePriceInfo,
  ContactNumber,
  Connection,
  RoomFeeDetails,
  AmountType,
  ChargeFrequencyType,
  RoomTaxDetails,
  BillingType,
  RoomTypeDetails,
  EventSpaceDetails,
  EventType,
  ContractDetails,
  SittingArrangement,
  GroupRateType,
} from '@marriott/mi-groups-graphql';
import { GuestRoomSelectionData } from '../organisms/GuestRooms/GuestRoomSelectionGrid/GuestRoomSelectionGrid.types';
import { DayRoomData } from '../organisms/GuestRooms/GuestRoomSelectionGrid/GuestRoomSelection/GuestRoomSelection.types';
import { moveDecimalPoint } from './searchResults';
import {
  QG_EXCLUDED_FEE_AND_TAX_CODES,
  QG_PORTERAGE_FEE,
  QG_DECIMAL_POINT_VALUE,
  QG_GUEST_ROOM_AMOUNT_UNIT_CODE,
  QG_GUEST_ROOM_AMOUNT_UNIT_DESCRIPTION,
  QG_EVENT_SPACE_AMOUNT_UNIT_CODE,
  QG_EVENT_SPACE_AMOUNT_UNIT_DESCRIPTION,
  QG_MIN_GUEST_ROOMS,
  QG_MAX_GUEST_ROOMS,
  QG_CANCELLATION_AMOUNT_UNIT_CODE,
  QG_CANCELLATION_AMOUNT_UNIT_DESCRIPTION,
  QG_ATTRITION_AMOUNT_UNIT_CODE,
  QG_ATTRITION_AMOUNT_UNIT_DESCRIPTION,
  INLINE_ENROLLMENT_URL,
  QG_GOOGLE_CALENDAR_EVENT_URL,
} from '../constants';
import { EventSpaceConfigurationOption } from '../molecules/EventSpaceConfigurationPicker/EventSpaceConfigurationPicker.types';
import { convertTo24HourTime, getFormattedDateString, getDurationInDays, getFormattedDate } from './date';
import { quickGroupDataStorage } from './search';
import { ContactNo, ContactType } from '../molecules/ContactNumberList/ContactNumberList.types';
import { formatPhoneNumber } from '../admin/utils/roomingList';
import { ContactInfoLabels } from '../organisms/PropertyHeader/PropertyHeader.types';
import { GuestRoomsFormData } from '../organisms/GuestRooms/GuestRooms.types';
import {
  EventSpaceInfo,
  eventSpaceConfigurationMap,
  EventSpacesFormData,
} from '../organisms/EventSpaces/EventSpaces.types';
import { BillingAndContactFormData } from '../organisms/BillingAndContact/BillingAndContact.types';
import { ContractType } from '../organisms/Review/FinalizeContract/ContractTermsModal/ContractTermsModal.types';
import { QuickGroupData } from '../organisms/SearchResults/SearchResults.types';
import { ReviewFormData } from '../organisms/Review/Review.types';
import { QuickGroupPageIdentifier } from '../organisms/QuickGroupHeader/QuickGroupHeader.types';
import { CalendarEventData } from '../organisms/QuickGroupConfirmation/ConfirmationCard/ConfirmationCard.types';
import { AddToCalendarLabels } from '../organisms/QuickGroupConfirmation/ConfirmationCard/ConfirmationMenu/ConfirmationMenu.types';
import { encryptPassword } from './encrypt';
import { EnrollUserPayload } from './auth';

export const isQuickGroupPage = (pageIdentifier: QuickGroupPageIdentifier) => {
  return window.location.href.includes(pageIdentifier);
};

export const getOrderedContactNumbers = (
  contactNumbers: ContactNumber[],
  contactInfoLabels: ContactInfoLabels
): ContactNo[] => {
  const { PHONE, FAX, SALES, SALES_FAX, RESERVATION } = ContactType;
  const orderContactType = [PHONE, FAX, SALES, SALES_FAX, RESERVATION];

  contactNumbers.sort(
    (a, b) =>
      orderContactType.indexOf(a.type.code as ContactType) - orderContactType.indexOf(b.type.code as ContactType)
  );

  return contactNumbers.reduce((acc: ContactNo[], contactNumber) => {
    const contact = getContactNumberLabel(contactInfoLabels, contactNumber.type.code as ContactType);
    if (contact.label && contactNumber.phoneNumber.display) {
      acc.push({
        ...contact,
        number: formatPhoneNumber(contactNumber.phoneNumber.display) as string,
      });
    }
    return acc;
  }, []);
};

const getContactNumberLabel = (contactInfoLabels: ContactInfoLabels, type: ContactType) => {
  switch (type) {
    case ContactType.PHONE:
      return {
        label: contactInfoLabels.phoneLabel,
        ariaLabel: contactInfoLabels.phoneAriaLabel || contactInfoLabels.phoneLabel,
      };
    case ContactType.FAX:
      return {
        label: contactInfoLabels.faxLabel,
        ariaLabel: contactInfoLabels.faxAriaLabel || contactInfoLabels.faxLabel,
      };
    case ContactType.SALES:
      return {
        label: contactInfoLabels.salesLabel,
        ariaLabel: contactInfoLabels.salesAriaLabel || contactInfoLabels.salesLabel,
      };
    case ContactType.SALES_FAX:
      return {
        label: contactInfoLabels.salesFaxLabel,
        ariaLabel: contactInfoLabels.salesFaxAriaLabel || contactInfoLabels.salesFaxLabel,
      };
    case ContactType.RESERVATION:
      return {
        label: contactInfoLabels.reservationCenterLabel,
        ariaLabel: contactInfoLabels.reservationCenterAriaLabel || contactInfoLabels.reservationCenterLabel,
      };
    default:
      return { label: '', ariaLabel: '' };
  }
};

export const getUniqueRoomRates = (roomRates: RoomRate[], startDate: string) => {
  return roomRates.filter(roomRate => roomRate.startDate === startDate).sort((a, b) => +b.isPrimary - +a.isPrimary);
};

export const getGuestRoomSelectionDataList = (
  roomRates: RoomRate[],
  numberOfRooms: number,
  groupRateType: GroupRateType
): GuestRoomSelectionData[] => {
  const roomSelectionDataMap: { [id: string]: GuestRoomSelectionData } = {};

  const dayMaxInventory = roomRates.reduce((acc: { [date: string]: number }, roomRate) => {
    const { startDate: date, maximumInventory } = roomRate;
    acc[date] = (acc[date] || 0) + maximumInventory;
    return acc;
  }, {});

  const processRoomData = (roomRate: RoomRate, groupRateType: GroupRateType) => {
    const { amount, startDate: date, maximumInventory, rateOffset } = roomRate;
    const price =
      +moveDecimalPoint(amount.origin.value, amount.origin.valueDecimalPoint) +
      (groupRateType === GroupRateType.GPO && rateOffset ? rateOffset : 0);

    return {
      date,
      rate: +price,
      quantity: maximumInventory,
      minRooms: 0,
      maxRooms: maximumInventory,
    };
  };

  roomRates.forEach(roomRate => {
    const { roomType, amount, startDate: date, isPrimary } = roomRate;

    if (!roomSelectionDataMap[roomType.id]) {
      roomSelectionDataMap[roomType.id] = {
        id: roomType.id,
        name: roomType.name,
        description: roomType.description,
        currency: amount.origin.currency,
        roomData: {},
        isPrimary: isPrimary,
      };
    }

    roomSelectionDataMap[roomType.id].roomData[date] = processRoomData(roomRate, groupRateType);
  });

  const roomSelectionDataList = Object.values(roomSelectionDataMap);

  roomSelectionDataList.forEach(roomSelectionData => {
    const dayRoomDataList = Object.values(roomSelectionData.roomData);

    dayRoomDataList.forEach((dayRoomData: DayRoomData) => {
      const totalMaxInventory = dayMaxInventory[dayRoomData.date];
      const otherRoomMaxInventory = totalMaxInventory - dayRoomData.maxRooms;
      dayRoomData.quantity = roomSelectionData.isPrimary ? Math.min(dayRoomData.maxRooms, numberOfRooms) : 0;
      dayRoomData.minRooms = Math.max(0, numberOfRooms - otherRoomMaxInventory);
      dayRoomData.maxRooms = Math.min(dayRoomData.maxRooms, numberOfRooms);
    });
  });

  return roomSelectionDataList.sort((a, b) => +b.isPrimary - +a.isPrimary);
};

export const getEstimatedTotalAmount = (data: GuestRoomSelectionData[]) => {
  return data.reduce((total, room) => {
    return (
      total +
      Object.values(room.roomData).reduce((roomTotal, roomData) => {
        return roomTotal + roomData.quantity * roomData.rate;
      }, 0)
    );
  }, 0);
};

export const getSpaceConfigurationOptions = (
  functionSpaceStyles: FunctionSpaceStyleInfo[] | undefined,
  spaceConfigurations: EventSpaceConfigurationOption[],
  numberOfAttendees: string
) => {
  const setupStyleCapacity = functionSpaceStyles?.reduce(
    (acc: Record<string, string>, functionSpaceStyleInfo: FunctionSpaceStyleInfo) => {
      acc[functionSpaceStyleInfo.styleCode] = functionSpaceStyleInfo.maxCapacity;
      return acc;
    },
    {}
  );

  return spaceConfigurations.map(spaceConfiguration => {
    const styleCode = eventSpaceConfigurationMap[spaceConfiguration.value];
    const maxCapacity = setupStyleCapacity?.[styleCode];
    if (maxCapacity && +maxCapacity >= +numberOfAttendees) {
      return { ...spaceConfiguration, disabled: false };
    }
    return { ...spaceConfiguration, disabled: true };
  });
};

export const getDayEventSpaceRate = (
  date: string,
  time: [string, string],
  pricingData?: FunctionSpacePricingResponse
) => {
  const startTime24h = convertTo24HourTime(time[0]);
  const endTime24h = convertTo24HourTime(time[1]);
  const startHour = +startTime24h.split(':')[0];
  const startMinute = +startTime24h.split(':')[1];
  const endHour = +endTime24h.split(':')[0];
  const endMinute = +endTime24h.split(':')[1];
  let totalRate = 0;

  const functionSpacePriceInfo = pricingData?.priceInfo?.reduce(
    (acc: Record<string, FunctionSpacePriceInfo>, priceInfo: FunctionSpacePriceInfo) => {
      acc[priceInfo.functionDate] = priceInfo;
      return acc;
    },
    {}
  );

  pricingData?.dayParts?.forEach(dayPart => {
    const dayPartStartHour = +dayPart.startTime.split(':')[0];
    const dayPartStartMinute = +dayPart.startTime.split(':')[1];
    const dayPartEndHour = +dayPart.endTime.split(':')[0] !== 0 ? +dayPart.endTime.split(':')[0] : 24;
    const dayPartEndMinute = +dayPart.endTime.split(':')[1];

    if (
      (startHour < dayPartEndHour || (startHour === dayPartEndHour && startMinute < dayPartEndMinute)) &&
      (endHour > dayPartStartHour || (endHour === dayPartStartHour && endMinute > dayPartStartMinute))
    ) {
      const priceInfo = functionSpacePriceInfo?.[date];
      if (priceInfo) {
        totalRate += parseFloat(priceInfo[`${dayPart.dayPart}DayPartPrice`]);
      }
    }
  });

  return totalRate.toFixed(2).toString();
};

export const getRoomFeeDetails = (
  roomFees: Connection<RoomFeeDetails>,
  feeTaxMap: Record<string, string>,
  currency: string,
  endDate: string,
  perDay: string,
  perStay: string
) => {
  const filteredRoomFeeDetailsMap: Record<string, RoomFeeDetails> = {};

  roomFees.edges.forEach(edge => {
    const { feeId, otaCode, includedInRate, effectiveDate, dependencyAttribute } = edge.node;
    if (
      !(
        QG_EXCLUDED_FEE_AND_TAX_CODES.includes(feeId) ||
        includedInRate ||
        dependencyAttribute?.positiveDependency ||
        feeTaxMap[otaCode]?.includes(QG_PORTERAGE_FEE) ||
        new Date(effectiveDate).getTime() > new Date(endDate).getTime()
      )
    ) {
      filteredRoomFeeDetailsMap[feeId] = edge.node;
    }
  });

  const filteredRoomFeeDetails = [...Object.values(filteredRoomFeeDetailsMap)].sort((a, b) => +a.feeId - +b.feeId);

  return filteredRoomFeeDetails
    ?.map(roomFee => {
      const { feeAmount, feeAmountType, otaCode, chargeUnit, chargeFrequency } = roomFee;

      return `${feeTaxMap[otaCode]} - ${feeAmount}${
        feeAmountType === AmountType.AMOUNT ? ` ${currency}` : '%'
      } ${chargeUnit.description.toLowerCase()} ${
        chargeFrequency.description === ChargeFrequencyType.DAILY
          ? perDay
          : chargeFrequency.description === ChargeFrequencyType.STAY
          ? perStay
          : chargeFrequency.description
      }`;
    })
    .join(', ');
};

export const getRoomTaxDetails = (
  roomTaxes: Connection<RoomTaxDetails>,
  feeTaxMap: Record<string, string>,
  currency: string,
  endDate: string,
  perDay: string,
  perStay: string
) => {
  const filteredRoomTaxDetailsMap: Record<string, RoomTaxDetails> = {};

  roomTaxes.edges.forEach(edge => {
    const { taxId, includedInRate, effectiveDate } = edge.node;
    if (
      !(
        QG_EXCLUDED_FEE_AND_TAX_CODES.includes(taxId) ||
        includedInRate ||
        new Date(effectiveDate).getTime() > new Date(endDate).getTime()
      )
    ) {
      filteredRoomTaxDetailsMap[taxId] = edge.node;
    }
  });

  const filteredRoomTaxDetails = [...Object.values(filteredRoomTaxDetailsMap)].sort((a, b) => +a.taxId - +b.taxId);

  return filteredRoomTaxDetails
    ?.map(roomTax => {
      const { taxAmount, taxAmountType, otaCode, chargeUnit, chargeFrequency } = roomTax;

      return `${feeTaxMap[otaCode]} - ${taxAmount}${
        taxAmountType === AmountType.AMOUNT ? ` ${currency}` : '%'
      } ${chargeUnit?.description.toLowerCase()} ${
        chargeFrequency?.description === ChargeFrequencyType.DAILY
          ? perDay
          : chargeFrequency?.description === ChargeFrequencyType.STAY
          ? perStay
          : chargeFrequency?.description
      }`;
    })
    .join(', ');
};

export const initializeContractDetails = () => ({
  contractType: '',
  cancellationFeeDetails: {
    rateDetails: {
      amount: {
        value: 0,
      },
    },
  },
  roomAttrition: {
    rateDetails: {
      amount: {
        value: 0,
      },
    },
  },
});

export const getPartialContractDetails = (quickGroupData: Partial<QuickGroupData>, estimatedTotal: number) => {
  const { startDate, endDate, eventType, numberOfRooms, numberOfAttendees } = quickGroupData;
  const contractDetails = initializeContractDetails();

  if (numberOfRooms && +numberOfRooms >= QG_MIN_GUEST_ROOMS && +numberOfRooms <= QG_MAX_GUEST_ROOMS) {
    if (+(numberOfAttendees as string)) {
      contractDetails.contractType = ContractType.D;
    } else {
      if (eventType?.toUpperCase() === EventType.BUSINESS || +numberOfRooms > 15) {
        contractDetails.contractType = ContractType.A;
      } else {
        contractDetails.contractType = ContractType.B;
      }
    }
  } else if (+(numberOfAttendees as string)) {
    contractDetails.contractType = ContractType.E;
  }

  if (contractDetails.contractType) {
    if (contractDetails.contractType === ContractType.E) {
      contractDetails.roomAttrition.rateDetails.amount.value = 0;
      contractDetails.cancellationFeeDetails.rateDetails.amount.value = 0;
    } else {
      const totalRooms = +(numberOfRooms as string) * getDurationInDays(startDate, endDate);
      contractDetails.roomAttrition.rateDetails.amount.value = estimatedTotal / totalRooms;
      contractDetails.cancellationFeeDetails.rateDetails.amount.value = estimatedTotal;
    }
  }

  return contractDetails;
};

export const getEnrollUserPayload = (contactData: BillingAndContactFormData): EnrollUserPayload => {
  const { userInfo, inlineEnrolment, addressInfo } = contactData;
  const { firstName, lastName, email } = userInfo;
  const { createPassword, confirmPassword, rememberMe, receiveOffers, receiveUpdates } = inlineEnrolment;
  const { zipcode, country } = addressInfo;

  return {
    userDetails: {
      firstName,
      lastName,
      emailAddress: email,
      pwrd: encryptPassword(createPassword),
      confirmPwrd: encryptPassword(confirmPassword),
      rememberMe,
      countryCode: country.code,
      postalCode: zipcode,
    },
    userConsents: [
      { code: 'CORE_REQUIRED_CONSENT', optIn: false },
      { code: 'PERSONALIZED_MARKETING_CONSENT', optIn: false },
    ],
    communicationPreferences: [
      { type: 'subscriptionsOffers', optIn: receiveOffers },
      { type: 'subscriptions3rdParty', optIn: receiveUpdates },
    ],
  };
};

const roundToDecimalPlaces = (number: number, positions: number) => Math.round(number * Math.pow(10, positions)) || 0;

const getEventDetails = (
  roomSelectionDataList: GuestRoomSelectionData[],
  eventSpaces: EventSpaceInfo[],
  eventType: string,
  functionSpaceId: string,
  functionSpaceName: string,
  eventSpaceConfigurationMap: Record<string, string>
) => {
  const daywiseRoomTypes = new Map<string, RoomTypeDetails[]>();
  const daywiseEventSpaces = new Map<string, EventSpaceDetails[]>();

  roomSelectionDataList.forEach(roomSelectionData => {
    Object.entries(roomSelectionData.roomData).forEach(([date, dayRoomData]) => {
      if (dayRoomData.quantity) {
        if (!daywiseRoomTypes.has(date)) {
          daywiseRoomTypes.set(date, []);
        }

        const roomType = {
          roomPoolCode: roomSelectionData.id.split('.')[1],
          segmentCode: eventType.toUpperCase() === EventType.BUSINESS ? 'C' : 'S',
          roomTitle: `${roomSelectionData.name}, ${roomSelectionData.description}`,
          count: dayRoomData.quantity,
          rateDetails: {
            amount: {
              value: roundToDecimalPlaces(dayRoomData.rate, QG_DECIMAL_POINT_VALUE),
              decimalPoint: QG_DECIMAL_POINT_VALUE,
            },
            unit: {
              code: QG_GUEST_ROOM_AMOUNT_UNIT_CODE,
              description: QG_GUEST_ROOM_AMOUNT_UNIT_DESCRIPTION,
            },
          },
          isPrime: roomSelectionData.isPrimary,
        };

        daywiseRoomTypes.get(date)?.push(roomType);
      }
    });
  });

  eventSpaces.forEach(eventSpace => {
    if (!eventSpace.removed) {
      const { date, time, attendees, rate, configuration } = eventSpace;

      if (!daywiseEventSpaces.has(date)) {
        daywiseEventSpaces.set(date, []);
      }

      const eventSpaceDetails = {
        functionSpaceID: functionSpaceId,
        functionSpaceName,
        numberOfAttendees: +attendees,
        startTime: time[0],
        endTime: time[1],
        sittingArrangement: configuration as SittingArrangement,
        sittingArrangementRate: {
          name: eventSpaceConfigurationMap[configuration],
          rateDetails: {
            amount: {
              value: roundToDecimalPlaces(+rate, QG_DECIMAL_POINT_VALUE),
              decimalPoint: QG_DECIMAL_POINT_VALUE,
            },
            unit: {
              code: QG_EVENT_SPACE_AMOUNT_UNIT_CODE,
              description: `${QG_EVENT_SPACE_AMOUNT_UNIT_DESCRIPTION} ${eventSpaceConfigurationMap[configuration]}`,
            },
          },
        },
      };

      daywiseEventSpaces.get(date)?.push(eventSpaceDetails);
    }
  });

  const uniqueDates = Array.from(new Set([...daywiseRoomTypes.keys(), ...daywiseEventSpaces.keys()]));

  const eventDetails = uniqueDates.map(date => {
    const roomTypes = daywiseRoomTypes.get(date);
    const eventSpaces = daywiseEventSpaces.get(date);
    return {
      date: getFormattedDateString(date, 'slashedDateWithMonthNoAndYear'),
      ...(roomTypes ? { roomType: roomTypes } : {}),
      ...(eventSpaces ? { eventSpace: eventSpaces } : {}),
    };
  });

  return { eventDetails };
};

export const getGuestRoomAndEventSpaceDetails = (
  guestRoomsData: GuestRoomsFormData,
  eventSpacesData: EventSpacesFormData,
  functionSpaceId: string,
  functionSpaceName: string,
  eventSpaceConfigurationMap: Record<string, string>
) => {
  const {
    stayInOneRoom,
    roomSelectionData,
    billingTypeOptions,
    billingType,
    contentPersonalized,
    additionalInfo: guestRoomComment,
  } = guestRoomsData;
  const [individualPaymentOption1, _, groupPaymentOption1, groupPaymentOption2] = billingTypeOptions;
  const { eventSpaces, additionalInfo: eventSpaceComment } = eventSpacesData;

  const selectedEventTypeCode = guestRoomsData.eventType.code || eventSpacesData.eventType.code;

  return {
    currency: roomSelectionData[0]?.currency || eventSpaces[0]?.currency,
    ...getEventDetails(
      roomSelectionData,
      eventSpaces,
      selectedEventTypeCode,
      functionSpaceId,
      functionSpaceName,
      eventSpaceConfigurationMap
    ),
    ...(billingType
      ? {
          isThreePlusGuestRequested: !!stayInOneRoom,
          billingDetails: {
            type:
              billingType === groupPaymentOption1
                ? BillingType.SRT
                : billingType === groupPaymentOption2
                ? BillingType.SAC
                : BillingType.EPO,
            resMethod: billingType === individualPaymentOption1 ? 'I' : 'L',
            isCustomWebPageRequired: contentPersonalized,
          },
          reservationMethod: billingType === individualPaymentOption1 ? 3 : 2,
        }
      : {}),
    ...(guestRoomComment ? { guestRoomComment } : {}),
    ...(eventSpaceComment ? { eventSpaceComment } : {}),
  };
};

export const getContractDetails = (partialContractData: ContractDetails, reviewData: ReviewFormData) => {
  const { contractType, cancellationFeeDetails, roomAttrition } = partialContractData;
  const { taxAndFeeDetails, acceptTnc } = reviewData;
  const { roomFees, roomTaxes, eventFees } = taxAndFeeDetails;

  return {
    contractDetails: {
      contractType,
      isContractSigned: acceptTnc,
      cancellationFeeDetails: {
        rateDetails: {
          amount: {
            value: roundToDecimalPlaces(+cancellationFeeDetails.rateDetails.amount.value, QG_DECIMAL_POINT_VALUE),
            decimalPoint: QG_DECIMAL_POINT_VALUE,
          },
          unit: {
            code: QG_CANCELLATION_AMOUNT_UNIT_CODE,
            description: QG_CANCELLATION_AMOUNT_UNIT_DESCRIPTION,
          },
        },
      },
      roomAttrition: {
        rateDetails: {
          amount: {
            value: roundToDecimalPlaces(+roomAttrition.rateDetails.amount.value, QG_DECIMAL_POINT_VALUE),
            decimalPoint: QG_DECIMAL_POINT_VALUE,
          },
          unit: {
            code: QG_ATTRITION_AMOUNT_UNIT_CODE,
            description: QG_ATTRITION_AMOUNT_UNIT_DESCRIPTION,
          },
        },
      },
      ...(roomFees || roomTaxes || eventFees
        ? {
            taxAndFeeDetails: {
              ...(roomFees ? { roomFees } : {}),
              ...(roomTaxes ? { roomTaxes } : {}),
              ...(eventFees ? { eventFees } : {}),
            },
          }
        : {}),
    },
  };
};

export const getEventProfileAndGuestDetails = (
  contactData: BillingAndContactFormData,
  selectedEventType: string,
  cutOffDate: string
) => {
  const { startDate, endDate, numberOfRooms, numberOfAttendees } = quickGroupDataStorage.getItem();
  const { eventInfo, weddingInfo, userInfo, addressInfo } = contactData;
  const { firstSpouseLastName, secondSpouseLastName } = weddingInfo;
  const { firstName, lastName, email, callingCode, phoneNumber, rewardId } = userInfo;
  const { addressType, companyName, addressLine1, addressLine2, country, city, zipcode } = addressInfo;

  let state = '';
  try {
    const stateOption = JSON.parse(addressInfo.state);
    state = stateOption.code;
  } catch (error) {
    state = addressInfo.state;
  }

  const eventName =
    selectedEventType.toUpperCase() === EventType.WEDDING
      ? `${firstSpouseLastName}/${secondSpouseLastName} ${selectedEventType}`
      : eventInfo.eventName;

  return {
    eventProfile: {
      startDate: getFormattedDateString(startDate, 'slashedDateWithMonthNoAndYear'),
      endDate: getFormattedDateString(endDate, 'slashedDateWithMonthNoAndYear'),
      ...(cutOffDate ? { cutOffDate: getFormattedDateString(cutOffDate, 'slashedDateWithMonthNoAndYear') } : {}),
      eventType: selectedEventType.toUpperCase() as EventType,
      ...(numberOfRooms ? { numberOfRooms: +numberOfRooms } : {}),
      ...(numberOfAttendees ? { numberOfAttendees: +numberOfAttendees } : {}),
      name: eventName,
      ...(firstSpouseLastName ? { brideLastName: firstSpouseLastName } : {}),
      ...(secondSpouseLastName ? { groomLastName: secondSpouseLastName } : {}),
    },
    guests: {
      firstName,
      lastName,
      email,
      dialingCountryCode: callingCode.value,
      phone: phoneNumber,
      ...(rewardId ? { rewardId } : {}),
      address: {
        type: addressType,
        ...(companyName ? { companyName } : {}),
        line1: addressLine1,
        ...(addressLine2 ? { line2: addressLine2 } : {}),
        country: {
          code: country.code,
          name: country.label,
        },
        city,
        state,
        zipCode: zipcode,
      },
    },
  };
};

const formatCalendarEventDateTime = (date: string, time: string) => {
  const [month, day, year] = date.split('/');
  return `${year}${month}${day}T${time.replace(/:/g, '')}00Z`;
};

const getCalendarEventDetails = (labels: AddToCalendarLabels, data: CalendarEventData, seperator: string) => {
  const {
    eventName,
    propertyName,
    propertyAddress,
    propertyPhoneNumber,
    startDate,
    endDate,
    checkInTime,
    checkOutTime,
    confirmationNumber,
  } = data;

  const formattedStartDate = getFormattedDate(moment(startDate), 'dateWithMonthNoAndShortYear');
  const formattedEndDate = getFormattedDate(moment(endDate), 'dateWithMonthNoAndShortYear');

  const addressLine = `${propertyAddress?.city ?? ''}, ${propertyAddress?.stateProvince?.description ?? ''}, ${
    propertyAddress?.country?.description ?? ''
  }`;

  const summary = `${labels.reservationAt} ${propertyName}, ${labels.starts}:${formattedStartDate}, ${labels.ends}:${formattedEndDate}`;
  const startDateTime = formatCalendarEventDateTime(startDate, checkInTime);
  const endDateTime = formatCalendarEventDateTime(endDate, checkOutTime);
  const location = `${propertyName} ${addressLine}`;
  const description = [
    eventName,
    propertyName,
    propertyAddress?.line1 ?? '',
    addressLine,
    `${labels.tel}: ${propertyPhoneNumber}`,
    `${labels.starts}:${formattedStartDate}, ${labels.ends}:${formattedEndDate}`,
    `${labels.confirmationNumber}:${confirmationNumber}`,
  ].join(seperator);

  return {
    summary,
    startDateTime,
    endDateTime,
    location,
    description,
  };
};

export const generateVCalendarEvent = (labels: AddToCalendarLabels, data: CalendarEventData) => {
  const { summary, startDateTime, endDateTime, location, description } = getCalendarEventDetails(labels, data, '\\n');

  const vCalendarEventStart = `BEGIN:VCALENDAR
PRODID:-//Microsoft Corporation//Outlook 10.0 MIMEDIR//EN
VERSION:1.0
BEGIN:VEVENT`;
  const vCalendarEventEnd = `PRIORITY:3
END:VEVENT
END:VCALENDAR`;
  const vCalendarEvent = [
    vCalendarEventStart,
    `DTSTART:${startDateTime}`,
    `DTEND:${endDateTime}`,
    `LOCATION:${location}`,
    `UID:${v4()}`,
    `SUMMARY:${summary}`,
    `DESCRIPTION:${description}`,
    vCalendarEventEnd,
  ].join('\n');

  return vCalendarEvent;
};

export const downloadFile = (content: string, fileName: string, mimeType: string) => {
  const blob = new Blob([content], { type: mimeType });
  const url = window.URL.createObjectURL(blob);

  const anchorEl = document.createElement('a');
  anchorEl.href = url;
  anchorEl.download = fileName;

  document.body.appendChild(anchorEl);
  anchorEl.click();
  document.body.removeChild(anchorEl);
};

export const generateGoogleCalendarEventUrl = (labels: AddToCalendarLabels, data: CalendarEventData) => {
  const { summary, startDateTime, endDateTime, location, description } = getCalendarEventDetails(labels, data, '\n');

  return `${QG_GOOGLE_CALENDAR_EVENT_URL}?text=${encodeURIComponent(
    summary
  )}&dates=${startDateTime}/${endDateTime}&location=${encodeURIComponent(`${location}`)}&details=${encodeURIComponent(
    description
  )}`;
};
