import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, font } = baseVariables;

export const StyledCapacityChart = styled.div`
  .unit-cta {
    background: transparent;
    color: ${color.neutral00};
    &.active {
      border-bottom: ${toRem(1)} solid;
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
  }

  .capacity-table-container {
    overflow-x: auto;
    .capacity-table {
      width: max-content;
      border: ${toRem(1)} solid ${color.neutral20};
      display: block;
      &-row {
        &:first-child {
          background-color: ${color.base20};
          border-bottom: ${toRem(1)} solid ${color.neutral20};
        }
        &:nth-child(even) {
          background-color: ${color.neutral10};
        }
      }
      &-cell {
        border-left: ${toRem(1)} solid ${color.neutral20};
        padding: ${toRem(8)};
        width: ${toRem(130)};
        white-space: break-spaces;
        vertical-align: top;
        &:first-child {
          width: ${toRem(95)};
        }
      }
      .room-type {
        font-weight: ${font.fontWeightMedium};
      }
    }
  }
`;
