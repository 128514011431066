import React, { useEffect, useRef, FC, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import DOMPurify from 'isomorphic-dompurify';
import { getSuggestedPlacesQuery, SuggestedPlacesNode } from '@marriott/mi-groups-graphql';
import type { TypeAheadFlyoutProps } from './TypeAheadFlyout.types';
import { StyledTypeAheadFlyout } from './TypeAheadFlyout.styles';
import { DESTINATION_FIELD_ID, TYPE_AHEAD_DELAY } from '../../../../constants';
import { getPlaceDescription } from '../../../../utils';

export const TypeAheadFlyout: FC<TypeAheadFlyoutProps> = ({
  className,
  show,
  destinationOnFocus,
  setDestinationOnFocus,
  value,
  onChange,
  setShowDefaultFlyout,
  setShowTypeAheadFlyout,
  updateDestination,
  labels,
  customHeaders,
  showErrorMessage,
}) => {
  const { placeholder, label, requiredError } = labels;
  const [inputValue, setInputValue] = useState<string>('');
  const [optionsList, setOptionsList] = useState<SuggestedPlacesNode[] | Record<string, string>[]>([]);
  const [timerList, setTimerList] = useState<ReturnType<typeof setTimeout>[]>([]);
  // use ref to access the current inputValue during setTimeout call for fetching auto suggestions
  const inputValueRef = useRef(inputValue);
  // api call to get suggested places
  const [getSuggestedPlaces, { data: suggestionListData, loading }] = useLazyQuery(getSuggestedPlacesQuery);

  const setScreenReaderText = (el: Element, text: string) => {
    if (el) {
      // empty the element before inserting content to replicate innerHTML functionality
      el.replaceChildren();
      el.appendChild(DOMPurify.sanitize(text, { RETURN_DOM_FRAGMENT: true }));
      setTimeout(() => {
        el.replaceChildren();
      }, 100);
    }
  };

  const clearTimerList = (timerList: ReturnType<typeof setTimeout>[]) => {
    if (timerList.length) {
      for (let i = timerList.length - 1; i >= 0; i--) {
        clearTimeout(timerList[i]);
      }
      setTimerList([]);
    }
  };

  useEffect(() => {
    // update current valRef with new inputValue
    inputValueRef.current = inputValue;
    // delaying API call till the input value finishes updating
    if (inputValue) {
      clearTimerList(timerList);
      const timer = setTimeout(() => {
        const placeDescription = getPlaceDescription(inputValue);
        updateDestination(placeDescription?.description, placeDescription?.placeId);
        getSuggestedPlaces({
          variables: {
            query: inputValue,
          },
          context: {
            headers: customHeaders,
          },
          fetchPolicy: 'network-only',
        });
      }, TYPE_AHEAD_DELAY);
      setTimerList([...timerList, timer]);
      setShowDefaultFlyout(false);
    } else {
      // cancel current fetch timeout timer if user delete the input value
      clearTimerList(timerList);

      // clear options list and show recently viewed when input value is cleared
      setOptionsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    const srEl = document.querySelector('.screen-reader-text');
    if (suggestionListData?.suggestedPlaces) {
      if (suggestionListData.suggestedPlaces.total === 0) {
        setOptionsList([]);
        // if there's no search results, screen reader should announce
        // no results found.
        if (srEl) {
          setScreenReaderText(srEl, 'No results found'); // should be coming from AEM
        }
      } else if (suggestionListData.suggestedPlaces.total > 0 && inputValueRef.current) {
        setShowDefaultFlyout(false);
        if (suggestionListData.suggestedPlaces.edges) {
          setOptionsList(suggestionListData.suggestedPlaces.edges);
          if (srEl) {
            setScreenReaderText(srEl, `${suggestionListData.suggestedPlaces.total} results are present`); //should be coming from AEM
          }
        }
      }
    }
  }, [setShowDefaultFlyout, suggestionListData, loading]);

  const stringifyOption = (option: SuggestedPlacesNode | Record<string, string>) => {
    try {
      return JSON.stringify(option.node);
    } catch {
      return '';
    }
  };

  const getOptionDescription = (option: string): string => {
    try {
      const parsedOption = JSON.parse(option);
      if (typeof parsedOption === 'object') {
        return parsedOption.description;
      }
      throw new Error();
    } catch {
      return option;
    }
  };

  return (
    <StyledTypeAheadFlyout className={className} data-testid="type-ahead-flyout">
      <Autocomplete
        data-testid="auto-complete"
        id={DESTINATION_FIELD_ID}
        className={clsx(inputValue || value ? 'closeIcon' : '', !destinationOnFocus && show ? 'focused' : '')}
        options={optionsList?.map(stringifyOption)}
        filterOptions={(option): string[] => option}
        getOptionLabel={getOptionDescription}
        disablePortal={true}
        open={!!optionsList.length}
        clearOnBlur={false}
        forcePopupIcon={false}
        aria-label={DESTINATION_FIELD_ID}
        aria-controls={optionsList!.length ? `${DESTINATION_FIELD_ID}-popup` : 'default-flyout'}
        closeIcon={<span className={clsx('icon-s icon-cancel', inputValue || value ? 'show-icon' : 'hide-icon')} />}
        aria-expanded={show}
        value={value}
        onChange={(_event, newValue: string | null, reason: string) => {
          // onChange will be triggered when an auto-suggest option is chosen or a chosen option is deleted;
          // thus only call the following when newValue exists, aka an option is chosen
          if (newValue) {
            const parsedNewValue = JSON.parse(newValue);
            onChange(newValue);
            setInputValue(newValue);
            setShowDefaultFlyout(false);
            setShowTypeAheadFlyout(false);
            setOptionsList([]);

            const placeId = parsedNewValue.placeId;

            try {
              updateDestination(parsedNewValue.description, placeId);
            } catch {
              updateDestination(newValue, placeId);
            }
            setDestinationOnFocus(false);
          }
          // reset once textfield is cleared up from clear button
          if (reason === 'clear') {
            onChange(null);
            setInputValue('');
            setDestinationOnFocus(true);
          }
        }}
        onInputChange={(event, newInputValue: string) => {
          // only to catch the event triggered by typing whose type is 'change';
          // exclude the input change from choosing an option from dropdown
          if (event?.type === 'change') {
            const trimmedNewInput = newInputValue.trim();
            setShowTypeAheadFlyout(true);
            setInputValue(trimmedNewInput);
          }

          // reset once textfield is cleared up from keyboard
          if (newInputValue === '' && event !== null) {
            onChange(null);
            setInputValue('');
            // clear optionsList when inputValue is empty
            setOptionsList([]);
            setShowTypeAheadFlyout(false);
            setShowDefaultFlyout(true);
          }
        }}
        onBlur={() => setShowTypeAheadFlyout(false)}
        renderInput={params => (
          <TextField
            {...params}
            data-testid="auto-complete-text"
            name="destination"
            placeholder={placeholder}
            variant="outlined"
            label={label}
            fullWidth
            value={value}
            error={showErrorMessage}
            helperText={showErrorMessage ? requiredError : ''}
            InputLabelProps={{ shrink: true }}
            onFocus={() => {
              setDestinationOnFocus(true);
              // when focus on empty text field, show recent viewed in popup
              if (inputValue || value) {
                setShowTypeAheadFlyout(true);
                setShowDefaultFlyout(false);
              } else {
                setShowTypeAheadFlyout(false);
                setShowDefaultFlyout(true);
              }
            }}
          />
        )}
        renderOption={option => {
          const place = JSON.parse(option);
          return (
            <p className="t-font-m">
              {place.primaryDescription}
              <span>{place.secondaryDescription ? ', ' + place.secondaryDescription : ''}</span>
            </p>
          );
        }}
      />
    </StyledTypeAheadFlyout>
  );
};
