import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { font, color, mediaQuery } = baseVariables;

export const StyledListView = styled.div`
  .pagination-text {
    font-size: ${font.fontS};
    @media ${mediaQuery.md} {
      font-size: ${font.fontL};
    }
  }

  .m-custom-select-block {
    span,
    button {
      font-size: ${font.fontS};
      @media ${mediaQuery.md} {
        font-size: ${font.fontM};
      }
    }
  }

  .pagination {
    .rnb-pagination-arrow {
      margin: 0 ${toRem(24)};
      .pagination-next-link,
      .pagination-prev-link {
        display: none;
      }
    }
  }

  .pagination-top-row {
    padding-top: ${toRem(24)};
    .pagination-text {
      font-size: ${font.fontS};
      line-height: ${toRem(20)};
    }
    .m-custom-select-block {
      label,
      button {
        font-size: ${font.fontS};
        line-height: ${toRem(20)};
      }
    }
  }

  .pagination-bottom-row {
    background: ${color.base20};
    height: ${toRem(60)};
    line-height: ${toRem(60)};
    z-index: 2;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;

    .rnb-pagination {
      margin: 0;
    }

    .pagination-next-link,
    .pagination-prev-link {
      display: none;
    }
  }
`;
