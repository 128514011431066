import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import { AddressType, ContractDetails } from '@marriott/mi-groups-graphql';
import { GuestRoomsFormData } from '../organisms/GuestRooms/GuestRooms.types';
import { EventSpacesFormData } from '../organisms/EventSpaces/EventSpaces.types';
import { ReviewFormData } from '../organisms/Review/Review.types';
import { BillingAndContactFormData } from '../organisms/BillingAndContact/BillingAndContact.types';
import { SubmitInquiryFormData } from '../organisms/SubmitInquiry/SubmitInquiry.types';
import { QuickGroupPageIdentifier } from '../organisms/QuickGroupHeader/QuickGroupHeader.types';
import { CreditCardType } from '../organisms/PaymentInfo/PaymentInfo.types';
import { SpouseType } from '../molecules/WeddingInfo/WeddingInfo.types';
import { initializeContractDetails } from '../utils';

type GuestRoomsExtendedFormData = GuestRoomsFormData & {
  cutoffDate: string;
};

export type BillingAndContactData = BillingAndContactFormData & {
  paymentInfo?: {
    cardType: CreditCardType | string;
    cardNumber: string;
  };
};

type ConfirmationData = {
  id: string;
  rewardId?: string;
  joinMarriottBonvoy?: boolean;
  isInlineEnrolmentDuplicateEmailError: boolean;
  isSubmitInquiry: boolean;
};

type QuickGroupState = {
  guestRoomsData: GuestRoomsExtendedFormData;
  eventSpacesData: EventSpacesFormData;
  eventSpaceConfigurationMap: Record<string, string>;
  contractData: ContractDetails;
  reviewData: ReviewFormData;
  billingAndContactData: BillingAndContactData;
  submitInquiryData: SubmitInquiryFormData;
  isCustomerInfoFetchedForBooking: boolean;
  isCustomerInfoFetchedForInquiry: boolean;
  confirmationData: ConfirmationData;
  pageVisited: {
    [QuickGroupPageIdentifier.GUEST_ROOMS]: boolean;
    [QuickGroupPageIdentifier.EVENT_SPACE]: boolean;
    [QuickGroupPageIdentifier.REVIEW_DETAILS]: boolean;
    [QuickGroupPageIdentifier.BILLING_AND_CONTACT]: boolean;
    [QuickGroupPageIdentifier.SUBMIT_INQUIRY]: boolean;
  };
};

type QuickGroupActions = {
  setGuestRoomsData: (data: GuestRoomsExtendedFormData) => void;
  setEventSpacesData: (data: EventSpacesFormData) => void;
  setEventSpaceConfigurationMap: (data: Record<string, string>) => void;
  getEventSpaceConfiguration: (key: string) => string;
  setContractData: (data: ContractDetails) => void;
  setReviewData: (data: ReviewFormData) => void;
  setBillingAndContactData: (data: BillingAndContactData) => void;
  setSubmitInquiryData: (data: SubmitInquiryFormData) => void;
  setIsCustomerInfoFetchedForBooking: (fetched: boolean) => void;
  setIsCustomerInfoFetchedForInquiry: (fetched: boolean) => void;
  setConfirmationData: (data: ConfirmationData) => void;
  setPageVisited: (pageIdentifier: QuickGroupPageIdentifier) => void;
};

const guestRoomsInitialData = {
  eventType: {
    code: '',
    label: '',
  },
  stayInOneRoom: '',
  roomSelectionData: [],
  totalAmount: 0,
  billingTypeOptions: [],
  billingType: '',
  contentPersonalized: false,
  additionalInfo: '',
  cutoffDate: '',
};

const eventSpacesInitialData = {
  eventType: {
    code: '',
    label: '',
  },
  eventSpaces: [],
  totalAmount: 0,
  additionalInfo: '',
};

const contractDetailsInitialData = initializeContractDetails();

const reviewInitialData = {
  taxAndFeeDetails: {
    roomFees: '',
    roomTaxes: '',
    eventFees: '',
  },
  acceptTnc: false,
};

const billingAndContactInitialData = {
  eventInfo: {
    eventName: '',
  },
  weddingInfo: {
    firstSpouseType: SpouseType.BRIDE,
    firstSpouseLastName: '',
    secondSpouseType: SpouseType.GROOM,
    secondSpouseLastName: '',
  },
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    callingCode: {
      code: '',
      label: '',
      description: '',
      value: '',
      shortDescription: '',
    },
    phoneNumber: '',
    rewardId: '',
  },
  inlineEnrolment: {
    joinMarriottBonvoy: false,
    createPassword: '',
    confirmPassword: '',
    rememberMe: true,
    receiveUpdates: true,
    receiveOffers: true,
  },
  addressInfo: {
    addressType: AddressType.NONE,
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    country: {
      label: '',
      value: '',
      code: '',
      description: '',
    },
    city: '',
    state: '',
    zipcode: '',
  },
  paymentInfo: {
    cardType: '',
    cardNumber: '',
  },
};

const confirmationInitialData = {
  id: '',
  rewardId: '',
  joinMarriottBonvoy: false,
  isInlineEnrolmentDuplicateEmailError: false,
  isSubmitInquiry: false,
};

const initialState: QuickGroupState = {
  guestRoomsData: guestRoomsInitialData,
  eventSpacesData: eventSpacesInitialData,
  eventSpaceConfigurationMap: {},
  contractData: contractDetailsInitialData,
  reviewData: reviewInitialData,
  billingAndContactData: billingAndContactInitialData,
  submitInquiryData: billingAndContactInitialData,
  isCustomerInfoFetchedForBooking: false,
  isCustomerInfoFetchedForInquiry: false,
  confirmationData: confirmationInitialData,
  pageVisited: {
    [QuickGroupPageIdentifier.GUEST_ROOMS]: false,
    [QuickGroupPageIdentifier.EVENT_SPACE]: false,
    [QuickGroupPageIdentifier.REVIEW_DETAILS]: false,
    [QuickGroupPageIdentifier.BILLING_AND_CONTACT]: false,
    [QuickGroupPageIdentifier.SUBMIT_INQUIRY]: false,
  },
};

export const QuickGroupStore: StateCreator<QuickGroupState & QuickGroupActions> = (set, get) => {
  return {
    ...initialState,

    setGuestRoomsData: (data: GuestRoomsExtendedFormData) => {
      set({ guestRoomsData: { ...data } });
    },

    setEventSpacesData: (data: EventSpacesFormData) => {
      set({ eventSpacesData: { ...data } });
    },

    setEventSpaceConfigurationMap: (data: Record<string, string>) => {
      set({ eventSpaceConfigurationMap: data });
    },

    getEventSpaceConfiguration: (key: string) => {
      const { eventSpaceConfigurationMap } = get();
      return eventSpaceConfigurationMap[key] || '';
    },

    setContractData: (data: ContractDetails) => {
      set({ contractData: { ...data } });
    },

    setReviewData: (data: ReviewFormData) => {
      set({ reviewData: { ...data } });
    },

    setBillingAndContactData: (data: BillingAndContactData) => {
      set({ billingAndContactData: { ...data } });
    },

    setSubmitInquiryData: (data: SubmitInquiryFormData) => {
      set({ submitInquiryData: { ...data } });
    },

    setIsCustomerInfoFetchedForBooking: (fetched: boolean) => {
      set({ isCustomerInfoFetchedForBooking: fetched });
    },

    setIsCustomerInfoFetchedForInquiry: (fetched: boolean) => {
      set({ isCustomerInfoFetchedForInquiry: fetched });
    },

    setConfirmationData: (data: ConfirmationData) => {
      set({ confirmationData: { ...data } });
    },

    setPageVisited: (pageIdentifier: QuickGroupPageIdentifier) => {
      const { pageVisited } = get();
      set({ pageVisited: { ...pageVisited, [pageIdentifier]: true } });
    },
  };
};

export const useQuickGroupStore = createAppStore(QuickGroupStore, {
  usePersistentStore: true,
  persistentStoreName: 'quickGroupData',
  useLocalStorage: false,
});
