import axios from 'axios';
import Cookies from 'js-cookie';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

export async function updateSession(data: Record<string, string>) {
  const clientVars = useClientEnvVarsStore.getState().envVarsObject;
  return new Promise((resolve, reject) => {
    if (process.env['IS_LOCAL_DEV'] === 'true') {
      resolve({ sessionHeader: '', sessionData: '' });
    } else {
      axios
        .post(`${process.env['SESSION_APP_POST_CALL_URL'] || clientVars['SESSION_APP_POST_CALL_URL']}`, data, {
          headers: {
            Cookie: Cookies.get('sessionID')?.toString() ?? '',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          resolve({ sessionHeader: response?.headers, sessionData: response?.data });
        })
        .catch(() => {
          reject('Update session error');
        });
    }
  });
}

export async function getSession() {
  const clientVars = useClientEnvVarsStore.getState().envVarsObject;
  return new Promise((resolve, reject) => {
    if (process.env['IS_LOCAL_DEV'] === 'true') {
      resolve({ sessionHeader: '', sessionData: '' });
    } else {
      axios
        .get(`${process.env['SESSION_APP_GET_CALL_URL'] || clientVars['SESSION_APP_GET_CALL_URL']}`)
        .then(response => {
          resolve({ sessionHeader: response?.headers, sessionData: response?.data });
        })
        .catch(() => {
          reject('Get session error');
        });
    }
  });
}
