import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, font, mediaQuery } = baseVariables;

export const StyledPropertyQuickViewModal = styled.div`
  .modal-header {
    .header-heading {
      font-size: ${toRem(18)};
      font-weight: ${font.fontWeightMedium};
      @media ${mediaQuery.md} {
        font-size: ${toRem(22)};
      }
      @media ${mediaQuery.lg} {
        font-size: ${toRem(30)};
      }
    }
  }

  .modal-body {
    padding: ${toRem(16)};
    min-height: ${toRem(150)};
    @media ${mediaQuery.md} {
      padding: ${toRem(32)};
    }
    .border-top {
      border-top: ${toRem(1)} solid ${color.neutral40};
    }

    .reviews-container {
      border-bottom: ${toRem(1)} solid transparent;
      &:hover {
        border-bottom-color: ${color.base10};
      }
    }

    .accordion__container .accordion__heading .visually-hidden {
      font-size: ${toRem(12)};
    }

    .amenities-container {
      width: 100%;
      .amenity {
        flex: 0 0 100%;
        @media ${mediaQuery.md} {
          flex: 0 0 50%;
        }
      }
    }

    .equipment-service-container {
      column-count: 1;
      @media ${mediaQuery.md} {
        column-count: 2;
        column-gap: ${toRem(20)};
      }
      .category {
        break-inside: avoid-column;
        .category-title {
          font-size: ${toRem(16)};
          font-weight: ${font.fontWeightMedium};
        }
        .category-value {
          > span {
            display: block;
          }
        }
      }
    }

    .map-container {
      border-radius: ${toRem(15)};
      overflow: hidden;
      .map-marker {
        width: 80%;
        white-space: normal;
        line-height: ${toRem(18)};
      }
      .gm-style-mtc button,
      .gm-fullscreen-control,
      .gm-svpc,
      .gm-control-active,
      .gm-style-cc button {
        &:focus-visible {
          outline: -webkit-focus-ring-color auto ${toRem(1)};
        }
      }
    }
  }

  .footer-section {
    .selected {
      background-color: ${color.neutral30};
      border: none;
    }
  }
`;
