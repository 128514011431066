import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { font, color, mediaQuery } = baseVariables;

export const StyledDatePickerContainer = styled.div`
  .DayPicker {
    margin-top: ${toRem(10)};
    width: 100%;
    padding: 0 ${toRem(5)};

    .DayPicker-wrapper {
      width: 100%;

      .DayPicker-Months {
        .DayPicker-Month {
          margin-left: 0;
          margin-right: 0;
          width: 100%;

          & ~ .DayPicker-Month {
            margin-left: 0;
            margin-right: 0;
          }

          @media ${mediaQuery.lg} {
            width: ${toRem(310)};
            margin-left: ${toRem(20)};
            margin-right: ${toRem(30)};

            & ~ .DayPicker-Month {
              margin-left: ${toRem(30)};
              margin-right: ${toRem(20)};
            }
          }

          .DayPicker-Weekdays {
            border-width: 0;

            .DayPicker-Weekday {
              color: ${color.base10};
              font-family: ${font.fontFamilySwiss};
            }
          }

          .DayPicker-Body {
            .DayPicker-Week {
              .DayPicker-Day {
                .DayPicker-Day-Cell {
                  width: ${toRem(44)};
                  height: ${toRem(44)};
                }

                &.DayPicker-Day--disabled {
                  .DayPicker-Day-Cell {
                    .DayPicker-Day-value {
                      color: ${color.neutral30};
                    }
                  }
                }
              }
            }
          }

          .DayPicker-Caption {
            margin-bottom: ${toRem(18)};
            padding-top: ${toRem(4)};
            width: 100%;
            & > div {
              text-transform: uppercase;
              color: ${color.base10};
              font-size: ${font.fontS};
              font-weight: ${font.fontWeightMedium};
              letter-spacing: ${toRem(1.3)};
              line-height: ${toRem(12)};
              text-align: center;
            }
          }
        }
      }

      .DayPicker-NavBar {
        .DayPicker-NavButton {
          z-index: 1;
          &.DayPicker-NavButton--next {
            right: ${toRem(8)};
            &:before {
              line-height: ${toRem(20)};
              margin-left: ${toRem(-2)};
            }
          }
          &.DayPicker-NavButton--prev {
            left: ${toRem(8)};
            &:before {
              line-height: ${toRem(20)};
              margin-left: ${toRem(-2)};
            }
          }
        }
      }
    }
  }

  .DayPicker-Weekday abbr[title] {
    font-size: ${font.fontS};
    font-weight: ${font.fontWeightMedium};
    letter-spacing: 0;
    line-height: ${toRem(16)};
  }
`;
