import React, { FC, useState } from 'react';
import { CheckboxControlsList } from '@marriott/mi-ui-library';
import { FilterModal } from '../../../molecules/FilterModal';
import { ActivitiesFilterModalProps, PropertySearchTermFacetBucket } from './ActivitiesFilterModal.types';
import { StyledActivtiesFilterModal } from './ActivitiesFilterModal.styles';

export const ActivitiesFilterModal: FC<ActivitiesFilterModalProps> = ({
  filterType,
  labels,
  activities,
  defaultSelected = [],
  showModal,
  isLoading,
  onFilterChange,
  onApply,
  onClear,
  onCloseModal,
}) => {
  const [selectedActivities, setSelectedActivities] = useState<PropertySearchTermFacetBucket[]>(defaultSelected);

  const handleChange = (selectedValues: PropertySearchTermFacetBucket[]) => {
    setSelectedActivities(selectedValues);
    onFilterChange(filterType, selectedValues);
  };

  const handleClear = () => {
    setSelectedActivities([]);
    onClear(filterType);
  };

  const activitiesFilter = (
    <CheckboxControlsList
      name={labels.heading}
      key={labels.heading}
      controls={activities}
      defaultSelected={selectedActivities}
      onChange={selectedValues => handleChange(selectedValues as PropertySearchTermFacetBucket[])}
    />
  );

  return (
    <StyledActivtiesFilterModal>
      <FilterModal
        labels={labels}
        show={showModal}
        bodyCustomClass={'custom-scrollbar'}
        children={activitiesFilter}
        isLoading={isLoading}
        onCloseModal={onCloseModal}
        onClearSelectedFilters={handleClear}
        onApplySelectedFilters={() => onApply(filterType, selectedActivities)}
      />
    </StyledActivtiesFilterModal>
  );
};
