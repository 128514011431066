import { FC, useEffect, useRef, useState } from 'react';
import { Button, baseVariables } from '@marriott/mi-ui-library';
import { recentSearchesStorage, getGeocodeAddress } from '../../../../utils';
import { DESTINATION_FIELD_ID } from '../../../../constants';
import { useMediaQuery } from '../../../../hooks';
import { RecentList } from './RecentList';
import type { DefaultFlyoutProps } from './DefaultFlyout.types';
import { StyledDefaultFlyout } from './DefaultFlyout.styles';
import { CurrentLocation } from './CurrentLocation';

export const DefaultFlyout: FC<DefaultFlyoutProps> = ({
  setDestinationOnFocus,
  setValue,
  updateDestination,
  closeFlyOut,
  labels,
  clearRecentTrackValue,
  setShowDefaultFlyout,
}) => {
  const { currentLocation, clearRecent, recentSearches: recentSearchesLabel } = labels;
  const recentSearches = recentSearchesStorage.getItem() || [];

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const [, setRerender] = useState<boolean>(false);
  const defaultLayoutRef = useRef<HTMLDivElement>(null);

  const clearRecentSearch = () => {
    recentSearchesStorage.removeItem();
    setRerender(true);
    closeFlyOut?.();
  };

  useEffect(() => {
    const defaultLayoutEl = defaultLayoutRef.current;
    if (defaultLayoutEl) {
      const clickListener = (event: Event) => {
        if (
          isTabletAndAbove &&
          !defaultLayoutEl.contains(event.target as Element) &&
          !defaultLayoutEl.contains(document.activeElement) &&
          document.activeElement?.id !== DESTINATION_FIELD_ID
        ) {
          setShowDefaultFlyout(false);
        }
      };

      const focusoutListener = (event: FocusEvent) => {
        if (
          isTabletAndAbove &&
          !defaultLayoutEl.contains(event.relatedTarget as Element) &&
          !defaultLayoutEl.contains(document.activeElement) &&
          document.activeElement?.id !== DESTINATION_FIELD_ID
        ) {
          setShowDefaultFlyout(false);
        }
      };

      document.addEventListener('click', clickListener);
      defaultLayoutEl.addEventListener('focusout', focusoutListener);

      return () => {
        document.removeEventListener('click', clickListener);
        defaultLayoutEl.removeEventListener('focusout', focusoutListener);
      };
    }
    return;
  }, [setShowDefaultFlyout, isTabletAndAbove]);

  // update the input field with recent search value and close the flyout
  const updateSelection = (recentValue: string, placeId?: string) => {
    if (typeof recentValue === 'string') {
      setDestinationOnFocus?.(false);
      setValue(recentValue);
      closeFlyOut?.();

      if (placeId) {
        updateDestination(recentValue, placeId);
      } else {
        getGeocodeAddress(recentValue)
          .then(id => {
            updateDestination(recentValue, id);
          })
          .catch(() => {
            updateDestination(recentValue, placeId);
          });
      }
    }
  };

  return (
    <StyledDefaultFlyout className="custom-scrollbar" ref={defaultLayoutRef}>
      <div className="main">
        <CurrentLocation handleSelection={updateSelection} label={currentLocation} />
        {!!recentSearches.length && (
          <>
            <div className="separator mb-3 mt-2" />
            <RecentList
              heading={recentSearchesLabel}
              itemList={recentSearches.reverse()}
              nameKey={'label'}
              valueKey={'value'}
              handleSelection={updateSelection}
            />
          </>
        )}
      </div>
      {!!recentSearches.length && (
        <div className="footer">
          {isTabletAndAbove && <div className="separator mb-3" />}
          <Button
            callback={clearRecentSearch}
            className="clear-recent-cta m-link-action p-0"
            custom_click_track_value={`${clearRecentTrackValue} button |internal`}
            buttonCopy={clearRecent}
          />
        </div>
      )}
    </StyledDefaultFlyout>
  );
};
